import React, { PropsWithChildren } from "react"
import Modal from "react-modal"
import { Heading } from "suomifi-ui-components"
import { FlexColTight } from "./CommonStyles"

interface Props {
  isOpen: boolean,
  title: string
}

const PopupEditor: React.FC<PropsWithChildren<Props>> = ({ isOpen, title, children }) => (
  <Modal isOpen={isOpen} style={{
    content: {
      display:"flex",
      margin:"auto",
      width:"20%",
      height:"22%"
    }
  }}>
    <FlexColTight style={{ height: "10%" }}>
      <Heading variant='h2'>
        {title}
      </Heading>
      {children}
    </FlexColTight>
  </Modal>
)

export default PopupEditor