import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../common/Page';
import Card from '../common/Card';

const FrontPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Card title={t("app.frontpage.heading")}>
      <p>{t("app.frontpage.infotext")}</p>
      </Card>
    </Page>
  );
}

export default FrontPage;