import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { InputWrapper, TooltippedTextField } from '../common/CustomFormikComponents';

interface FormFieldsProps {
  fieldPath: string,
  formikProps: FormikProps<any>
}

export const AllowanceChargeFormFields: React.FC<FormFieldsProps> = ({fieldPath, formikProps}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <InputWrapper>
          <TooltippedTextField fieldName="chargeIndicator" fieldPath={`${fieldPath}.chargeIndicator.value`} memberOfClass="AllowanceCharge" formikProps={formikProps} disabled/>
        </InputWrapper>
      </Grid>
      <Grid item xs={6}>
        <InputWrapper>
          <TooltippedTextField fieldName="allowanceChargeReason" fieldPath={`${fieldPath}.allowanceChargeReason.value`} memberOfClass="AllowanceCharge" formikProps={formikProps} />
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TooltippedTextField fieldName="amount" fieldPath={`${fieldPath}.amount.value`} memberOfClass="AllowanceCharge" formikProps={formikProps} />
            </Grid>
            <Grid item xs={4}>
              <TooltippedTextField fieldName="currencyID" fieldPath={`${fieldPath}.amount.currencyID`} memberOfClass="AmountType" formikProps={formikProps} />
            </Grid>
          </Grid>
        </InputWrapper>
      </Grid>
    </Grid>
  );
}

export const ValidityPeriodFormFields: React.FC<FormFieldsProps> = ({fieldPath, formikProps}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <InputWrapper>
          <TooltippedTextField fieldName="startDate" fieldPath={`${fieldPath}.startDate.value`} memberOfClass="Period" formikProps={formikProps} />
        </InputWrapper>
      </Grid>
      <Grid item xs={6}>
        <InputWrapper>
          <TooltippedTextField fieldName="endDate" fieldPath={`${fieldPath}.endDate.value`} memberOfClass="Period" formikProps={formikProps} />
        </InputWrapper>
      </Grid>
    </Grid>
  );
}

export const QuantityTypeFormFields: React.FC<FormFieldsProps> = ({fieldPath, formikProps}) => {
  return (
    <InputWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TooltippedTextField fieldName="value" fieldPath={`${fieldPath}.value`} memberOfClass="QuantityType" formikProps={formikProps} fieldType="number" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TooltippedTextField fieldName="unitCode" fieldPath={`${fieldPath}.unitCode`} memberOfClass="QuantityType" formikProps={formikProps} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TooltippedTextField fieldName="unitCodeListID" fieldPath={`${fieldPath}.unitCodeListID`} memberOfClass="QuantityType" formikProps={formikProps} disabled />
        </Grid>
      </Grid>
    </InputWrapper>
  );
}