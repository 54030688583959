/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import Card from '../../common/Card';
import { Report, ReportStatus } from '../../../model/AppModels';
import TablePaginationActions from '../../common/TablePaginationActions';
import { toDateTimeString } from '../../../utils/dateUtils';
import ReportDocumentDialog from './ReportDocumentDialog';
import SendReportDialog from './SendReportDialog';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const ReportingView: React.FC = () => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [reports, setReports] = useState<Report[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [dialogReport, setDialogReport] = useState<Report|undefined>(undefined);
  const [sendReport, setSendReport] = useState<Report|undefined>(undefined);

  useEffect(() => {
    setReports(() => appContext.reports);
    setLoading(() => true);
    appContext.getReportsAsync()
    .then(res => setReports(() => res))
    .finally(() => setLoading(() => false));
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sentContent = (r: Report) => {
    // Show sendReport button if the report is not sent yet and
    // - it is a session report (user is an anonymous user) or
    // - user is an admin (admin cannot see any session reports)
    if (r.status === ReportStatus.CREATED && appContext.isAdmin()) {
      return <Button variant="text" sx={{padding: 0}} onClick={() => setSendReport(() => r)}>{t("app.components.ReportingView.sendReportBtn")}</Button>;
    }
    return [ReportStatus.SENDING, ReportStatus.SENT].includes(r.status) ? "x" : "-";
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/reporting", label: "reporting", current: true}
      ]}
    >
      <Card
        title={t("app.components.ReportingView.reportingHeading")}
        titleAction={
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
            <Button 
              startIcon={<AddCircleOutlineIcon/>}
              variant="text"
              onClick={() => navigate("/reporting/new")}
            >
              {t("app.components.ReportingView.newReportBtn")}
            </Button>
          </Box>
        }
      >
        <Box sx={{overflow: "auto"}}>
          <TableContainer component={Paper} style={{minWidth: "600px"}}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{"& > * ": {fontWeight: "700"}}}>
                  <TableCellThin align="left">{t("app.components.ReportingView.reportType")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.ReportingView.createdOn")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.ReportingView.periodStart")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.ReportingView.periodEnd")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.ReportingView.description")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.ReportingView.report")}</TableCellThin>
                  <TableCellThin align="right">{t("app.components.ReportingView.sent")}</TableCellThin>
                </TableRow>
              </TableHead>
              <TableBody>
                { (loading) &&
                  <TableRow>
                    <TableCellThin colSpan={8} sx={{padding: 0}}>
                      <LinearProgress/>
                    </TableCellThin>
                  </TableRow>
                }
                {(rowsPerPage > 0
                  ? reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : reports
                ).map((r, rIndex) => (
                  <TableRow
                    key={rIndex}
                    sx={{backgroundColor: r.sessionAccountID ? tokens.colors.highlightLight2 : undefined}}
                  >
                    <TableCellThin component="th" scope="row" align="left">{`${t(`app.models.ReportType.${r.reportType}`)} (${r.reportType})`}</TableCellThin>
                    <TableCellThin align="left">{toDateTimeString(r.createdOn)}</TableCellThin>
                    <TableCellThin align="left">{r.periodStart}</TableCellThin>
                    <TableCellThin align="left">{r.periodEnd}</TableCellThin>
                    <TableCellThin align="left">{r.description}</TableCellThin>
                    <TableCellThin align="left"><Link style={{padding: 0, cursor: "pointer"}} onClick={() => setDialogReport(() => r)}>{t("common.actions.open")}</Link></TableCellThin>
                    <TableCellThin align="right">{ sentContent(r) }</TableCellThin>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={reports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>

            <ReportDocumentDialog
              report={dialogReport}
              handleClose={() => setDialogReport(() => undefined)}
              sendReport={() => setSendReport(dialogReport)}
            />
            <SendReportDialog
              report={sendReport}
              handleClose={() => setSendReport(() => undefined)}
            />
          </TableContainer>
        </Box>
      </Card>
    </Page>
  )
}

export default ReportingView;