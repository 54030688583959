import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";

interface Props {
  open: boolean,
  textToCopy?: string,
  handleClose: () => void,
  confirmAction: () => void,
  cancelAction: () => void
}

const ConfirmLogoutSessionDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const copyToClipboard = (value?: string) => {
    navigator.clipboard.writeText(value ?? "");
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        {t("app.header.confirmLogoutSessionTitle")}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t("app.header.confirmLogoutSessionContentText")}
        </Typography>
        <Typography fontWeight="bold" color={tokens.colors.brandBase}>
          {t("app.header.trialDisclaimer")}
        </Typography>
        <Typography sx={{marginTop: tokens.spacing.s}}>
          {t("app.header.copyTrialIDInfo")}
        </Typography>
        <Grid container>
          <Grid item xs={true}>
            <TextField
              fullWidth
              value={props.textToCopy}
            />
          </Grid>
          <Grid item xs="auto" sx={{display: "flex", alignItems: "center"}}>
            <Tooltip disableFocusListener disableTouchListener title={t("common.actions.copyToClipboard")} placement='top-start'>
              <IconButton onClick={() => copyToClipboard(props.textToCopy)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" color="secondary" onClick={props.cancelAction}>{t("common.actions.cancel")}</Button>
        <Button variant="contained" color="primary" onClick={props.confirmAction}>{t("app.header.confirmLogoutBtn")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmLogoutSessionDialog;