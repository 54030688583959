import React, { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { Invoice, InvoiceType, Product } from '../../model/AppModels';
import { Alert, AlertTitle, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { JsonObjectRequestData } from '../../model/OmaXTypes';

interface Props {
  invoice?: Invoice,
  handleClose: () => void
}

interface DataItem {
  product: Product,
  checked: boolean
}

const ImportInvoiceItemsDialog: React.FC<Props> = ({invoice, handleClose}) => {
  const { t } = useTranslation(["translation"]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [importing, setImporting] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>(undefined);

  useEffect(() => {
    setNotification(() => undefined);
    setImporting(() => false);
    if (invoice?.type === InvoiceType.PEPPOL) {
      setLoadingData(() => true);
      OmaYritysApi.GetInvoiceItems(invoice.document.id)
      .then(res => {
        const items: DataItem[] = res.data.map(it => ({product: it, checked: false}));
        setData(() => items);
      })
      .catch(() => setNotification(() => "loadingFailed"))
      .finally(() => setLoadingData(() => false));
    }
    else {
      setNotification(() => "invalidInvoiceType");
    }
  }, [invoice]);

  const handleToggle = (index: number) => {
    setData(i => {
      const newItems = [...i];
      newItems[index].checked = !newItems[index].checked;
      return newItems;
    });
  }

  const importProducts = () => {
    setImporting(() => true);
    setNotification(() => undefined);
    const promises = data.filter(it => it.checked).map(async i => {
      const product = i.product;
      console.log(`Importing product ${product.name.value}`, product);
      const now = new Date().toISOString();
      product.createdOn = now;
      product.updatedOn = now;
      const formData: JsonObjectRequestData<Product> = {
        id: undefined,
        dataType: "CatalogueProductItem",
        jsonData: product
      };
      const postProductResp = await OmaYritysApi.PostCatalogue(formData);
      if (postProductResp.data) {
        const productId = postProductResp.data.id;
        if (product.epcisEvents && product.epcisEvents.length > 0) {
          const postEpcisResp = await OmaYritysApi.PostEpcisEvents(productId, product.epcisEvents);
        }
      }
    });
    Promise.all(promises)
    .then(() => setNotification(() => "success"))
    .catch(() => setNotification(() => "error"))
    .finally(() => setImporting(() => false));
  }

  return (
    <Dialog open={!!invoice} onClose={handleClose} fullWidth>
      <DialogTitle>
        {t("app.components.ImportInvoiceItemsDialog.title")}
      </DialogTitle>
      <DialogContent>
        { notification &&
          <Alert severity={notification === "success" ? "success" : "error"}>
            <AlertTitle>{t(`app.components.ImportInvoiceItemsDialog.${notification}Title`)}</AlertTitle>
            {t(`app.components.ImportInvoiceItemsDialog.${notification}Msg`)}
            { notification === "success" && 
              <Link component={RouterLink} to={"/products"} underline="hover" color="inherit">
                {t(`app.navigation.products`)}
              </Link>
            }
          </Alert>
        }
        { importing && 
          <Alert severity="info" icon={false}>
            <AlertTitle sx={{display: "flex", alignItems: "center"}}>
              <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
              {t(`app.components.ImportInvoiceItemsDialog.importingItems`)}
            </AlertTitle>
          </Alert>
        }
        { loadingData && 
          <Alert severity="info" icon={false}>
            <AlertTitle sx={{display: "flex", alignItems: "center"}}>
              <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
              {t(`common.actions.loading`)}
            </AlertTitle>
          </Alert>
        }
        { !loadingData &&
          <List>
            {data.map((i, iIndex) => {
              const labelId = `import-invoice-item-label-${iIndex}`;
              return (
                <ListItem
                  key={`importinvoiceitem-${iIndex}`}
                  sx={{background: (iIndex % 2 ? tokens.colors.highlightLight2 : tokens.colors.highlightLight3)}}
                  disablePadding
                  divider>
                  <ListItemButton role={undefined} onClick={() => handleToggle(iIndex)} dense disabled={importing || !!notification}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={i.checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={i.product.name.value} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        }
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={importing}
        >
          { notification ? t("common.actions.close") : t("common.actions.cancel") }
        </Button>
        <Button
          variant="contained"
          onClick={importProducts}
          disabled={importing || !!notification || data.findIndex(it => it.checked) === -1}
        >
          {t(`app.components.ImportInvoiceItemsDialog.importBtn`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportInvoiceItemsDialog;