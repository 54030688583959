import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Alert, AlertTitle, Autocomplete, Box, Button, CircularProgress, FormControl, Grid, ListItemText, TextField, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens, Heading } from 'suomifi-ui-components';
import { IconLogin } from 'suomifi-icons';
import { useAppStateContext } from '../../state/AppStateContext';
import Page from '../common/Page';
import Card from '../common/Card';
import { SessionRole } from '../../model/OmaXTypes';

interface LoginOption {
  businessId: string,
  name: string
}

const loginOptions: LoginOption[] = [
  {
    businessId: "7017001-3",
    name: "Raksa Oy"
  },
  {
    businessId: "7003430-2",
    name: "Älari Testifirma As.OY"
  },
  {
    businessId: "7011367-6",
    name: "Hosharaff 238 Testifirma"
  },
  {
    businessId: "7025394-3",
    name: "Acanne Testifirma"
  },
  {
    businessId: "7023610-7",
    name: "Vasagrande Testifirma"
  },
  {
    businessId: "7006370-1",
    name: "Sallakari Testifirma OY"
  },
  {
    businessId: "7021704-5",
    name: "Kinnasarjaana Testifirma"
  }
];

const InfoParagraph = styled.p`
  margin-top: ${tokens.spacing.s};
  margin-bottom: ${tokens.spacing.xxs};
`

const LoginView: React.FC = () => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [signupCompanyCode, setSignupCompanyCode] = useState<string>("");
  const [adminLoginCompanyCode, setAdminLoginCompanyCode] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [selectedSignupOption, setSelectedSignupOption] = useState<LoginOption|string>("");
  const [selectedAdminLoginOption, setSelectedAdminLoginOption] = useState<LoginOption|string>("");
  const [signupLoading, setSignupLoading] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [selectCompanyLoading, setSelectCompanyLoading] = useState<boolean>(false);
  const [signupError, setSignupError] = useState<string|undefined>(undefined);
  const [loginError, setLoginError] = useState<string|undefined>(undefined);

  // Sign up a new anonymous user
  const doSignup = (businessId: string|null) => {
    if (businessId) {
      setSignupError(undefined);
      setSignupLoading(true);
      appContext.signupAsync(businessId)
      .then(sessionAccount => {
        if (!sessionAccount) {
          setSignupError("companyNotFound");
        }
        setSignupLoading(false);
      })
      .catch(err => {
        setSignupError("companyNotFound");
        setSignupLoading(false);
        console.log(err);
      });
    } else {
      setSignupError("companyIDRequired");
    }
  }
  
  // Log in an existing anonymous user or a known user
  const doLogin = (token: string|null) => {
    if (token) {
      setLoginError(undefined);
      setLoginLoading(true);
      appContext.loginAsync(token)
      .then(sessionAccount => {
        if (!sessionAccount) {
          setLoginError("loginFailed");
        }
        setLoginLoading(false);
      })
      .catch(err => {
        setLoginError("loginFailed");
        setLoginLoading(false);
        console.log(err);
      });
    } else {
      setLoginError("tokenRequired");
    }
  }

  // Select company for an admin user
  const onSelectCompany = (companyCode: string|null) => {
    if (companyCode) {
      setLoginError(undefined);
      setSelectCompanyLoading(true);
      appContext.selectCompanyAsync(companyCode)
      .then(sessionAccount => {
        if (!sessionAccount) {
          setLoginError("selectCompanyFailed");
        }
        setSelectCompanyLoading(false);
      })
      .catch(err => {
        setLoginError("selectCompanyFailed");
        setSelectCompanyLoading(false);
        console.log(err);
      });
    } else {
      setLoginError("companyIDRequired");
    }
  }

  const onSignupSelectInputChange = (e: React.SyntheticEvent, value: LoginOption|string|null) => {
    const val = value !== null ? (typeof value === "string" ? value : value.businessId) : "";
    setSignupCompanyCode(old => val);
    setSelectedSignupOption(old => value ?? "");
  }

  const onSignupSelectValueChange = (e: React.SyntheticEvent, value: LoginOption|string|null) => {
    const val = value !== null ? (typeof value === "string" ? value : value.businessId) : "";
    setSignupCompanyCode(old => val);
    setSelectedSignupOption(old => value ?? "");
  }

  const onAdminLoginSelectInputChange = (e: React.SyntheticEvent, value: LoginOption|string|null) => {
    const val = value !== null ? (typeof value === "string" ? value : value.businessId) : "";
    setAdminLoginCompanyCode(old => val);
    setSelectedAdminLoginOption(old => value ?? "");
  }

  const onAdminLoginSelectValueChange = (e: React.SyntheticEvent, value: LoginOption|string|null) => {
    const val = value !== null ? (typeof value === "string" ? value : value.businessId) : "";
    setAdminLoginCompanyCode(old => val);
    setSelectedAdminLoginOption(old => value ?? "");
  }

  const waitingForAdminCompanySelect = () => {
    return !!appContext.sessionAccount;
  }

  return (
    <Page>
      <Card>
        <InfoParagraph>{t("app.login.demoInfo")}</InfoParagraph>
        <InfoParagraph>
          <Trans
            i18nKey="app.login.demoInfo2"
            components={{
              link1: <a href="https://wiki.dvv.fi/display/TYPOC/Testiaineistopalvelu" target="_blank" rel="noreferrer">https://wiki.dvv.fi/display/TYPOC/Testiaineistopalvelu</a>
            }}
          />
        </InfoParagraph>
      </Card>
      <Grid container display="flex">
        <Grid container item xs={12} sm={9} md={6}>
          <Grid item xs={12} sx={{margin: `${tokens.spacing.m} 0`}}>
            <Heading variant="h2" smallScreen>
              {t("app.login.signUpHeading")}
            </Heading>
          </Grid>
          { signupError && 
            <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
              <Alert severity="error" onClose={() => setSignupError(undefined)}>
                <AlertTitle>{t("app.login.signupErrorTitle")}</AlertTitle>
                {t(`app.login.${signupError}`)}
              </Alert>
            </Grid>
          }
          <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
            <Typography>
              {t("app.login.signUpInfo")}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
            <FormControl fullWidth>
              <Autocomplete
                id="signup-options-select"
                options={loginOptions}
                getOptionLabel={option => typeof option === "string" ? option : option.name}
                value={selectedSignupOption}
                inputValue={typeof selectedSignupOption === "string" ? selectedSignupOption : selectedSignupOption?.name}
                onInputChange={onSignupSelectInputChange}
                onChange={onSignupSelectValueChange}
                freeSolo
                disabled={waitingForAdminCompanySelect()} // disabled when a sessionAccount is created but a company is not selected (=> admin login)
                renderInput={(params) => (
                  <TextField
                    InputProps={params.InputProps}
                    fullWidth={params.fullWidth}
                    // {...params}
                    name="value"
                    label={t("app.login.selectCompanyLabel")}
                    autoFocus
                    inputProps={{...params.inputProps}}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <ListItemText 
                      primary={option.name} 
                      secondary={option.businessId}
                      secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                    />
                  </Box>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{marginBottom: tokens.spacing.s, display: "flex", alignItems: "center"}}>
            <Button 
              variant="contained"
              startIcon={<IconLogin/>}
              disabled={!signupCompanyCode || signupLoading || loginLoading || waitingForAdminCompanySelect()}
              onClick={() => doSignup(signupCompanyCode)}
              sx={{marginRight: tokens.spacing.s}}
            >
              {t("app.login.signUpButton")}
            </Button>
            {signupLoading && <CircularProgress/>}
          </Grid>
          <Grid item xs={12} sx={{margin: `${tokens.spacing.m} 0`}}>
            <Heading variant="h2" smallScreen>
              {t("app.login.loginHeading")}
            </Heading>
          </Grid>
          { loginError && 
            <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
              <Alert severity="error" onClose={() => setLoginError(undefined)}>
                <AlertTitle>{t("app.login.loginErrorTitle")}</AlertTitle>
                {t(`app.login.${loginError}`)}
              </Alert>
            </Grid>
          }
          <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
            <Typography>
              {t("app.login.loginInfo")}
            </Typography>
            <Typography fontWeight={600}>
              {t("app.login.loginInfoDisclaimer")}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
            <TextField
              fullWidth
              type="password"
              label={t("app.login.enterToken")}
              value={token}
              onChange={e => setToken(() => e.target.value)}
              variant="outlined"
              disabled={signupLoading || loginLoading || waitingForAdminCompanySelect()}
            />
          </Grid>
          { !waitingForAdminCompanySelect() &&
            <Grid item xs={12} sx={{marginBottom: tokens.spacing.s, display: "flex", alignItems: "center"}}>
              <Button 
                variant="contained"
                startIcon={<IconLogin/>}
                disabled={!token || signupLoading || loginLoading || waitingForAdminCompanySelect()}
                onClick={() => doLogin(token)}
                sx={{marginRight: tokens.spacing.s}}
              >
                {t("app.login.loginButton")}
              </Button>
              {loginLoading && <CircularProgress/>}
            </Grid>
          }
          { appContext.sessionAccount?.role === SessionRole.Admin && !appContext.sessionAccount.data.companyID &&
            <>
              <Grid item xs={12} sx={{marginBottom: tokens.spacing.s}}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="login-options-select"
                    options={loginOptions}
                    getOptionLabel={option => typeof option === "string" ? option : option.name}
                    value={selectedAdminLoginOption}
                    inputValue={typeof selectedAdminLoginOption === "string" ? selectedAdminLoginOption : selectedAdminLoginOption?.name}
                    onInputChange={onAdminLoginSelectInputChange}
                    onChange={onAdminLoginSelectValueChange}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        InputProps={params.InputProps}
                        fullWidth={params.fullWidth}
                        name="value"
                        label={t("app.login.selectCompanyLabel")}
                        autoFocus
                        inputProps={{...params.inputProps}}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItemText 
                          primary={option.name} 
                          secondary={option.businessId}
                          secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                        />
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{marginBottom: tokens.spacing.s, display: "flex", alignItems: "center"}}>
                <Button 
                  variant="contained"
                  startIcon={<IconLogin/>}
                  disabled={!adminLoginCompanyCode || signupLoading || loginLoading}
                  onClick={() => onSelectCompany(adminLoginCompanyCode)}
                  sx={{marginRight: tokens.spacing.s}}
                >
                  {t("app.login.selectCompanyButton")}
                </Button>
                {selectCompanyLoading && <CircularProgress/>}
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </Page>
  );
}

export default LoginView;
