
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import { AppStateContext, AppStateProperty, AppStatePropertyState } from "../state/AppStateContext";
import { Box, Button, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import TablePaginationActions from "./common/TablePaginationActions";
import { toDateTimeString } from '../utils/dateUtils';
import { JsonObject } from '../model/OmaXTypes';
import { BusinessDocument } from '../model/AppModels';
import BusinessDocumentDialog from './modals/BusinessDocumentDialog';
import { NSGInvoiceCredential } from '../model/NsgInvoiceModels';
import CreateGeneralLedgerEntriesDialog from './modals/CreateGeneralLedgerEntryDialog';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const PurchaseReceiptsTable: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogReceipt, setDialogReceipt] = useState<JsonObject<BusinessDocument<NSGInvoiceCredential>>|undefined>(undefined);
  const [receipts, setReceipts] = useState<JsonObject<BusinessDocument<NSGInvoiceCredential>>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [postReceipt, setPostReceipt] = useState<JsonObject<BusinessDocument<NSGInvoiceCredential>>|undefined>(undefined);

  useEffect(() => {
    let _receipts: JsonObject<BusinessDocument<NSGInvoiceCredential>>[] = [];
    setLoading(() => true);
    if (appContext.businessDocuments) {
      _receipts.push(...appContext.businessDocuments
      .filter(it => it.dataType === "BusinessDocument:eReceipt")
      .map(it => it as JsonObject<BusinessDocument<NSGInvoiceCredential>>));
    }
    setReceipts(() => sortReceipts(_receipts));
    if (dialogReceipt) {
      const updatedDialogReceipt = _receipts.find(it => it.id === dialogReceipt.id);
      setDialogReceipt(() => updatedDialogReceipt);
    }
    setLoading(() => false);
  }, [appContext.businessDocuments]);

  useEffect(() => {
    if (appContext.propertyStates[AppStateProperty.BUSINESS_DOCUMENTS] !== AppStatePropertyState.UNINITIALIZED) {
      setIsInitialized(() => true);
    }
  }, [appContext.propertyStates[AppStateProperty.BUSINESS_DOCUMENTS]]);

  const sortReceipts = (list: JsonObject<BusinessDocument<NSGInvoiceCredential>>[]): JsonObject<BusinessDocument<NSGInvoiceCredential>>[] => {
    const sorted = list.sort((a, b) => {
      const aCreatedAtStr = a.jsonData.createdOn;
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = b.jsonData.createdOn;
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return  bCreatedAt - aCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return -1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const postedToAccountingContent = (bd: JsonObject<BusinessDocument<NSGInvoiceCredential>>) => {
    // Show postInvoice button if the invoice is accepted but is not yet accounted and 
    // - it is a session invoice (user is an anonymous user) or
    // - it is an admin invoice (user can be anonymous or admin).
    //   - Anonymous user can post an admin invoice to accounting but it does not change the status of the invoice.
    if (!bd.jsonData.accountingNumber) {
      return <Button variant="text" sx={{padding: 0}} onClick={() => setPostReceipt(() => bd)}>{t("app.components.PurchaseReceiptsTable.postInvoiceBtn")}</Button>;
    }
    // Show accountingNumber (entryNumber) if the invoice is alreeady accounted.
    else if (bd.jsonData.accountingNumber) {
      return bd.jsonData.accountingNumber;
    }
    return "-";
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - receipts.length) : 0;

  return (
    <Box sx={{overflow: "auto"}}>
    <TableContainer component={Paper} style={{minWidth: "800px"}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCellThin>{t("app.components.PurchaseReceiptsTable.receiptType")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.PurchaseReceiptsTable.sender")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.PurchaseReceiptsTable.amount")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.PurchaseReceiptsTable.openDocument")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.PurchaseReceiptsTable.createdOn")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.PurchaseReceiptsTable.postedToAccounting")}</TableCellThin>
          </TableRow>
        </TableHead>
        <TableBody>
          { (!isInitialized || loading) &&
            <TableRow>
              <TableCellThin colSpan={8} sx={{padding: 0}}>
                <LinearProgress/>
              </TableCellThin>
            </TableRow>
          }
          {(rowsPerPage > 0
            ? receipts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : receipts
          ).map((r, index) => (
            <TableRow
              key={index}
              sx={{backgroundColor: r.sessionAccountID ? tokens.colors.highlightLight2 : undefined}}
            >
              <TableCellThin component="th" scope="row">{t("app.components.PurchaseReceiptsTable.eReceipt")}</TableCellThin>
              <TableCellThin align="left">{r.jsonData.jsonData.credentialSubject.accountingSupplierParty.party.partyName.name}</TableCellThin>
              <TableCellThin align="left">{r.jsonData.jsonData.credentialSubject.legalMonetaryTotal.chargeTotalAmount.toFixed(2) + " €"}</TableCellThin>
              <TableCellThin align="left">
                <Link style={{padding: 0, cursor: "pointer"}} onClick={() => setDialogReceipt(r)}>{t("app.components.PurchaseReceiptsTable.openHTML")}</Link>
              </TableCellThin>
              <TableCellThin align="left">{toDateTimeString(r.jsonData.createdOn)}</TableCellThin>
              <TableCellThin align="left">
                { postedToAccountingContent(r) }
              </TableCellThin>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCellThin colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              count={receipts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <BusinessDocumentDialog
        busDoc={dialogReceipt}
        handleClose={() => setDialogReceipt(() => undefined)}
      />
      <CreateGeneralLedgerEntriesDialog
        type="receipt"
        document={postReceipt?.jsonData}
        handleClose={() => setPostReceipt(() => undefined)}
      />
    </TableContainer>
    </Box>
  );
}

export default PurchaseReceiptsTable;