import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import XMLViewer from "react-xml-viewer";
import ReactJson from 'react-json-view';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import { useAppStateContext } from '../../../state/AppStateContext';
import { Report, ReportStatus, ReportType } from '../../../model/AppModels';
import * as OmaYritysApi from "../../../api/OmaYritysApi";
import * as PublicFolderApi from "../../../api/PublicFolderApi";

interface Props {
  report?: Report,
  handleClose: () => void,
  sendReport?: () => void
}

const XmlContainer = styled.div`
  ul {
    padding: revert
  }
`;

  /**
   * @param report
   * - Dialog will open when report is not undefined.
   * @param handleClose
   * - Action that will be triggered when the dialog should be closed.
   * @param sendReport
   * - Optional function to send the report. If the parameter is undefined, the button is hidden.
   */
const ReportDocumentDialog: React.FC<Props> = ({report, handleClose, sendReport}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [xml, setXml] = useState<string>();
  const [json, setJson] = useState<any>();
  const [html, setHtml] = useState<any>();
  const [visualize, setVisualize] = useState<boolean>(true);
  const [enableVisualize, setEnableVisualize] = useState<boolean>(true);

  const getLoadingElement = () => {
    const rootElement = document.createElement("div");
    const p = document.createElement("p");
    p.textContent=t("common.actions.loading");
    rootElement.appendChild(p);
    return rootElement;
  }
  const [doc, setDoc] = useState<HTMLDivElement>(getLoadingElement());

  const createXmlIFrameContent = (xml: string, stylesheet: string) => {
    const rootElement = document.createElement("div");
    const parser = new DOMParser();
    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(parser.parseFromString(stylesheet, "text/xml"));
    const fragment = xsltProcessor.transformToFragment(parser.parseFromString(xml, "text/xml"), document);
    rootElement.appendChild(fragment);
    setDoc(rootElement);
  }
  
  useEffect(() => {
    setXml(() => undefined);
    setJson(() => undefined);
    setHtml(() => undefined);
    setVisualize(() => true);
    setEnableVisualize(() => true);
    if (report?.xmlData) {
      setVisualize(() => true);
      OmaYritysApi.GetReportContent(report.id, report.reportType)
      .then(res => {
        if (report.reportType === ReportType.VISUALIZE_INLINE_XBRL) {
          setHtml(() => res.data);
        }
        else if (report.reportType === ReportType.JOURNAL) {
          setXml(() => res.data);
          PublicFolderApi.GetFile("XBRLGL-UTF-8.xsl")
          .then(res2 => createXmlIFrameContent(res.data, res2.data));
        }
        else {
          setVisualize(() => false);
          setEnableVisualize(() => false);
          setXml(() => res.data);
        }
      });
    }
    else if (report?.jsonData) {
      setJson(() => report.jsonData);
    }
  }, [report]);

  const onAction = (action: () => void) => {
    action();
  }

  return (
    <Dialog open={!!report} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        {!xml && !json && !html &&
          <div style={{width: "100%", height: "100%"}}>
            {(!xml) &&
              <p>{t("common.actions.loading")}</p>
            }
          </div>
        }
        {xml &&
          <div style={{width: "100%", height: "100%"}}>
            <FormGroup sx={{display: enableVisualize ? "block" : "none", position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
              <FormControlLabel 
                control={<Switch checked={visualize} 
                onChange={() => setVisualize(old => !old)} />} 
                label={t("common.actions.visualize")} 
                labelPlacement="start" 
              />
            </FormGroup>
            <XmlContainer 
              style={{display: visualize ? "block" : "none", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}
              dangerouslySetInnerHTML={{__html: doc.innerHTML}}
            ></XmlContainer>
            <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs, fontSize: "medium"}}>
              <XMLViewer xml={xml} collapsible />
            </div>
          </div>
        }
        {json &&
          <div style={{width: "100%", height: "100%"}}>
            <div style={{borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}>
              <ReactJson src={json} name={false} displayDataTypes={false}/>
            </div>
          </div>
        }
        {html &&
          <div style={{width: "100%", height: "100%"}}>
            <FormGroup sx={{position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
              <FormControlLabel 
                control={<Switch checked={visualize} 
                onChange={() => setVisualize(old => !old)} />} 
                label={t("common.actions.visualize")} 
                labelPlacement="start" 
              />
            </FormGroup>
            <XmlContainer 
              style={{display: visualize ? "block" : "none", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}
              dangerouslySetInnerHTML={{__html: html}}
            ></XmlContainer>
            <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs, fontSize: "medium"}}>
              <XMLViewer xml={html} collapsible />
            </div>
          </div>
        }
        <Grid container sx={{marginTop: tokens.spacing.s}} spacing={1}>
          { !!sendReport && report && appContext.isAdmin() && report.status === ReportStatus.CREATED &&
            <Grid item>
              <Button variant="outlined" onClick={() => onAction(sendReport)}>{t("app.components.ReportDocumentDialog.sendReportBtn")}</Button>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{display: "flex"}}>
        <Button variant="contained" onClick={handleClose}>{t("common.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReportDocumentDialog;