/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';
import dayjs from 'dayjs';
import { Box, IconButton, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu';
import { useAppStateContext } from '../../state/AppStateContext';
import { SessionRole } from '../../model/OmaXTypes';

const AppHeader = styled.header`
  display: flex;
  font-weight: 600;
`

const AppTitle = styled.div`
  display: flex;
  color: ${tokens.colors.brandBase};
  font-size: 28px;
  flex-grow: 1;
  align-items: center;
`

export const SelectLanguage = styled.div`
  display: flex;
  text-align: right;
  line-height: 1;
  margin-left: ${tokens.spacing.xs};
`

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const appContext = useAppStateContext();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    dayjs.locale(lng);
  };

  return (
      <AppHeader>
        <AppTitle>
          {t("app.header.title")}
        </AppTitle>
        { appContext.sessionAccount?.role === SessionRole.Admin && 
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography fontSize="1rem" align="center" variant="button" color={tokens.colors.brandBase}>ADMIN</Typography>
          </Box>
        }
        <UserMenu/>
        <SelectLanguage>
          <IconButton
            color="primary"
            size="small"
            style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
            onClick={() => changeLanguage('fi') }
          >
            FI
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
            onClick={() => changeLanguage('en') }
          >
            EN
          </IconButton>
        </SelectLanguage>
      </AppHeader>
  );
};

export default Header;
