import { useTranslation } from 'react-i18next';

export const useCustomTranslations = () => {
  const namespaces = ["translation", "preawardcatalogue", "ubl"];
  const { t, i18n } = useTranslation(namespaces);

  /**
   * @param attribute
   * - E.g. "title" in "components.schemas.AmountType.title"
   * - E.g. "description" in "components.schemas.AmountType.properties.currencyID.description"
   * @param schema
   * - E.g. AmountType in "components.schemas.AmountType.title"
   * - E.g. AmountType in "components.schemas.AmountType.properties.currencyID.description"
   * @param property
   * - E.g. _undefined_ in "components.schemas.AmountType.title"
   * - E.g. currencyID in "components.schemas.AmountType.properties.currencyID.description"
   * @returns Translations of the UBL Data types
   */
  const tp = (attribute: string, schema: string, property: string|undefined = undefined) => {
    const key = property ?
      `components.schemas.${schema}.properties.${property}.${attribute}` : 
      `components.schemas.${schema}.${attribute}`;
    const ns = namespaces.find(ns => i18n.exists(key, {ns}));
    return ns ? t(key, {ns}) : key;
  }

  return tp;
}
