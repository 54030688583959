/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, CircularProgress, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../common/Page';
import { BasicInfo } from '../../model/CompanyTypes';
import { FetchNSGAgentBasicInformation } from '../../api/VirtualFinlandTestbedApi';
import Card from '../common/Card';

const SearchCompaniesView: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [basicInfo, setBasicInfo] = useState<BasicInfo|undefined>(undefined);
  const [error, setError] = useState<string|undefined>(undefined);

  const addKeyword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setBasicInfo(() => undefined);
    setLoading(() => true);
    setError(() => undefined);
    if (code) {
      FetchNSGAgentBasicInformation(code)
      .then(res => {
        setBasicInfo(() => res.data[0]);
      })
      .catch(err => {
        console.log("Error while fetching NSGAgentBasicInfo", err);
        return undefined;
      })
      .finally(() => {
        setLoading(() => false);
      })
    }
  }

  return (
    <Page>
      <Card title={t("app.components.SearchCompaniesView.heading")}>
        <p>
          <Trans
            i18nKey="app.components.SearchCompaniesView.description"
            components={{
              link1: <a href="https://testbed.fi" target="_blank"> Virtual Finland Testbed</a>,
              link2: <a href="https://definitions.testbed.fi/definitions/draft/NSG/Agent/BasicInformation" target="_blank">NSG/Agent/BasicInformation</a>
            }}
          />
        </p>
        <form onSubmit={(e) => addKeyword(e)} autoComplete="off">
          <Box sx={{display: "flex"}}>
            <TextField
              label={t("app.components.SearchCompaniesView.searchByCode")}
              value={code}
              onChange={e => setCode(e.target.value)}
              size="small"
              sx={{flexGrow: 1}}
              disabled={loading}
            />
            <IconButton color="primary" type="submit" disabled={loading}>
              <SearchOutlinedIcon/>
            </IconButton>
          </Box>
        </form>
        { error && 
          <Alert severity="error" onClose={()=> setError(() => undefined)}>
            <AlertTitle>{t("app.components.SearchCompaniesView.errorNoResults")}</AlertTitle>
            {t("app.components.SearchCompaniesView.errorNoResultsMsg")}
          </Alert>
        }
        <Box sx={{minHeight: "400px"}}>
          { loading && 
            <Box display={"flex"} justifyContent={"center"}>
              <CircularProgress sx={{margin: tokens.spacing.xxs}}/>
            </Box>
          }
          { basicInfo && 
            <Box>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.name.description")}>
                    <span>{t("app.companyinfo.legalentity.basicInfo.name.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.name}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registrationDate.description")}>
                    <span>{t("app.companyinfo.legalentity.basicInfo.registrationDate.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registrationDate}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.legalForm.description")}>
                    <span>{t("app.companyinfo.legalentity.basicInfo.legalForm.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.legalForm}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.legalStatus.description")}>
                    <span>{t("app.companyinfo.legalentity.basicInfo.legalStatus.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.legalStatus}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.description")}>
                    <span>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.title")}</span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.fullAddress.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.fullAddress.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.fullAddress}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.thoroughfare.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.thoroughfare.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.thoroughfare}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorDesignator.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorDesignator.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.locatorDesignator}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorName.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorName.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.locatorName}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressArea.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressArea.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.addressArea}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.postCode.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.postCode.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.postCode}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.postName.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.postName.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.postName}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.poBox.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.poBox.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.poBox}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_1.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_1.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.adminUnitLevel_1}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_2.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_2.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.adminUnitLevel_2}</span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressId.description")}>
                    <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressId.title")}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>{basicInfo.registeredAddress.addressId}</span>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
      </Card>
    </Page>
  )
}

export default SearchCompaniesView;