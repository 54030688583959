import React, { useEffect, useState, PropsWithChildren, useContext } from "react";
import log from "loglevel";
import _ from "lodash";
import * as OmaYritysApi from "../api/OmaYritysApi";
import { BasicInfo } from '../model/CompanyTypes';
import { Company, FinvoiceDocument, JsonObject, SessionAccount, SessionRole, StandardBusinessDocument } from '../model/OmaXTypes';
import { Catalogue, Item } from '../model/Peppol/PreAwardCatalogue';
import { FetchNSGAgentBasicInformation } from '../api/VirtualFinlandTestbedApi';
import { BusinessDocument, ChartOfAccounts, GeneralLedgerEntry, Product, Report } from '../model/AppModels';
import { EpcisEvent } from '../model/EpcisModels';

export enum AppStateProperty {
  SIGNUP_SESSION = "signupSession",
  LOGIN_SESSION = "loginSession",
  SELECT_COMPANY = "selectCompany",
  SESSION_ACCOUNT = "sessionAccount",
  CURRENT_COMPANY = "currentCompany",
  BASICINFO = "basicInfo",
  CATALOGUES = "catalogues",
  CATALOGUEPRODUCTS = "catalogueProducts",
  EPCISEVENTS = "epcisEvents",
  PEPPOL_DOCUMENTS = "peppolDocuments",
  FINVOICE_DOCUMENTS = "finvoiceDocuments",
  BUSINESS_DOCUMENTS = "businessDocuments",
  PEPPOL_PARTICIPANTS = "peppolParticipants",
  ACCOUNTING_ENTRIES = "accountingEntries",
  CHART_OF_ACCOUNTS = "chartOfAccounts",
  REPORTS = "reports"
}

export enum AppStatePropertyState {
  UNINITIALIZED = "uninitialized",
  INITIALIZED = "initialized",
  ERROR = "error"
}

export const SESSION_SCOPE = "omayritys";

const setDefaultPropStates = () => {
  const states: {[id: string]: AppStatePropertyState} = {};
  Object.entries(AppStateProperty).forEach(entry => {
    states[`${entry[1]}`] = AppStatePropertyState.UNINITIALIZED;
  });
  return states;
}

interface AppState {
  isLoadingApp: boolean,
  sessionAccount?: SessionAccount,
  company?: Company,
  basicInfo?: BasicInfo,
  catalogueProducts: {[id: string]: JsonObject<Product>},
  catalogues: {[id: string]: JsonObject<Catalogue>},
  epcisEvents: {[productId: string]: JsonObject<EpcisEvent>[]},
  peppolDocuments: StandardBusinessDocument[],
  finvoiceDocuments: FinvoiceDocument[],
  businessDocuments: JsonObject<BusinessDocument<any>>[],
  peppolParticipants: {[longPatricipantID: string]: string},
  accountingEntries: GeneralLedgerEntry[],
  chartOfAccounts?: ChartOfAccounts,
  reports: Report[],
  propertyStates: {[id: string]: AppStatePropertyState}
}

export type AppStateType = AppState & {
  // Functions
  signupAsync: (businessId: string) => Promise<SessionAccount|undefined>,
  loginAsync: (password: string) => Promise<SessionAccount|undefined>,
  selectCompanyAsync: (companyCode: string) => Promise<SessionAccount|undefined>,
  isAdmin: () => boolean,
  getCurrentCompanyAsync: () => Promise<Company|undefined>,
  getBasicInfoAsync: (companyCode: string) => Promise<BasicInfo|undefined>,
  getCataloguesAsync: () => Promise<{[id: string]: JsonObject<Catalogue>}>,
  getCatalogueProductsAsync: () => Promise<{[id: string]: JsonObject<Product>}>,
  getEpcisEventsAsync: (productId: string) => Promise<{[productId: string]: JsonObject<EpcisEvent>[]}>,
  getPeppolDocumentsAsync: () => Promise<StandardBusinessDocument[]>,
  getFinvoiceDocumentsAsync: () => Promise<FinvoiceDocument[]>,
  getBusinessDocumentsAsync: () => Promise<JsonObject<BusinessDocument<any>>[]>,
  searchParticipantAsync: (participantID: string) => Promise<string|undefined>,
  getAccountingEntriesAsync: () => Promise<GeneralLedgerEntry[]>,
  getChartOfAccountsAsync: () => Promise<ChartOfAccounts|undefined>,
  getReportsAsync: () => Promise<Report[]>,
  refreshAppState: () => Promise<void>,
  resetAppState: () => Promise<void>,
  logout: () => Promise<void>,
  deleteSession: () => Promise<void>,
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  catalogueProducts: {},
  catalogues: {},
  epcisEvents: {},
  peppolDocuments: [],
  finvoiceDocuments: [],
  businessDocuments: [],
  peppolParticipants: {},
  accountingEntries: [],
  reports: [],
  propertyStates: setDefaultPropStates()
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  signupAsync: (businessId: string) => new Promise<SessionAccount|undefined>(() => {}),
  loginAsync: (password: string) => new Promise<SessionAccount|undefined>(() => {}),
  selectCompanyAsync: (companyCode: string) => new Promise<SessionAccount|undefined>(() => {}),
  isAdmin: () => false,
  getCurrentCompanyAsync: () => new Promise<Company|undefined>(() => {}),
  getBasicInfoAsync: (companyCode: string) => new Promise<BasicInfo|undefined>(() => {}),
  getCataloguesAsync: () => new Promise<{[id: string]: JsonObject<Catalogue>}>(() => {}),
  getCatalogueProductsAsync: () => new Promise<{[id: string]: JsonObject<Product>}>(() => {}),
  getEpcisEventsAsync: (productId: string) => new Promise<{[productId: string]: JsonObject<EpcisEvent>[]}>(() => {}),
  getPeppolDocumentsAsync: () => new Promise<StandardBusinessDocument[]>(() => []),
  getFinvoiceDocumentsAsync: () => new Promise<FinvoiceDocument[]>(() => []),
  getBusinessDocumentsAsync: () => new Promise<JsonObject<BusinessDocument<any>>[]>(() => []),
  searchParticipantAsync: (participantID: string) => new Promise<string|undefined>(() => {}),
  getAccountingEntriesAsync: () => new Promise<GeneralLedgerEntry[]>(() => []),
  getChartOfAccountsAsync: () => new Promise<ChartOfAccounts|undefined>(() => {}),
  getReportsAsync: () => new Promise<Report[]>(() => []),
  refreshAppState: () => new Promise<void>(resolve => resolve()),
  resetAppState: () => new Promise<void>(resolve => resolve()),
  logout: () => new Promise<void>(resolve => resolve()),
  deleteSession: () => new Promise<void>(resolve => resolve()),
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  // Run this useEffect only once when app loads
  useEffect(() => {
    loadAppStateAsync();
  }, []);

  // This useEffect will run after login
  useEffect(() => {
    if (appState.sessionAccount) {
      if (appState.sessionAccount.data.companyCode) {
        getCurrentCompanyAsync()
        .finally(() => {
          // After the current company is fetched, isLoadingApp can be changed to false
          // although there are more information that still need to be fetched.
          setAppState(appState => ({...appState, isLoadingApp: false}));
        });
      }
      else {
        // Session account is set but a company is not selected => possible case with admin users
        setAppState(appState => ({...appState, isLoadingApp: false}));
      }
    }
  }, [appState.sessionAccount]);

  useEffect(() => {
    if (appState.company?.id) {
      getBasicInfoAsync(appState.company.code);
      getCataloguesAsync();
      getCatalogueProductsAsync();
      getPeppolDocumentsAsync();
      getFinvoiceDocumentsAsync();
      getBusinessDocumentsAsync();
    }
  }, [appState.company]);
  
  // private
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    setAppState(appState => ({...appState, isLoadingApp: true}));
    getSessionAsync();
    // isLoadingApp will be set to false when current company is fetched
  }

  // const fetchAsync = async <T,>(
  //   func: () => Promise<AxiosResponse<T>>, 
  //   callback: (data: T) => T,
  //   defaultRes: any,
  //   funcName?: string
  //   ) => {
  //   const promise = func()
  //   .then(res => {
  //     if (res.data) {
  //       callback(res.data);
  //       return res.data;
  //     }
  //     logger.debug(`ACP fetchAsync ${funcName ?? ""} response without a company`, res);
  //     return 
  //   })
  // }

  const signupAsync = async (businessId: string): Promise<SessionAccount|undefined> => {
    const AppStateProp = AppStateProperty.SIGNUP_SESSION;
    return promises[AppStateProp] ?? (
      async (businessId: string): Promise<SessionAccount|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.Signup(businessId)
        .then(res => {
          if (res.data) {
            const sessionAccount = res.data;
            setAppState(oldState => ({...oldState, sessionAccount}));
            return sessionAccount;
          }
          logger.debug("ACP signupAsync response without SessionAccount", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP signupAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(businessId);
  }

  const loginAsync = async (token: string): Promise<SessionAccount|undefined> => {
    const AppStateProp = AppStateProperty.LOGIN_SESSION;
    return promises[AppStateProp] ?? (
      async (token: string): Promise<SessionAccount|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.Login(token)
        .then(res => {
          if (res.data) {
            const sessionAccount = res.data;
            setAppState(oldState => ({...oldState, sessionAccount}));
            return sessionAccount;
          }
          logger.debug("ACP loginAsync response without SessionAccount", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP loginAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(token);
  }

  const selectCompanyAsync = async (companyCode: string): Promise<SessionAccount|undefined> => {
    const AppStateProp = AppStateProperty.SELECT_COMPANY;
    return promises[AppStateProp] ?? (
      async (companyCode: string): Promise<SessionAccount|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.SelectCompany(companyCode)
        .then(res => {
          if (res.data) {
            const sessionAccount = res.data;
            setAppState(oldState => ({...oldState, sessionAccount}));
            return sessionAccount;
          }
          logger.debug("ACP selectCompanyAsync response without SessionAccount", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP selectCompanyAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(companyCode);
  }

  const getSessionAsync = async (): Promise<SessionAccount|undefined> => {
    const AppStateProp = AppStateProperty.SESSION_ACCOUNT;
    return promises[AppStateProp] ?? (
      async (): Promise<SessionAccount|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetSession()
        .then(res => {
          if (res.data) {
            const sessionAccount = res.data;
            setAppState(oldState => ({...oldState, sessionAccount}));
            return sessionAccount;
          }
          logger.debug("ACP getSessionAccountAsync response without a sessionAccount", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          if (err.response?.status === 401) {
            logger.debug("ACP getSessionAccountAsync got 401 Unauthorized response", err);
          }
          else {
            logger.debug("ACP getSessionAccountAsync catched an error", err);
            propState = AppStatePropertyState.ERROR;
          }
          // If session is fetched successfully, isLoadingApp will stay true until current company is also fetched.
          // Because fetching a session failed, current company cannot be fetched and isLoadingApp should be changed to false.
          setAppState(appState => ({...appState, isLoadingApp: false}));
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const isAdmin = () => {
    return appState.sessionAccount?.role === SessionRole.Admin;
  }

  const getCurrentCompanyAsync = async (): Promise<Company|undefined> => {
    const AppStateProp = AppStateProperty.CURRENT_COMPANY;
    return promises[AppStateProp] ?? (
      async (): Promise<Company|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetSessionCompany()
        .then(res => {
          if (res.data) {
            const company = res.data;
            setAppState(oldState => ({...oldState, company}));
            propState = AppStatePropertyState.INITIALIZED;
            return company;
          }
          logger.debug("ACP getCurrentCompanyAsync response without a company", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP getCurrentCompanyAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getBasicInfoAsync = async (companyCode: string): Promise<BasicInfo|undefined> => {
    const AppStateProp = AppStateProperty.BASICINFO;
    return promises[AppStateProp] ?? (
      async (companyCode: string): Promise<BasicInfo|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = FetchNSGAgentBasicInformation(companyCode)
        .then(res => {
          if (res.data) {
            const basicInfo = res.data[0];
            setAppState(oldState => ({...oldState, basicInfo}));
            return basicInfo;
          }
          logger.debug("ACP getBasicInfoAsync response without basicInfo", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP getBasicInfoAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )(companyCode);
  }

  const getCataloguesAsync = async (): Promise<{[id: string]: JsonObject<Catalogue>}> => {
    const AppStateProp = AppStateProperty.CATALOGUES;
    return promises[AppStateProp] ?? (
      async (): Promise<{[id: string]: JsonObject<Catalogue>}> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetCatalogues("Catalogue")
        .then(res => {
          let cataloguesMap: { [id: string]: JsonObject<Catalogue>} = {};
          if (res.data) {
            res.data.forEach(json => cataloguesMap = {...cataloguesMap, [json.id!]: json as JsonObject<Catalogue>});
            setAppState(oldState => ({...oldState, catalogues: cataloguesMap}));
            return cataloguesMap;
          }
          logger.debug("ACP getCataloguesAsync response without catalogues", res);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .catch(err => {
          logger.debug("ACP getCataloguesAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getCatalogueProductsAsync = async (): Promise<{[id: string]: JsonObject<Product>}> => {  
    const AppStateProp = AppStateProperty.CATALOGUEPRODUCTS; 
    return promises[AppStateProp] ?? (
      async (): Promise<{[id: string]: JsonObject<Product>}> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetCatalogues("CatalogueProductItem")
        .then(res => {
          let productsMap: { [id: string]: JsonObject<Product>} = {};
          if (res.data) {
            res.data.forEach(json => productsMap = {...productsMap, [json.id!]: json as JsonObject<Product>});
            setAppState(oldState => ({...oldState, catalogueProducts: productsMap}));
            return productsMap;
          }
          logger.debug("ACP getCatalogueProductsAsync response without catalogueProducts", res);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .catch(err => {
          logger.debug("ACP getCatalogueProductsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getEpcisEventsAsync = async (productId: string): Promise<{[productId: string]: JsonObject<EpcisEvent>[]}> => {  
    const AppStateProp = AppStateProperty.EPCISEVENTS;
    const promiseKey = `${AppStateProperty.EPCISEVENTS}-${productId}`;
    return promises[promiseKey] ?? (
      async (productId: string): Promise<{[id: string]: JsonObject<EpcisEvent>[]}> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetEpcisEvents(productId)
        .then(res => {
          if (res) {
            setAppState(oldState => ({...oldState, epcisEvents: {...oldState.epcisEvents, [productId]: sortEpcisEvents(res)}}));
            return res;
          }
          logger.debug("ACP getEpcisEventsAsync response without epcisEvents", res);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .catch(err => {
          logger.debug("ACP getEpcisEventsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return {};
        })
        .finally(() => {
          removePromise(promiseKey);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(promiseKey, promise);
        return promise;
      }
    )(productId);
  }

  const sortEpcisEvents = (resp: JsonObject<EpcisEvent>[]) => {
    const sorted = resp.sort((a, b) => {
      const aCreatedAtStr = a.jsonData.eventTime;
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = b.jsonData.eventTime;
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return bCreatedAt - aCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return 1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return -1;
      }
      return 0;
    });
    return sorted;
  }

  const getPeppolDocumentsAsync = async (): Promise<StandardBusinessDocument[]> => {  
    const AppStateProp = AppStateProperty.PEPPOL_DOCUMENTS;
    return promises[AppStateProp] ?? (
      async (): Promise<StandardBusinessDocument[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.ListPeppolDocuments()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, peppolDocuments: res.data}));
            return res.data;
          }
          logger.debug("ACP getPeppolDocumentsAsync response without peppolDocuments", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getPeppolDocumentsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getFinvoiceDocumentsAsync = async (): Promise<FinvoiceDocument[]> => {    
    const AppStateProp = AppStateProperty.FINVOICE_DOCUMENTS;
    return promises[AppStateProp] ?? (
      async (): Promise<FinvoiceDocument[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.ListFinvoiceDocuments()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, finvoiceDocuments: res.data}));
            return res.data;
          }
          logger.debug("ACP getFinvoiceDocumentsAsync response without peppolDocuments", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getFinvoiceDocumentsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getBusinessDocumentsAsync = async (): Promise<JsonObject<BusinessDocument<any>>[]> => {    
    const AppStateProp = AppStateProperty.BUSINESS_DOCUMENTS;
    return promises[AppStateProp] ?? (
      async (): Promise<JsonObject<BusinessDocument<any>>[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.ListBusinessDocuments()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, businessDocuments: res.data}));
            return res.data;
          }
          logger.debug("ACP getBusinessDocumentsAsync response without peppolDocuments", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getBusinessDocumentsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  // longParticipantID means participant id's scheme + value e.g. iso6523-actorid-upis::0216:00377023610700100
  const searchParticipantAsync = async (longParticipantID: string): Promise<string|undefined> => {
    if (appState.peppolParticipants[longParticipantID]) {
      return appState.peppolParticipants[longParticipantID];
    }
    const AppStateProp = AppStateProperty.PEPPOL_PARTICIPANTS;
    const promiseKey = `${AppStateProperty.PEPPOL_PARTICIPANTS}-${longParticipantID}`;
    return promises[promiseKey] ?? (
      async (participantID: string): Promise<string|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.SearchParticipant(participantID)
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, peppolParticipants: {...oldState.peppolParticipants, [participantID]: res.data}}));
            return res.data;
          }
          logger.debug("ACP searchParticipantAsync response without catalogues", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP searchParticipantAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(promiseKey);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(promiseKey, promise);
        return promise;
      }
    )(longParticipantID);
  }

  const getAccountingEntriesAsync = async (): Promise<GeneralLedgerEntry[]> => {
    const AppStateProp = AppStateProperty.ACCOUNTING_ENTRIES;
    return promises[AppStateProp] ?? (
      async (): Promise<GeneralLedgerEntry[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.ListGeneralLedgerEntries()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, accountingEntries: res.data.sort((a, b) => b.entryNumber > a.entryNumber ? -1 : 1)}));
            return res.data;
          }
          logger.debug("ACP getAccountingEntriesAsync response without entries", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getAccountingEntriesAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getChartOfAccountsAsync = async (): Promise<ChartOfAccounts|undefined> => {
    const AppStateProp = AppStateProperty.CHART_OF_ACCOUNTS;
    return promises[AppStateProp] ?? (
      async (): Promise<ChartOfAccounts|undefined> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.GetChartOfAccounts()
        .then(res => {
          if (res.data) {
            const chartOfAccounts = res.data;
            setAppState(oldState => ({...oldState, chartOfAccounts}));
            return chartOfAccounts;
          }
          logger.debug("ACP getChartOfAccountsAsync response without data", res);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .catch(err => {
          logger.debug("ACP getChartOfAccountsAsync catched an data", err);
          propState = AppStatePropertyState.ERROR;
          return undefined;
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const getReportsAsync = async (): Promise<Report[]> => {
    const AppStateProp = AppStateProperty.REPORTS;
    return promises[AppStateProp] ?? (
      async (): Promise<Report[]> => {
        let propState = AppStatePropertyState.INITIALIZED;
        const promise = OmaYritysApi.ListReports()
        .then(res => {
          if (res.data) {
            setAppState(oldState => ({...oldState, reports: res.data.sort((a, b) => b.createdOn > a.createdOn ? -1 : 1)}));
            return res.data;
          }
          logger.debug("ACP getReportsAsync response without entries", res);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .catch(err => {
          logger.debug("ACP getReportsAsync catched an error", err);
          propState = AppStatePropertyState.ERROR;
          return [];
        })
        .finally(() => {
          removePromise(AppStateProp);
          setAppState(oldState => ({...oldState, propertyStates: {...oldState.propertyStates, [AppStateProp]: propState}}));
        });
        addPromise(AppStateProp, promise);
        return promise;
      }
    )();
  }

  const refreshAppState = async (): Promise<void> => {
    await loadAppStateAsync();
  }

  const resetAppState = async (): Promise<void> => {
    setAppState(() => DefaultAppState);
  }

  const logout = async (): Promise<void> => {
    setAppState(appState => ({...appState, isLoadingApp: true}));
    OmaYritysApi.Logout()
    .then(() => {
      return resetAppState();
    })
    .then(() => {
      return loadAppStateAsync();
    });
  }

  const deleteSession = async (): Promise<void> => {
    setAppState(appState => ({...appState, isLoadingApp: true}));
    OmaYritysApi.DeleteSession()
    .then(() => {
      return resetAppState();
    })
    .then(() => {
      return loadAppStateAsync();
    });
  }

  return (
    <AppStateContext.Provider value={{
      ...appState,
      signupAsync,
      loginAsync,
      selectCompanyAsync,
      isAdmin,
      getCurrentCompanyAsync,
      getBasicInfoAsync,
      getCataloguesAsync,
      getCatalogueProductsAsync,
      getEpcisEventsAsync,
      getPeppolDocumentsAsync,
      getFinvoiceDocumentsAsync,
      getBusinessDocumentsAsync,
      searchParticipantAsync,
      getAccountingEntriesAsync,
      getChartOfAccountsAsync,
      getReportsAsync,
      refreshAppState,
      resetAppState,
      logout,
      deleteSession
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppStateContext = () => {
  const appContext = useContext(AppStateContext);
  if (appContext === undefined) {
    throw new Error("useAppStateContext must be used within a AppContextProvider");
  }
  return appContext;
}

export default AppContextProvider;