import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import XMLViewer from "react-xml-viewer";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import * as PublicFolderApi from "../../api/PublicFolderApi";

const XmlContainer = styled.div`
  ul {
    padding: revert
  }
`;

interface Props {
  accountingNumber?: string,
  docStandard: string,
  docType: string,
  handleClose: () => void
}

const XbrlGlDocumentDialog: React.FC<Props> = ({accountingNumber, docStandard, docType, handleClose}) => {
  const { t } = useTranslation();
  const [xml, setXml] = useState<string>("");
  const [visualize, setVisualize] = useState<boolean>(true);

  const getLoadingElement = () => {
    const rootElement = document.createElement("div");
    const p = document.createElement("p");
    p.textContent=t("common.actions.loading");
    rootElement.appendChild(p);
    return rootElement;
  }

  const [doc, setDoc] = useState<HTMLDivElement>(getLoadingElement());

  useEffect(() => {
    let xmlD = "";
    setXml(() => "");
    setVisualize(() => true);
    if (!!accountingNumber) {
      OmaYritysApi.GetSBDContent(accountingNumber, docStandard, docType)
      .then(res => {
        xmlD = res.data;
        setXml(() => res.data);
        return PublicFolderApi.GetFile("XBRLGL-UTF-8.xsl");
      })
      .then(res => {
        const rootElement = document.createElement("div");
        const parser = new DOMParser();
        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(parser.parseFromString(res.data, "text/xml"));
        const fragment = xsltProcessor.transformToFragment(parser.parseFromString(xmlD, "text/xml"), document);
        rootElement.appendChild(fragment);
        setDoc(rootElement);
      })
    }
  }, [accountingNumber]);

  return (
    <Dialog open={!!accountingNumber} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        {(!xml) &&
          <p>{t("common.actions.loading")}</p>
        }
        {xml &&
          <div style={{width: "100%", height: "100%"}}>
            <FormGroup sx={{position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
              <FormControlLabel 
                control={<Switch checked={visualize} 
                onChange={() => setVisualize(old => !old)} />} 
                label={t("common.actions.visualize")} 
                labelPlacement="start" 
              />
            </FormGroup>
            <XmlContainer 
              style={{display: visualize ? "block" : "none", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}
              dangerouslySetInnerHTML={{__html: doc.innerHTML}}
            ></XmlContainer>
            <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs, fontSize: "medium"}}>
              <XMLViewer xml={xml} collapsible />
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions sx={{display: "flex"}}>
        <Button variant="contained" onClick={handleClose}>{t("common.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default XbrlGlDocumentDialog;