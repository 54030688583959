/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Header from './components/common/Header';
import GlobalStyles from './components/GlobalStyles';
import LoginView from './components/pages/LoginView';
import ProductsView from './components/pages/Products/ProductsView';
import Hankinta from './components/Hankinta/Hankinta';
import FrontPage from './components/FrontPage/FrontPage';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import NavBar from './components/common/NavBar';
import PageNotFoundView from './components/pages/PageNotFoundView';
import SearchCompaniesView from './components/pages/SearchCompaniesView';
import CatalogueProductItemView from './components/pages/CatalogueProductItemView';
import CatalogueView from './components/pages/CatalogueView';
import CatalogueLineView from './components/pages/CatalogueLineView';
import Yritystiedot from './components/Yritystiedot/Yritystiedot';
import SalesView from './components/pages/sales/SalesView';
import ProcurementsView from './components/pages/procurements/ProcurementsView';
import LoadingModal from './components/modals/LoadingModal';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fi';
import CataloguesView from './components/pages/Catalogues/CataloguesView';
import SalesInvoiceFormView from './components/pages/sales/SalesInvoiceForm';
import AccountingView from './components/pages/Accounting/AccountingView';
import ReportingView from './components/pages/Reporting/ReportingView';
import CreateReportForm from './components/pages/Reporting/CreateReportForm';
import { useTranslation } from 'react-i18next';

const styles = {
  contentContainer: css({
    padding: tokens.spacing.m,
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    }
  })
}

const App: React.FC = () => {
  const { i18n } = useTranslation();

  let muiTheme = createTheme({
    palette: {
      primary: {
        main: tokens.colors.brandBase,
      },
      secondary: {
        main: tokens.colors.highlightBase,
      },
    },
  });

  return (
    <Suspense fallback={<LoadingModal isOpen={true} />}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
        <ThemeProvider theme={muiTheme}>
          <AppContextProvider>
            <GlobalStyles />
            <Router>
              <HeaderBackground>
                <ContentContainer>
                  <Header/>
                </ContentContainer>
              </HeaderBackground>

              <TopNavigationBackground>
                <ContentContainer>
                  <NavBar/>
                </ContentContainer>
              </TopNavigationBackground>

              <AppStateContext.Consumer>
                {state => state.isLoadingApp ?
                  <LoadingModal isOpen={state.isLoadingApp} /> :
                  state.company ?
                    <Routes>
                      { RoutePaths.map((it, i) => 
                        <Route key={`route-${i}`} path={it.to} element={it.element}/>
                      )}
                      { AdministrationPaths.map((it, i) => 
                        <Route key={`route-${i}`} path={it.to} element={it.element}/>
                      )}
                      <Route path="/login" element={<Navigate to={"/"}/>}/>
                      <Route path="*" element={<PageNotFoundView/>}/>
                    </Routes> : 
                    <Routes>
                      <Route path="/login" element={<LoginView/>}/>
                      <Route path="/search-companies" element={<SearchCompaniesView/>}/>
                      <Route path="*" element={<Navigate to={"/login"}/>}/>
                    </Routes>
                }
              </AppStateContext.Consumer>
            </Router>
          </AppContextProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Suspense>
  )
}

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  // Visible in navigation bar
  { to: "/", label: "frontpage", element: <FrontPage/>, visibleInNav: true},
  { to: "/products", label: "products", element: <ProductsView/>, visibleInNav: true},
  { to: "/catalogues", label: "catalogues", element: <CataloguesView/>, visibleInNav: true},
  { to: "/sales", label: "sales", element: <SalesView/>, visibleInNav: true},
  { to: "/procurements", label: "procurements", element: <ProcurementsView/>, visibleInNav: true},
  { to: "/accounting", label: "accounting", element: <AccountingView/>, visibleInNav: true},
  { to: "/companyinfo", label: "companyInfo", element: <Yritystiedot/>, visibleInNav: true},
  // Hidden from navigation bar
  { to: "/products/new", label: "new-product", element: <CatalogueProductItemView/>, visibleInNav: false},
  { to: "/products/:productId/edit", label: "edit-product", element: <CatalogueProductItemView/>, visibleInNav: false},
  { to: "/catalogues/new", label: "new-catalogue", element: <CatalogueView/>, visibleInNav: false},
  { to: "/catalogues/:catalogueId/edit", label: "edit-catalogue", element: <CatalogueView/>, visibleInNav: false},
  { to: "/catalogues/:catalogueId/new-line", label: "new-catalogueline", element: <CatalogueLineView/>, visibleInNav: false},
  { to: "/catalogues/:catalogueId/line/:catalogueLineId/edit", label: "edit-catalogueline", element: <CatalogueLineView/>, visibleInNav: false},
  { to: "/salesinvoices/new", label: "new-salesInvoice", element: <SalesInvoiceFormView/>, visibleInNav: false}
];

export const AdministrationPaths: RoutePathProps[] = [
  // Visible in navigation bar
  { to: "/reporting", label: "reporting", element: <ReportingView/>, visibleInNav: true},
  { to: "/search-companies", label: "search-companies", element: <SearchCompaniesView/>, visibleInNav: true},
  // Hidden from navigation bar
  { to: "/reporting/new", label: "new-report", element: <CreateReportForm/>, visibleInNav: false},
]

export default App
