import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, ListItemText, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppStateContext } from '../../../state/AppStateContext';
import { JsonObject } from '../../../model/OmaXTypes';
import { BizLocation, EPC, EpcisEvent, GetEpcisEventType, ILMD, QuantityElement, ReadPoint, TypeValue } from '../../../model/EpcisModels';
import { Product } from '../../../model/AppModels';
import { toDateTimeString } from '../../../utils/dateUtils';

const EpcisEventsDialog: React.FC<{open: boolean, handleClose: () => void, productObject: JsonObject<Product>}> = ({open, handleClose, productObject}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();

  useEffect(() => {
    if (open) {
      appContext.getEpcisEventsAsync(productObject.id);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {`${productObject.jsonData.name.value} ${t("app.components.EpcisEventsDialog.title")}`}
      </DialogTitle>
      <DialogContent>
        { appContext.epcisEvents[productObject.id]?.map((eventObject, eventIndex) => <EpcisEventAccordion key={eventIndex} eventObject={eventObject} eventIndex={eventIndex}/>)}
      </DialogContent>
    </Dialog>
  );
}

const EpcisEventAccordion: React.FC<{eventObject: JsonObject<EpcisEvent>, eventIndex: number}> = ({eventObject, eventIndex}) => {
  const { t } = useTranslation();
  const epcisEvent = eventObject.jsonData;
  const epcisEventType = GetEpcisEventType(epcisEvent.eventType);

  const PrintAttributeGridElement = (keyPrefix: string, attrName: string, attrValue: {subAttrName: string, subAttrValue: JSX.Element}[]|JSX.Element|undefined) => {
    if (attrValue instanceof Array) {
      const subAttributes = attrValue as {subAttrName: string, subAttrValue: JSX.Element}[];
      return (
        <Grid container>
          <Grid item xs={12}>
            <ListItemText primaryTypographyProps={{fontWeight: 700}} primary={t(`app.models.EpcisEvent.${attrName}`)} />
          </Grid>
          { subAttributes.map(({subAttrName, subAttrValue}) => (
            <Grid key={`${keyPrefix}-${subAttrName}`} container item xs={12}>
              <Grid item xs={12} sm={4}>
                <ListItemText primaryTypographyProps={{fontWeight: 700, paddingLeft: tokens.spacing.s}} primary={subAttrName} />
              </Grid>
              <Grid item xs={12} sm={8} sx={{textAlign: {xs: "right", sm: "left"}}}>
                {subAttrValue}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )
    }
    else {
      const value = attrValue as JSX.Element;
      return (
        <Grid container>
          <Grid item xs={12} sm={4}>
            <ListItemText primaryTypographyProps={{fontWeight: 700}} primary={t(`app.models.EpcisEvent.${attrName}`)} />
          </Grid>
          <Grid item xs={12} sm={8} sx={{textAlign: {xs: "right", sm: "left"}}}>
            {value}
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Accordion sx={{backgroundColor: tokens.colors.highlightLight3}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: tokens.colors.highlightLight2}}>
        <Grid container sx={{alignItems: "center", marginRight: {sm: tokens.spacing.xs}}}>
          <Grid item xs={6} sm={4}>
            <ListItemText primaryTypographyProps={{variant: "button", fontWeight: "700"}} primary={t(`app.models.EventType.${epcisEvent.eventType}`)} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <ListItemText primaryTypographyProps={{variant: "button", fontWeight: "700"}} primary={epcisEvent.action} />
          </Grid>
          <Grid item xs={12} sm={true}>
            <ListItemText primaryTypographyProps={{variant: "button"}} primary={toDateTimeString(epcisEvent.eventTime)} />
          </Grid>
          <Grid container item xs={"auto"} spacing={1}>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} sx={{alignItems: "center"}}>
          <Grid item container xs={12}>
            { epcisEventType && MapEpcisEventAttributesToGridElements(epcisEvent, eventIndex, epcisEventType.getAttributeTypeMap()).filter(it => it.value).map((attr, attrIndex) => 
              <Grid key={`attr-${eventObject.id}-${attrIndex}`} item container xs={12}>
                { attrIndex > 0 &&
                  <Grid item xs={12}>
                    <Divider/>
                  </Grid>
                }
                { PrintAttributeGridElement(`attr-${eventObject.id}-${attrIndex}`, attr.name, attr.value) }
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const MapEpcisEventAttributesToGridElements = (epcisEvent: EpcisEvent, eventIndex: number, attributes: { name: string, baseName: string, type: string }[]): {name: string, value: {subAttrName: string, subAttrValue: JSX.Element}[]|JSX.Element|undefined}[] => {
  return attributes.map(attr => {
    if (!Object.hasOwn(epcisEvent, attr.name)) {
      return {name: attr.name, value: undefined};
    }
    const attrName = attr.name as keyof typeof epcisEvent;
    let attrValue = epcisEvent[attrName];
    let elementValue: {subAttrName: string, subAttrValue: JSX.Element}[]|JSX.Element|undefined = undefined;
    if (!attrValue) {
      return {name: attr.name, value: undefined};
    }
    switch (attr.type) {
      case "string": {
        elementValue = <ListItemText key={`${eventIndex}-${attrName}`} primary={attrValue as string} />;
        break;
      }
      case "Date": {
        elementValue = <ListItemText key={`${eventIndex}-${attrName}`} primary={toDateTimeString(attrValue as string)} />;
        break;
      }
      case "ReadPoint":
      case "BizLocation": {
        const val = attrValue as ReadPoint|BizLocation;
        if (val.id?.value) {
          elementValue = <ListItemText key={`${eventIndex}-${attrName}`} primary={val.id?.value} />;
        }
        break;
      }
      case "Array<TypeValue>": {
        const val = attrValue as TypeValue[];
        if (val.length > 0) {
          elementValue = (
            <Box>
              {val.map((it, itIndex) =>
                <ListItemText 
                  key={`${eventIndex}-${attrName}-${itIndex}`}
                  primary={it.value}
                  secondary={
                    <span>
                      <Typography variant="caption" sx={{fontWeight: 700}}>TYPE: </Typography>{it.type}
                    </span>
                  }
                />
              )}
            </Box>
          );
        }
        break;
      }
      case "ILMD": {
        // ILMD field may contain a list custom xml attributes e.g.
        // "ilmd": {
        //   "items": [
        //     {
        //       "subAttrName": {
        //         "@subAttrAttr": "SUPPLIER_ASSIGNED",
        //         "#text": "ANIMAL-NUMBER-001"
        //       }
        //     }
        //   ]
        // }
        const val = attrValue as ILMD;
        if (val.items && val.items.length > 0) {
          const elementSubValues = val.items?.flatMap(item => Object.entries(item).map(([subAttrName, subAttrValues]) => {
            // Variable anySubAttrValues contains object e.g. { "@subAttrAttr": "SUPPLIER_ASSIGNED", "#text": "ANIMAL-NUMBER-001"}
            const anySubAttrValues = subAttrValues as any;
            const xmlTextValue = anySubAttrValues["#text"] as string;
            // Variable xmlAttributes contain all other key-value pairs except #text from anySubAttrValues
            const xmlAttributes = Object.entries(anySubAttrValues).filter(([key]) => key !== "#text") as [string, string][];
            // Set #text as primary in ListItemText and all other xmlAttributes to the secondary
            const subElementValue = (
              <Box>
                <ListItemText 
                  key={`${eventIndex}-${attrName}-${subAttrName}`}
                  primary={xmlTextValue}
                  secondary={
                    <span>
                      { xmlAttributes.map(([k, v]) => (
                        <span
                          key={`${eventIndex}-${attrName}-${subAttrName}-${k}`}
                          style={{marginRight: tokens.spacing.xxs}}
                        >
                          <Typography variant="caption" sx={{fontWeight: 700}}>{k?.replace("@", "")}: </Typography>
                          {v}
                        </span>
                      ))}
                    </span>
                  }
                />
              </Box>
            );
            return {subAttrName: subAttrName, subAttrValue: subElementValue};
          }));
          elementValue = elementSubValues;
        }
        break;
      }
      case "Array<EPC>": {
        const val = attrValue as EPC[];
        if (val.length > 0) {
          elementValue = (
            <Box>
              {val.map((it, itIndex) => <ListItemText key={`${eventIndex}-${attrName}-${itIndex}`} primary={it.value}/>)}
            </Box>
          );
        }
        break;
      }
      case "Array<QuantityElement>": {
        const val = attrValue as QuantityElement[];
        if (val.length > 0) {
          elementValue = (
            <Box>
              {val.map((it, itIndex) =>
                <ListItemText 
                  key={`${eventIndex}-${attrName}-${itIndex}`}
                  primary={
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="body1">
                          <span style={{fontWeight: 700}}>EPC Class: </span>
                          <span>{it.epcClass}</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1">
                          <span style={{fontWeight: 700}}>Quantity: </span>
                          <span>{it.quantity}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              )}
            </Box>
          );
        }
        break;
      }
    }
    return {name: attr.name, value: elementValue};
  });
}

export default EpcisEventsDialog;