import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { getIn, useFormik } from 'formik';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { BalanceType, BusinessDocument, BusinessDocumentType, CreateGeneralLedgerEntriesWrapper, CreateGeneralLedgerEntryData, Invoice, InvoiceType } from '../../model/AppModels';
import { Alert, AlertTitle, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { GetNSGReceiptLedgerEntries, GetPeppolInvoiceLedgerEntries, PostNSGReceipt, PostPeppolInvoice } from '../../api/OmaYritysApi';
import { AppStateProperty, AppStatePropertyState, useAppStateContext } from '../../state/AppStateContext';
import { GridItem } from '../common/CommonStyles';
import * as PeppolUtils from '../../utils/peppolUtils';
import * as FinvoiceUtils from '../../utils/finvoiceUtils';
import { NSGInvoiceCredential } from '../../model/NsgInvoiceModels';

interface Props {
  type: "invoice"|"receipt",
  document?: Invoice|BusinessDocument<NSGInvoiceCredential>,
  handleClose: () => void
}

export interface LedgerEntryFormModel {
  rows: CreateGeneralLedgerEntryData[]
}

export const LedgerEntryFormSchema: Yup.ObjectSchema<CreateGeneralLedgerEntryData> = Yup.object({
  sourceLineID: Yup.string().required('Required'),
  description: Yup.string().required("required"),
  accountNumber: Yup.string().required("required")
});

const LedgerEntryFormModelSchema: Yup.ObjectSchema<LedgerEntryFormModel> = Yup.object({
  rows: Yup.array().of(LedgerEntryFormSchema).required('Required')
});

const CreateGeneralLedgerEntriesDialog: React.FC<Props> = ({type, document: busDoc, handleClose}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [loadingChartOfAccounts, setLoadingChartOfAccounts] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<CreateGeneralLedgerEntriesWrapper>();
  const [sending, setSending] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>();
  const [entryNumber, setEntryNumber] = useState<string>("");
  const [balanceType, setBalanceType] = useState<string>(BalanceType.DEBIT);

  useEffect(() => {
    if (appContext.propertyStates[AppStateProperty.CHART_OF_ACCOUNTS] !== AppStatePropertyState.INITIALIZED) {
      setLoadingChartOfAccounts(() => true);
      appContext.getChartOfAccountsAsync()
      .catch(() => setNotification(() => "loadingFailed"))
      .finally(() => setLoadingChartOfAccounts(() => false));
    }
  }, []);

  useEffect(() => {
    setNotification(() => undefined);
    setSending(() => false);
    if (type === "invoice" && busDoc?.type === InvoiceType.PEPPOL) {
      setBalanceType(() => PeppolUtils.isSender(busDoc, appContext.company?.code) ? BalanceType.CREDIT : BalanceType.DEBIT);
      setLoadingData(() => true);
      GetPeppolInvoiceLedgerEntries(busDoc.document.id)
      .then(res => setData(res.data))
      .catch(() => setNotification(() => "error"))
      .finally(() => setLoadingData(() => false));
    }
    else if (type === "receipt" && busDoc?.type === BusinessDocumentType.ERECEIPT) {
      setBalanceType(() => FinvoiceUtils.isSender(busDoc.jsonData.credentialSubject, appContext.company?.code) ? BalanceType.CREDIT : BalanceType.DEBIT);
      setLoadingData(() => true);
      GetNSGReceiptLedgerEntries(busDoc.id)
      .then(res => setData(res.data))
      .catch(() => setNotification(() => "error"))
      .finally(() => setLoadingData(() => false));
    }
    else {
      setNotification(() => "invalidInvoiceType");
    }
  }, [busDoc]);

  const initialValues = (): LedgerEntryFormModel => {
    const formikRows = data?.entries ? data.entries.map((entry, entryIndex) => ({
      sourceLineID: entry.sourceLineID,
      accountNumber: entry.accountNumber || "",
      description: entry.description || ""
    })) : [];
    return { rows: formikRows };;
  }

  const submit = async (formData: LedgerEntryFormModel) => {
    setSending(() => true);
    setNotification(() => undefined);
    if (data && formData) {
      const postData = {...data, entries: formData.rows};
      (type == "invoice" ? PostPeppolInvoice(postData) : PostNSGReceipt(postData))
      .then(res => {
        const en = res.data.length > 0 ? res.data[0].entryNumber : "";
        setEntryNumber(() => en);
        setSending(() => false);
        setNotification(() => "success");
        appContext.getPeppolDocumentsAsync();
      })
      .catch(err => {
        console.error("Error while submitting GeneralLedger entries", err);
        setSending(() => false);
        setNotification(() => "error");
      });
    }
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: LedgerEntryFormModelSchema,
    onSubmit: submit
  });

  useEffect(() => {
    formik.resetForm({values: initialValues()})
  }, [data]);

  const availableChartOfAccounts = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {t("app.components.CreateGeneralLedgerEntryDialog.selectAccount")}
    </MenuItem>];
    if (appContext.chartOfAccounts) {
      const accounts = balanceType === BalanceType.CREDIT ? appContext.chartOfAccounts.salesAccounts : appContext.chartOfAccounts.purchaseAccounts;
      return empty.concat(accounts
        .map(it => (
          <MenuItem key={"selectAccount-" + it.code} value={it.code}>
            <Tooltip title={<ListItemText primary={it.description?.length > 0 ? it.description : it.title} secondary={it.code} secondaryTypographyProps={{color: "white", variant: "caption"}}/>} placement="right-end">
              <ListItemText primary={`${it.code} - ${it.title}`}/>
            </Tooltip>
          </MenuItem>
          )
        ));
    }
    return empty;
  }

  const getErrorMessage = (errorKey?: string, param?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + param;
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  // useEffect(() => {
  //   console.log("Formikprops.values", formik.values);
  // }, [formik.values]);
  // useEffect(() => {
  //   console.log("Formikprops.errors", formik.errors);
  // }, [formik.errors]);
  
  return (
    <Dialog open={!!busDoc} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {t("app.components.CreateGeneralLedgerEntryDialog.title")}
        </DialogTitle>
        <DialogContent>
          { notification &&
            <Alert severity={notification === "success" ? "success" : "error"}>
              <AlertTitle>{t(`app.components.CreateGeneralLedgerEntryDialog.${notification}Title`)}</AlertTitle>
              {t(`app.components.CreateGeneralLedgerEntryDialog.${notification}Msg`) + ` ${entryNumber}.`}
            </Alert>
          }
          { sending && 
            <Alert severity="info" icon={false}>
              <AlertTitle sx={{display: "flex", alignItems: "center"}}>
                <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
                {t(`app.components.CreateGeneralLedgerEntryDialog.postingInvoice`)}
              </AlertTitle>
            </Alert>
          }
          { (loadingChartOfAccounts || loadingData) && 
            <Alert severity="info" icon={false}>
              <AlertTitle sx={{display: "flex", alignItems: "center"}}>
                <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
                {t(`common.actions.loading`)}
              </AlertTitle>
            </Alert>
          }
          { !loadingChartOfAccounts && !loadingData && 
            <Card sx={{width: "100%", marginTop: tokens.spacing.s}}>
              <List component="div" disablePadding>
                {data?.entries?.map((entry, entryIndex) => (
                  <ListItem
                    key={`entries-row-${entryIndex}`}
                    sx={{background: (entryIndex % 2 ? tokens.colors.highlightLight2 : tokens.colors.highlightLight3)}}
                    disablePadding
                    divider
                  >
                    <Grid container sx={{padding: `${tokens.spacing.xxs}`}}>
                      <GridItem item xs={12} sm={4}>
                        <ListItemText
                          primaryTypographyProps={{fontWeight: "700"}} sx={{margin: 0}} 
                          primary={entry.description} 
                        />
                      </GridItem>
                      <GridItem item xs={12} sm={8}>
                        <Box display="flex" sx={{paddingX: tokens.spacing.xxs, flex: 1, "& > *": {flex: 1}, justifyContent: "flex-end"}}>
                          <FormControl error={Boolean(getIn(formik.touched, `rows[${entryIndex}].accountNumber`)) && Boolean(getIn(formik.errors, `rows[${entryIndex}].accountNumber`))}>
                            <InputLabel className="MuiInputLabel-outlined" id={`rows[${entryIndex}].accountNumber`}>{t("app.components.CreateGeneralLedgerEntryDialog.selectAccount")}</InputLabel>
                            <Select 
                              name={`rows[${entryIndex}].accountNumber`}
                              labelId={`rows[${entryIndex}].accountNumber`}
                              value={formik.values.rows[entryIndex]?.accountNumber ?? ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              variant="outlined"
                              disabled={sending || !!notification}
                            >
                              { availableChartOfAccounts() }
                            </Select>
                            { Boolean(getIn(formik.touched, `rows[${entryIndex}].accountNumber`)) && Boolean(getIn(formik.errors, `rows[${entryIndex}].accountNumber`)) && 
                              <FormHelperText>{getErrorMessage(getIn(formik.errors, `rows[${entryIndex}].accountNumber`))}</FormHelperText> 
                            }
                          </FormControl>
                        </Box>
                      </GridItem>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Card>
          }
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button variant="outlined" onClick={handleClose} disabled={sending}>{notification ? t("common.actions.close") : t("common.actions.cancel")}</Button>
          <Button variant="contained" type="submit" disabled={sending || !!notification}>{t("common.actions.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateGeneralLedgerEntriesDialog;