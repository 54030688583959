import { Identifiable } from './identifiable';

export class Address extends Identifiable {
  PoBox?: string;
  StreetName?: string;
  LocatorDesignator?: string;
  PostName?: string;
  AdminUnitLevel1?: string;
  AdminUnitLevel2?: string;
  PostCode?: string;
  AddressTypeCode?: string;
}