
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';
import { AppStateContext, AppStateProperty, AppStatePropertyState } from "../state/AppStateContext";
import { Box, Button, LinearProgress, Link, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import TablePaginationActions from "./common/TablePaginationActions";
import { toDateTimeString } from '../utils/dateUtils';
import { Invoice, InvoiceType } from '../model/AppModels';
import * as PeppolUtils from '../utils/peppolUtils';
import * as FinvoiceUtils from '../utils/finvoiceUtils';
import InvoiceDocumentDialog from './modals/InvoiceDocumentDialog';
import { SBDStatus } from '../model/OmaXTypes';
import SendInvoiceDialog from './modals/SendInvoiceDialog';
import CreateGeneralLedgerEntriesDialog from './modals/CreateGeneralLedgerEntryDialog';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const InvoiceReceiver: React.FC<{invoice: Invoice, index: number}> = ({invoice, index}) => {
  const appContext = useContext(AppStateContext);
  const [name, setName] = useState<string>(PeppolUtils.getShortParticipantID(invoice.document.receiverId));

  useEffect(() => {
    if (invoice.type === InvoiceType.PEPPOL) {
      appContext.searchParticipantAsync(invoice.document.receiverId)
      .then(name => {
        if (name) {
          setName(() => name);
        }
      });
    }
    else if (invoice.type === InvoiceType.FINVOICE) {
      setName(() => FinvoiceUtils.getBuyerOrganisationName(invoice));
    }
  }, [invoice]);

  return (
    <ListItemText primary={name} secondary={PeppolUtils.getShortParticipantID(invoice.document.receiverId)}/>
  );
}

const SalesInvoicesTable: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogInvoice, setDialogInvoice] = useState<Invoice|undefined>(undefined);
  const [salesInvoices, setSalesInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [postInvoice, setPostInvoice] = useState<Invoice|undefined>(undefined);
  const [sendInvoice, setSendInvoice] = useState<Invoice|undefined>(undefined);

  useEffect(() => {
    let invoices: Invoice[] = [];
    setLoading(() => true);
    if (appContext.peppolDocuments) {
      invoices.push(...appContext.peppolDocuments
      .filter(it => it.documentIDStandard === "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2")
      .filter(it => it.senderId === PeppolUtils.getLongPeppolParticipantID(appContext.company?.code))
      .map(it => ({type: InvoiceType.PEPPOL, document: it})));
    }
    const finvoiceId = `0037${appContext.company?.code?.replace('-', '')}`;
    if (appContext.finvoiceDocuments) {
      invoices.push(...appContext.finvoiceDocuments
      .filter(it => it.senderId === finvoiceId)
      .map(it => ({type: InvoiceType.FINVOICE, document: it})));
    }
    setSalesInvoices(() => sortSalesInvoices(invoices));
    if (dialogInvoice) {
      const updatedDialogInvoice = invoices.find(it => it.document.id === dialogInvoice.document.id);
      setDialogInvoice(() => updatedDialogInvoice);
    }
    setLoading(() => false);
  }, [appContext.peppolDocuments, appContext.finvoiceDocuments]);

  useEffect(() => {
    if (appContext.propertyStates[AppStateProperty.PEPPOL_DOCUMENTS] !== AppStatePropertyState.UNINITIALIZED &&
        appContext.propertyStates[AppStateProperty.FINVOICE_DOCUMENTS] !== AppStatePropertyState.UNINITIALIZED) {
      setIsInitialized(() => true);
    }
  }, [
    appContext.propertyStates[AppStateProperty.PEPPOL_DOCUMENTS], 
    appContext.propertyStates[AppStateProperty.FINVOICE_DOCUMENTS]
  ]);

  const sortSalesInvoices = (list: Invoice[]): Invoice[] => {
    const sorted = list.sort((a, b) => {
      const aCreatedAtStr = a.document.createdOn;
      const aCreatedAt = aCreatedAtStr ? Date.parse(aCreatedAtStr) : undefined;
      const bCreatedAtStr = b.document.createdOn;
      const bCreatedAt = bCreatedAtStr ? Date.parse(bCreatedAtStr) : undefined;
      if (aCreatedAt && bCreatedAt) {
        return  bCreatedAt - aCreatedAt;
      }
      else if (aCreatedAt && !bCreatedAt) {
        return -1;
      }
      else if (!aCreatedAt && bCreatedAt) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStylesheet = (invoice?: Invoice) => {
    switch (invoice?.type) {
      case InvoiceType.FINVOICE:
        return "Finvoice3.0.sef.json";
      case InvoiceType.PEPPOL:
      default:
        return "stylesheet-ubl.sef.json";
    }
  }

  const sentContent = (i: Invoice) => {
    // Show sendInvoice button if the invoice is not sent yet and
    // - it is a session invoice (user is an anonymous user) or
    // - user is an admin (admin cannot see any session invoices)
    if (i.document.status === SBDStatus.CREATED && (i.document.sessionAccountID || appContext.isAdmin())) {
      return <Button variant="text" sx={{padding: 0}} onClick={() => setSendInvoice(() => i)}>{t("app.components.InvoiceDocumentsTable.sendInvoiceBtn")}</Button>;
    }
    return [SBDStatus.SENDING, SBDStatus.SENT].includes(i.document.status) ? "x" : "-";
  }

  const postedToAccountingContent = (i: Invoice) => {
    // Show postInvoice button if the invoice is sent but is not yet accounted and 
    // - it is a session invoice (user is an anonymous user) or
    // - it is an admin invoice (user can be anonymous or admin).
    //   - Anonymous user can post an admin invoice to accounting but it does not change the status of the invoice.
    if ([SBDStatus.SENDING, SBDStatus.SENT].includes(i.document.status) && !i.document.accountingNumber) {
      return <Button variant="text" sx={{padding: 0}} onClick={() => setPostInvoice(() => i)}>{t("app.components.InvoiceDocumentsTable.postInvoiceBtn")}</Button>;
    }
    // Show accountingNumber (entryNumber) if the invoice is alreeady accounted.
    else if (i.document.accountingNumber) {
      return i.document.accountingNumber;
    }
    return "-";
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - salesInvoices.length) : 0;

  return (
    <Box sx={{overflow: "auto"}}>
    <TableContainer component={Paper} style={{minWidth: "800px"}}>
      <Table aria-label="simple table">
        <colgroup>
          <col width="10%" />
          <col width="20%" />
          <col width="10%" />
          <col width="10%" />
          <col width="20%" />
          <col width="15%" />
          <col width="15%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCellThin>{t("app.components.InvoiceDocumentsTable.documentType")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.receiver")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.amount")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.openDocument")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.createdOn")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.sent")}</TableCellThin>
            <TableCellThin align="left">{t("app.components.InvoiceDocumentsTable.postedToAccounting")}</TableCellThin>
          </TableRow>
        </TableHead>
        <TableBody>
          { (!isInitialized || loading) &&
            <TableRow>
              <TableCellThin colSpan={8} sx={{padding: 0}}>
                <LinearProgress/>
              </TableCellThin>
            </TableRow>
          }
          {(rowsPerPage > 0
            ? salesInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : salesInvoices
          ).map((i, index) => (
            <TableRow
              key={index}
              sx={{backgroundColor: i.document.sessionAccountID ? tokens.colors.highlightLight2 : undefined}}
            >
              <TableCellThin component="th" scope="row">{t(`app.models.PurchaceInvoiceType.${i.type}`)}</TableCellThin>
              <TableCellThin align="left">
                <InvoiceReceiver invoice={i} index={index}/>
              </TableCellThin>
              <TableCellThin align="left">{i.type === InvoiceType.PEPPOL ? PeppolUtils.getInvoiceTotalAmount(i) : FinvoiceUtils.getInvoiceTotalAmount(i)}</TableCellThin>
              <TableCellThin align="left">
                <Link style={{padding: 0}} onClick={() => setDialogInvoice(i)}>{t("app.components.InvoiceDocumentsTable.openHTML")}</Link>
              </TableCellThin>
              <TableCellThin align="left">{toDateTimeString(i.document.createdOn)}</TableCellThin>
              <TableCellThin align="left">
                { sentContent(i) }
              </TableCellThin>
              <TableCellThin align="left">
                { postedToAccountingContent(i) }
              </TableCellThin>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCellThin colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              count={salesInvoices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <InvoiceDocumentDialog
        invoice={dialogInvoice}
        stylesheet={determineStylesheet(dialogInvoice)}
        handleClose={() => setDialogInvoice(undefined)}
        postInvoice={() => setPostInvoice(dialogInvoice)}
        sendInvoice={() => setSendInvoice(dialogInvoice)}
      />
      <SendInvoiceDialog
        invoice={sendInvoice}
        handleClose={() => setSendInvoice(() => undefined)}
      />
      <CreateGeneralLedgerEntriesDialog
        type="invoice"
        document={postInvoice}
        handleClose={() => setPostInvoice(() => undefined)}
      />
    </TableContainer>
    </Box>
  );
}

export default SalesInvoicesTable;