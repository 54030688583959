import React, { HTMLInputTypeAttribute, PropsWithChildren, useEffect } from "react";
import { FormikProps, getIn } from "formik";
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Paper, Popover, Select, Switch, TextField, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol } from './CommonStyles';
import { Address, AllowanceCharge, Catalogue, Contract, DeliveryUnit, Party, Price, ValidityPeriod } from '../../model/Peppol/PreAwardCatalogue';
import { useCustomTranslations } from '../../utils/useCustomTranslations';
import { Product } from '../../model/AppModels';
import { QuantityType, TextType } from '../../model/UBL/UnqualifiedDataTypes';
import { AllowanceChargeFormFields, QuantityTypeFormFields, ValidityPeriodFormFields } from '../pages/PreAwardCatalogueFormFields';

declare type Props = {
  fieldName: string,
  fieldPath: string,
  memberOfClass: string,
  formikProps: FormikProps<any>,
  availableValues?: JSX.Element[],
  disabled?: boolean,
  bgColor?: string,
  fieldType?: HTMLInputTypeAttribute
}

export const InputWrapper: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <Card sx={{display: "flex", flexGrow: "1", alignItems: "center", backgroundColor: tokens.colors.highlightLight4, padding: tokens.spacing.xs}} component={Paper}>
      <Box sx={{flexGrow: 1}}>
        {children}
      </Box>
    </Card>
  );
}

export const TooltippedTextField: React.FC<Props> = ({fieldName, fieldPath, memberOfClass, formikProps, disabled, fieldType}) => {
  const { t } = useTranslation();
  const ct = useCustomTranslations();

  return (
    <Tooltip sx={{display: "flex"}} disableFocusListener disableTouchListener title={ct("description", memberOfClass, fieldName)} placement='top-start'>
      <TextField 
        type={fieldType}
        name={fieldPath} 
        label={ct("title", memberOfClass, fieldName)}
        value={getIn(formikProps.values, fieldPath) ?? ""} 
        onChange={formikProps.handleChange} 
        onBlur={formikProps.handleBlur} 
        error={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath))} 
        helperText={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath)) && t(`common.validation.${getIn(formikProps.errors, fieldPath)}`)} 
        variant="outlined" 
        disabled={disabled}
        style={{backgroundColor: tokens.colors.whiteBase}}
      />
    </Tooltip>
  );
}

export const TooltippedAccordion: React.FC<PropsWithChildren<Props>> = ({fieldName, memberOfClass, children, bgColor}) => {
  const ct = useCustomTranslations();

  return (
    <Accordion key={fieldName} sx={{backgroundColor: bgColor}}>
      <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>{ct("title", memberOfClass, fieldName)}</span>
        </AccordionSummary>
      </Tooltip>
      <AccordionDetails>
        <Box sx={{ flexGrow: 1 }}>
          <FlexCol>
            {children}
          </FlexCol>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export const ToolTippedCard: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string, popoverI18nKey?: string, popoverEl?: JSX.Element}>> = ({fieldName, memberOfClass, children, bgColor, popoverI18nKey, popoverEl}) => {
  const { t } = useTranslation();
  const ct = useCustomTranslations();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement|null>(null);

  return (
    <Card key={fieldName} sx={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
          <Typography variant="button" color={tokens.colors.brandBase}>{ct("title", memberOfClass, fieldName)}</Typography>
        </Tooltip>
        {popoverEl && 
          <Button
            sx={{marginLeft: tokens.spacing.s}}
            color="primary" variant="outlined"
            startIcon={<InfoIcon/>}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            {t(popoverI18nKey ?? "common.actions.readMore")}
          </Button>
        }
        {popoverEl &&
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            {popoverEl}
          </Popover>
        }
      </Box>
      {children}
    </Card>
  );
}

export const DeletableToolTippedCard: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string, onDelete?: () => void}>> = ({fieldName, memberOfClass, children, bgColor, onDelete}) => {
  const { t } = useTranslation();
  const ct = useCustomTranslations();

  return (
    <Card key={fieldName} sx={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Box display="flex" justifyContent="space-between">
        <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
          <Typography variant="button" color={tokens.colors.brandBase}>{ct("title", memberOfClass, fieldName)}</Typography>
        </Tooltip>
        { onDelete &&
          <Button size="small" color="error" onClick={onDelete}>
            {t("common.actions.delete")}
          </Button>
        }
      </Box>
      {children}
    </Card>
  );
}

export const DeletableMuiCard: React.FC<PropsWithChildren<{title?: string, deletable?: boolean, deleteCard?: () => void}>> = ({title, deletable, deleteCard, children}) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Card
      sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs, paddingX: tokens.spacing.xs, paddingBottom: tokens.spacing.xs}}
      component={Paper}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
          <Box>
            { title &&
              <Typography variant="caption" color={tokens.colors.brandBase}>{title}</Typography>
            }
          </Box>
          <Box>
            { deletable &&
              <Button sx={{justifySelf: "flex-end"}} size="small" color="error" onClick={() => deleteCard && deleteCard()}>
                {t("common.actions.delete")}
              </Button>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          { children }
        </Grid>
      </Grid>
    </Card>
  );
}

export const ToolTippedAddButton: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string, onAdd: () => void}>> = ({fieldName, memberOfClass, children, bgColor, onAdd}) => {
  const { t } = useTranslation();
  const ct = useCustomTranslations();

  return (
    <Card key={fieldName} sx={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
        <Button variant="outlined" size="small" onClick={onAdd}>
          <Typography>{t("app.components.CatalogueForms.add") + ct("title", memberOfClass, fieldName)}</Typography>
        </Button>
      </Tooltip>
      {children}
    </Card>
  );
}

export const TooltippedSelect: React.FC<Props> = ({fieldName, fieldPath, memberOfClass, formikProps, availableValues}) => {
  const ct = useCustomTranslations();

  return (
    <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
      <FormControl error={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath))}>
        <InputLabel className="MuiInputLabel-outlined" id={`${memberOfClass}-${fieldName}`}>{ct("title", memberOfClass, fieldName)}</InputLabel>
        <Select 
          name={fieldPath} 
          labelId={`${memberOfClass}-${fieldName}`}
          value={getIn(formikProps.values, fieldPath) ?? ""} 
          onChange={formikProps.handleChange} 
          onBlur={formikProps.handleBlur} 
          variant="outlined" 
        >
          { availableValues }
        </Select>
        { Boolean(getIn(formikProps.touched, fieldPath)) && getIn(formikProps.errors, fieldPath) && 
          <FormHelperText>{getIn(formikProps.errors, fieldPath)}</FormHelperText> 
        }
      </FormControl>
    </Tooltip>
  );
}

export const TooltippedSwitch: React.FC<Props> = ({fieldName, fieldPath, memberOfClass, formikProps}) => {
  const ct = useCustomTranslations();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikProps.setFieldValue(fieldPath, event.target.checked);
  };

  return (
    <Tooltip title={ct("description", memberOfClass, fieldName)} placement='top-start'>
      <FormControl error={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath))}>
        <FormControlLabel
          name={fieldPath}
          control={<Switch checked={getIn(formikProps.values, fieldPath) ?? false}
          onChange={handleChange} />}
          onBlur={formikProps.handleBlur}
          label={ct("title", memberOfClass, fieldName)}
        />
        { Boolean(getIn(formikProps.touched, fieldPath)) && getIn(formikProps.errors, fieldPath) && 
          <FormHelperText>{getIn(formikProps.errors, fieldPath)}</FormHelperText> 
        }
      </FormControl>
    </Tooltip>
  );
}

export const CataloguePartyForm: React.FC<{partyFieldName: string, partyFieldPath: string, formikProps: FormikProps<any>, fieldsFilter?: string[], required?: boolean}> = ({partyFieldName, partyFieldPath, formikProps, fieldsFilter, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  fieldsFilter = fieldsFilter ?? ["endpointID", "iD", "name", "postaladdress", "contact"];

  useEffect(() => {
    // Add a party template as default if it is required
    if (!formikProps.values[partyFieldName] && required) {
      add();
    }
  }, []);

  const add = () => {
    const partyTemplate: Party = {}
    formikProps.setFieldValue(partyFieldName, partyTemplate);
  }

  const del = () => {
    formikProps.setFieldValue(partyFieldName, undefined);
  }

  return (
    <ToolTippedCard fieldName={partyFieldName} memberOfClass="Catalogue">
      { formikProps.values[partyFieldName] &&
        <Grid container spacing={1}>
          { !required &&
            <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button size="small" color="error" onClick={() => del()}>
                {t("common.actions.delete")}
              </Button>
            </Grid>
          }
          { fieldsFilter.includes("endpointID") && 
            <Grid item xs={12} sm={12} md={6}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TooltippedTextField fieldName="endpointID" fieldPath={`${partyFieldPath}.endpointID.value`} memberOfClass="Party" formikProps={formikProps} />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="schemeID" fieldPath={`${partyFieldPath}.endpointID.schemeID`} memberOfClass="IdentifierType" formikProps={formikProps} />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
          }
          { fieldsFilter.includes("iD") && 
            <Grid item xs={12} sm={12} md={6}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                  <TooltippedTextField fieldName="partyIdentification" fieldPath={`${partyFieldPath}.partyIdentification[0].iD.value`} memberOfClass="Party" formikProps={formikProps} />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="schemeID" fieldPath={`${partyFieldPath}.partyIdentification[0].iD.schemeID`} memberOfClass="IdentifierType" formikProps={formikProps} />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
          }
          { fieldsFilter.includes("name") && 
            <Grid item xs={12} sm={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="partyName" fieldPath={`${partyFieldPath}.partyName[0].name.value`} memberOfClass="Party" formikProps={formikProps} />
              </InputWrapper>
            </Grid>
          }
          { fieldsFilter.includes("postaladdress") && 
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="streetName" fieldPath={`${partyFieldPath}.postaladdress.streetName.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="additionalStreetName" fieldPath={`${partyFieldPath}.postaladdress.additionalStreetName.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                  <TooltippedTextField fieldName="cityName" fieldPath={`${partyFieldPath}.postaladdress.cityName.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                  <TooltippedTextField fieldName="postalZone" fieldPath={`${partyFieldPath}.postaladdress.postalZone.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                  <TooltippedTextField fieldName="countrySubentity" fieldPath={`${partyFieldPath}.postaladdress.countrySubentity.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                  <TooltippedTextField fieldName="country" fieldPath={`${partyFieldPath}.postaladdress.country.identificationCode.value`} memberOfClass="Address" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          }
          { fieldsFilter.includes("contact") && 
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                <TooltippedTextField fieldName="name" fieldPath={`${partyFieldPath}.contact.name.value`} memberOfClass="Contact" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                <TooltippedTextField fieldName="telephone" fieldPath={`${partyFieldPath}.contact.telephone.value`} memberOfClass="Contact" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                <TooltippedTextField fieldName="telefax" fieldPath={`${partyFieldPath}.contact.telefax.value`} memberOfClass="Contact" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputWrapper>
                <TooltippedTextField fieldName="electronicMail" fieldPath={`${partyFieldPath}.contact.electronicMail.value`} memberOfClass="Contact" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          }
        </Grid>
      }
      { !formikProps.values[partyFieldName] && !required &&
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CataloguePartyForm.addParty") + ct("title", "Catalogue", partyFieldName)}</Typography>
          </Button>
        </Box>
      }
    </ToolTippedCard>
  );
}

export const ItemSpecificationDocumentReferenceForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addDocItem = () => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.push({
      iD: {value: ""}
    });
    formikProps.setFieldValue("itemSpecificationDocumentReference", docs);
  }

  const deleteDocItem = (docIndex: number) => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.splice(docIndex, 1);
    formikProps.setFieldValue("itemSpecificationDocumentReference", docs);
  }

  return (
    <ToolTippedCard fieldName="itemSpecificationDocumentReference" memberOfClass="Item">
      { formikProps.values.itemSpecificationDocumentReference?.map((doc, docIndex) => {
        return (
          <Card
            key={`itemSpecificationDocumentReference-${docIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="iD" fieldPath={`itemSpecificationDocumentReference[${docIndex}].iD.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteDocItem(docIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TooltippedTextField fieldName="documentTypeCode" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentTypeCode.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <TooltippedTextField fieldName="listID" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentTypeCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputWrapper>
                  <TooltippedTextField fieldName="documentType" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentType.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputWrapper>
                  <TooltippedTextField fieldName="documentDescription" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentDescription[0].value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TooltippedTextField fieldName="embeddedDocumentBinaryObject" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.embeddedDocumentBinaryObject.value`} memberOfClass="Attachment" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TooltippedTextField fieldName="mimeCode" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.embeddedDocumentBinaryObject.mimeCode`} memberOfClass="BinaryObjectType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TooltippedTextField fieldName="externalReference" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.externalReference.URI.value`} memberOfClass="Attachment" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addDocItem()}>
          <Typography>{t("app.components.ItemSpecificationDocumentReferenceForm.addAttachment")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemCommodityClassificationForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addClassification = () => {
    const docs = formikProps.values.commodityClassification ?? [];
    docs.push({
      itemClassificationCode: {value: ""}
    });
    formikProps.setFieldValue("commodityClassification", docs);
  }

  const deleteClassification = (docIndex: number) => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.splice(docIndex, 1);
    formikProps.setFieldValue("commodityClassification", docs);
  }

  return (
    <ToolTippedCard fieldName="commodityClassification" memberOfClass="Item">
      { formikProps.values.commodityClassification?.map((cc, ccIndex) => {
        return (
          <Card
            key={`commodityClassification-${ccIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid item xs>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TooltippedTextField fieldName="itemClassificationCode" fieldPath={`commodityClassification[${ccIndex}].itemClassificationCode.value`} memberOfClass="CommodityClassification" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <TooltippedTextField fieldName="listID" fieldPath={`commodityClassification[${ccIndex}].itemClassificationCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs="auto" sx={{display: "flex"}}>
                <Button color="error" onClick={() => deleteClassification(ccIndex)}>
                  {t("common.actions.delete")}
                </Button>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addClassification()}>
          <Typography>{t("app.components.ItemCommodityClassificationForm.AddCommodityClassification")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const HazardousItemForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addItem = () => {
    const items = formikProps.values.hazardousItem ?? [];
    items.push({
      iD: {value: ""},
      uNDGCode: {value: "", listID: "UNCL8273"}
    });
    formikProps.setFieldValue("hazardousItem", items);
  }

  const deleteItem = (itemIndex: number) => {
    const items = formikProps.values.hazardousItem ?? [];
    items.splice(itemIndex, 1);
    formikProps.setFieldValue("hazardousItem", items);
  }

  return (
    <ToolTippedCard fieldName="hazardousItem" memberOfClass="Item">
      { formikProps.values.hazardousItem?.map((it, itIndex) => {
        return (
          <Card
            key={`hazardousItem-${itIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="iD" fieldPath={`hazardousItem[${itIndex}].iD.value`} memberOfClass="HazardousItem" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItem(itIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TooltippedTextField fieldName="uNDGCode" fieldPath={`hazardousItem[${itIndex}].uNDGCode.value`} memberOfClass="HazardousItem" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <TooltippedTextField fieldName="listID" fieldPath={`hazardousItem[${itIndex}].uNDGCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItem()}>
          <Typography>{t("app.components.HazardousItemForm.AddHazardousItem")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemPropertyForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addItemProp = () => {
    const itemProps = formikProps.values.additionalItemProperty ?? [];
    itemProps.push({
      name: {value: ""},
      value: {value: ""}
    });
    formikProps.setFieldValue("additionalItemProperty", itemProps);
  }

  const deleteItemProp = (itemIndex: number) => {
    const itemProps = formikProps.values.additionalItemProperty ?? [];
    itemProps.splice(itemIndex, 1);
    formikProps.setFieldValue("additionalItemProperty", itemProps);
  }

  return (
    <ToolTippedCard fieldName="additionalItemProperty" memberOfClass="Item">
      { formikProps.values.additionalItemProperty?.map((it, itIndex) => {
        return (
          <Card
            key={`additionalItemProperty-${itIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="iD" fieldPath={`additionalItemProperty[${itIndex}].iD.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemProp(itIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <TooltippedTextField fieldName="name" fieldPath={`additionalItemProperty[${itIndex}].name.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TooltippedTextField fieldName="nameCode" fieldPath={`additionalItemProperty[${itIndex}].nameCode.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <TooltippedTextField fieldName="listID" fieldPath={`additionalItemProperty[${itIndex}].nameCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="value" fieldPath={`additionalItemProperty[${itIndex}].value.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="valueQualifier" fieldPath={`additionalItemProperty[${itIndex}].valueQualifier.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>{t("app.components.ItemPropertyForm.AddItemProperty")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemInstanceItemPropertyForm: React.FC<{formikProps: FormikProps<Product>, instanceIndex: number}> = ({formikProps, instanceIndex}) => {
  const { t } = useTranslation(["translation"]);
  const itemInstances = formikProps.values.itemInstance;

  if (!itemInstances) {
    return <></>;
  }

  const addItemProp = () => {
    if (itemInstances) {
      const itemProps = itemInstances[instanceIndex].additionalItemProperty ?? [];
      itemProps.push({
        name: {value: ""}
      });
      itemInstances[instanceIndex].additionalItemProperty = itemProps;
      formikProps.setFieldValue("itemInstance", itemInstances);
    }
  }

  const deleteItemProp = (itemIndex: number) => {
    if (itemInstances) {
      const itemProps = itemInstances[instanceIndex].additionalItemProperty ?? [];
      itemProps.splice(itemIndex, 1);
      itemInstances[instanceIndex].additionalItemProperty = itemProps;
      formikProps.setFieldValue("itemInstance", itemInstances);
    }
  }

  return (
    <ToolTippedCard fieldName="additionalItemProperty" memberOfClass="ItemInstance">
      { itemInstances[instanceIndex].additionalItemProperty?.map((it, itIndex) => {
        return (
          <Card
            key={`itemInstance-${instanceIndex}-additionalItemProperty-${itIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="name" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].name.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemProp(itIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="attributeID" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].rangeDimension.attributeID.value`} memberOfClass="Dimension" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <TooltippedTextField fieldName="description" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].rangeDimension.description.value`} memberOfClass="Dimension" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>{t("app.components.ItemInstanceItemPropertyForm.AddItemInstanceProperty")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemInstanceForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addItemInstance = () => {
    const itemProps = formikProps.values.itemInstance ?? [];
    itemProps.push({
    });
    formikProps.setFieldValue("itemInstance", itemProps);
  }

  const deleteItemInstance = (itemIndex: number) => {
    const itemProps = formikProps.values.itemInstance ?? [];
    itemProps.splice(itemIndex, 1);
    formikProps.setFieldValue("itemInstance", itemProps);
  }

  return (
    <ToolTippedCard fieldName="itemInstance" memberOfClass="Item">
      { formikProps.values.itemInstance?.map((it, itIndex) => {
        return (
          <Card
            key={`itemInstance-${itIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid container item xs spacing={1}>
                  <Grid item xs={12} md={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="bestBeforeDate" fieldPath={`itemInstance[${itIndex}].bestBeforeDate.valueString`} memberOfClass="ItemInstance" formikProps={formikProps} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="expiryDate" fieldPath={`itemInstance[${itIndex}].lotIdentification.expiryDate.valueString`} memberOfClass="LotIdentification" formikProps={formikProps} />
                    </InputWrapper>
                  </Grid>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemInstance(itIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ItemInstanceItemPropertyForm formikProps={formikProps} instanceIndex={itIndex} />
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemInstance()}>
          <Typography>{t("app.components.ItemInstanceForm.AddItemInstance")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemCertificateForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addItemInstance = () => {
    const certificates = formikProps.values.certificate ?? [];
    certificates.push({
      iD: {value: ""},
      certificateTypeCode: {value: ""},
      certificateType: {value: ""}
    });
    formikProps.setFieldValue("certificate", certificates);
  }

  const deleteItemInstance = (itemIndex: number) => {
    const certificates = formikProps.values.certificate ?? [];
    certificates.splice(itemIndex, 1);
    formikProps.setFieldValue("certificate", certificates);
  }

  return (
    <ToolTippedCard fieldName="certificate" memberOfClass="Item">
      { formikProps.values.certificate?.map((cert, certIndex) => {
        return (
          <Card
            key={`certificate-${certIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="iD" fieldPath={`certificate[${certIndex}].iD.value`} memberOfClass="Certificate" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemInstance(certIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <TooltippedTextField fieldName="certificateTypeCode" fieldPath={`certificate[${certIndex}].certificateTypeCode.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <TooltippedTextField fieldName="certificateType" fieldPath={`certificate[${certIndex}].certificateType.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <TooltippedTextField fieldName="remarks" fieldPath={`certificate[${certIndex}].remarks[0].value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <TooltippedTextField fieldName="issuerParty" fieldPath={`certificate[${certIndex}].issuerParty.partyName[0].name.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <CertificateDocumentReferenceForm formikProps={formikProps} certificateIndex={certIndex} />
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemInstance()}>
          <Typography>{t("app.components.ItemCertificateForm.AddCertificate")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const CertificateDocumentReferenceForm: React.FC<{formikProps: FormikProps<Product>, certificateIndex: number}> = ({formikProps, certificateIndex}) => {
  const { t } = useTranslation(["translation"]);
  const certificates = formikProps.values.certificate;

  if (!certificates) {
    return <></>;
  }

  const addItemProp = () => {
    if (certificates) {
      const docRefs = certificates[certificateIndex].documentReference ?? [];
      docRefs.push({
        iD: {value: ""}
      });
      certificates[certificateIndex].documentReference = docRefs;
      formikProps.setFieldValue("certificate", certificates);
    }
  }

  const deleteItemProp = (itemIndex: number) => {
    if (certificates) {
      const docRefs = certificates[certificateIndex].documentReference ?? [];
      docRefs.splice(itemIndex, 1);
      certificates[certificateIndex].documentReference = docRefs;
      formikProps.setFieldValue("certificate", certificates);
    }
  }

  return (
    <ToolTippedCard fieldName="documentReference" memberOfClass="Certificate">
      { certificates[certificateIndex].documentReference?.map((docRef, docRefIndex) => {
        return (
          <Card
            key={`certificate-${certificateIndex}-documentReference-${docRefIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="documentReference" fieldPath={`certificate[${certificateIndex}].documentReference[${docRefIndex}].iD.value`} memberOfClass="Certificate" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemProp(docRefIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>{t("app.components.CertificateDocumentReferenceForm.AddDocumentReference")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const ItemDimensionForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);

  const addItemProp = () => {
    const dimensions = formikProps.values.dimension ?? [];
    dimensions.push({
      attributeID: {value: ""},
      measure: {unitCodeListVersionID: "UNECERec20"},
      minimumMeasure: {unitCodeListVersionID: "UNECERec20"},
      maximumMeasure: {unitCodeListVersionID: "UNECERec20"}
    });
    formikProps.setFieldValue("dimension", dimensions);
  }

  const deleteItemProp = (itemIndex: number) => {
    const dimensions = formikProps.values.dimension ?? [];
    dimensions.splice(itemIndex, 1);
    formikProps.setFieldValue("dimension", dimensions);
  }

  return (
    <ToolTippedCard fieldName="dimension" memberOfClass="Item">
      { formikProps.values.dimension?.map((d, dIndex) => {
        return (
          <Card
            key={`dimension-${dIndex}`}
            sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} sx={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <TooltippedTextField fieldName="attributeID" fieldPath={`dimension[${dIndex}].attributeID.value`} memberOfClass="Dimension" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" sx={{display: "flex"}}>
                  <Button color="error" onClick={() => deleteItemProp(dIndex)}>
                    {t("common.actions.delete")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TooltippedTextField fieldName="measure" fieldPath={`dimension[${dIndex}].measure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCode" fieldPath={`dimension[${dIndex}].measure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCodeListVersionID" fieldPath={`dimension[${dIndex}].measure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TooltippedTextField fieldName="minimumMeasure" fieldPath={`dimension[${dIndex}].minimumMeasure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCode" fieldPath={`dimension[${dIndex}].minimumMeasure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCodeListVersionID" fieldPath={`dimension[${dIndex}].minimumMeasure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TooltippedTextField fieldName="maximumMeasure" fieldPath={`dimension[${dIndex}].maximumMeasure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCode" fieldPath={`dimension[${dIndex}].maximumMeasure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TooltippedTextField fieldName="unitCodeListVersionID" fieldPath={`dimension[${dIndex}].maximumMeasure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box sx={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>{t("app.components.ItemDimensionForm.AddDimension")}</Typography>
        </Button>
      </Box>
    </ToolTippedCard>
  )
}

export const CatalogueReferencedContractForm: React.FC<{formikProps: FormikProps<Catalogue>}> = ({formikProps}) => {
  const { t } = useTranslation(["translation"]);
  const referencedContract = formikProps.values.referencedContract;

  const add = () => {
    const contractTemplate: Contract = {
      contractTypeCode: {value: "311", listID: "UNCL1001"},
      contractDocumentReference: {
        iD: {value: ""}
      }
    }
    formikProps.setFieldValue("referencedContract", contractTemplate);
  }

  const del = () => {
    formikProps.setFieldValue("referencedContract", undefined);
  }

  return (
    <ToolTippedCard fieldName="documentReference" memberOfClass="Certificate">
      { referencedContract &&
        <Card
          key="documentReference"
          sx={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
          component={Paper}
        >
          <Grid container spacing={1} sx={{padding: "8px"}}>
            <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button size="small" color="error" onClick={() => del()}>
                {t("common.actions.delete")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="iD" fieldPath="referencedContract[0].iD.value" memberOfClass="Contract" formikProps={formikProps} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="issueDate" fieldPath="referencedContract[0].issueDate.valueString" memberOfClass="Contract" formikProps={formikProps} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="contractDocumentReference" fieldPath="referencedContract[0].contractDocumentReference.iD.value" memberOfClass="Contract" formikProps={formikProps} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TooltippedTextField fieldName="contractTypeCode" fieldPath="referencedContract[0].contractTypeCode.value" memberOfClass="Contract" formikProps={formikProps} disabled />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="listID" fieldPath={"referencedContract[0].contractTypeCode.listID"} memberOfClass="CodeType" formikProps={formikProps} disabled />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
          </Grid>
        </Card>
      }
      { !referencedContract &&
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CertificateDocumentReferenceForm.AddDocumentReference")}</Typography>
          </Button>
        </Box>
      }
    </ToolTippedCard>
  )
}

export const AddressListForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const addresses: Address[] = fieldProps.value ?? [];

  useEffect(() => {
    if (!addresses && required) {
      add();
    }
  }, []);

  const add = () => {
    addresses.push({});
    formikProps.setFieldValue(fieldPath, addresses);
  }

  const del = (itemIndex: number) => {
    addresses.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, addresses);
  }

  if (addresses?.length > 0) {
    return (
      <ToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { addresses?.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="streetName" fieldPath={`${fieldPath}[${itIndex}].streetName.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="additionalStreetName" fieldPath={`${fieldPath}[${itIndex}].additionalStreetName.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="cityName" fieldPath={`${fieldPath}[${itIndex}].cityName.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="postalZone" fieldPath={`${fieldPath}[${itIndex}].postalZone.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="countrySubentity" fieldPath={`${fieldPath}[${itIndex}].countrySubentity.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InputWrapper>
                    <TooltippedTextField fieldName="country" fieldPath={`${fieldPath}[${itIndex}].country.identificationCode.value`} memberOfClass="Address" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
              </Grid>
            </DeletableMuiCard>
          );
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CatalogueForms.add") + " " + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </ToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const PriceForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const price: Price = fieldProps.value;

  useEffect(() => {
    if (!formikProps.values[fieldPath] && required) {
      add();
    }
  }, []);

  const add = () => {
    const template: Price = {priceAmount: {}, priceTypeCode: {listID: "UNCL5387"}}
    formikProps.setFieldValue(fieldPath, template);
  }

  const del = () => {
    formikProps.setFieldValue(fieldPath, undefined);
  }

  if (price) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass} onDelete={del}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TooltippedTextField fieldName="priceAmount" fieldPath={`${fieldPath}.priceTypeCode.value`} memberOfClass="Price" formikProps={formikProps} />
                </Grid>
                <Grid item xs={4}>
                  <TooltippedTextField fieldName="currencyID" fieldPath={`${fieldPath}.priceTypeCode.currencyID`} memberOfClass="AmountType" formikProps={formikProps} />
                </Grid>
              </Grid>
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TooltippedTextField fieldName="priceTypeCode" fieldPath={`${fieldPath}.priceTypeCode.value`} memberOfClass="Price" formikProps={formikProps} />
                </Grid>
                <Grid item xs={4}>
                  <TooltippedTextField fieldName="listID" fieldPath={`${fieldPath}.priceTypeCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                </Grid>
              </Grid>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <TooltippedTextField fieldName="orderableUnitFactorRate" fieldPath={`${fieldPath}.orderableUnitFactorRate.value`} memberOfClass="Price" formikProps={formikProps} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <ValidityPeriodListForm fieldName="validityPeriod" fieldPath={`${fieldPath}.validityPeriod`} memberOfClass={"Price"} formikProps={formikProps} />
          </Grid>
          <Grid item xs={12}>
            <AllowanceChargeForm fieldName="allowanceCharge" fieldPath={`${fieldPath}.allowanceCharge`} memberOfClass={"Price"} formikProps={formikProps} />
          </Grid>
        </Grid>
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const QuantityForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const quantity: QuantityType = fieldProps.value;

  useEffect(() => {
    if (!quantity && required) {
      add();
    }
  }, []);

  const add = () => {
    const template: QuantityType = {unitCodeListID: "UNECERec20"}
    formikProps.setFieldValue(fieldPath, template);
  }

  const del = () => {
    formikProps.setFieldValue(fieldPath, undefined);
  }

  if (quantity) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass} onDelete={!required ? del : undefined}>
        <QuantityTypeFormFields fieldPath={fieldPath} formikProps={formikProps} />
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const AllowanceChargeForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const values: AllowanceCharge[] = fieldProps.value ?? [];
  console.log("AllowanceChargeForm values", values);

  useEffect(() => {
    if (values.length < 1 && required) {
      add();
    }
  }, []);

  const add = () => {
    values.push({chargeIndicator: {value: false}, allowanceChargeReason: {}, amount: {}});
    formikProps.setFieldValue(fieldPath, values);
  }

  const del = () => {
    formikProps.setFieldValue(fieldPath, undefined);
  }

  if (values.length > 0) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass} onDelete={!required ? del : undefined}>
        <AllowanceChargeFormFields fieldPath={`${fieldPath}[0]`} formikProps={formikProps} />
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const ValidityPeriodListForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const validityPeriods: ValidityPeriod[] = fieldProps.value ?? [];

  useEffect(() => {
    if (!formikProps.values[fieldPath] && required) {
      add();
    }
  }, []);

  const add = () => {
    validityPeriods.push({startDate: {}, endDate: {}});
    formikProps.setFieldValue(fieldPath, validityPeriods);
  }

  const del = (itemIndex: number) => {
    validityPeriods.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, validityPeriods);
  }

  if (validityPeriods?.length > 0) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { validityPeriods?.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <ValidityPeriodFormFields fieldPath={`${fieldPath}[${itIndex}]`} formikProps={formikProps} />
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={add}>
            <Typography>{t("app.components.CatalogueForms.add") + " " + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const DeliveryUnitListForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const values: DeliveryUnit[] = fieldProps.value ?? [];

  useEffect(() => {
    if (!formikProps.values[fieldPath] && required) {
      add();
    }
  }, []);

  const add = () => {
    values.push({batchQuantity: {unitCodeListID: "UNECERec20"}, consumerUnitQuantity: {unitCodeListID: "UNECERec20"}});
    formikProps.setFieldValue(fieldPath, values);
  }

  const del = (itemIndex: number) => {
    values.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, values);
  }

  if (values?.length > 0) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { values?.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <QuantityTypeFormFields fieldPath={`${fieldPath}[${itIndex}].batchQuantity`} formikProps={formikProps} />
                </Grid>
                <Grid item xs={12}>
                  <QuantityTypeFormFields fieldPath={`${fieldPath}[${itIndex}].consumerUnitQuantity`} formikProps={formikProps} />
                </Grid>
              </Grid>
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={add}>
            <Typography>{t("app.components.CatalogueForms.add") + " " + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const TextFieldListForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const values: TextType[] = fieldProps.value ?? [];

  useEffect(() => {
    if (!formikProps.values[fieldPath] && required) {
      add();
    }
  }, []);

  const add = () => {
    values.push({});
    formikProps.setFieldValue(fieldPath, values);
  }

  const del = (itemIndex: number) => {
    values.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, values);
  }

  if (values?.length > 0) {
    return (
      <DeletableToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        <Grid container spacing={1}>
          { values?.map((it, itIndex) => {
            return (
              <Grid key={`${fieldName}-${itIndex}`} item xs={12} sm={6} md={4} lg={3}>
                <DeletableMuiCard title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TooltippedTextField fieldName={fieldName} fieldPath={`${fieldPath}[${itIndex}].value`} memberOfClass={memberOfClass} formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </DeletableMuiCard>
              </Grid>
            )
          })}
        </Grid>
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={add}>
            <Typography>{t("app.components.CatalogueForms.add") + " " + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </DeletableToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

