import { Invoice, InvoiceType } from '../model/AppModels';
import { NSGInvoice } from '../model/NsgInvoiceModels';

export const getInvoiceTotalAmount = (pi: Invoice): string => {
  if (pi.type === InvoiceType.FINVOICE) {
    const invoice = pi.document.document["Finvoice"];
    const total = invoice["InvoiceDetails"]["InvoiceTotalVatIncludedAmount"]["#text"];
    return `${total} €`
  }
  
  return "virhe";
}

export const getSellerOrganisationName = (pi: Invoice): string => {
  return pi.document.document["Finvoice"]["SellerPartyDetails"]["SellerOrganisationName"];
}

export const getBuyerOrganisationName = (pi: Invoice): string => {
  return pi.document.document["Finvoice"]["BuyerPartyDetails"]["BuyerOrganisationName"];
}

export const isSender = (invoice?: NSGInvoice, companyCode?: string): boolean => {
  return invoice?.accountingSupplierParty.party.partyIdentification.iD === companyCode;
}

export const isReceiver = (invoice?: NSGInvoice, companyCode?: string): boolean => {
  return invoice?.accountingCustomerParty.party.partyIdentification.iD === companyCode;
}