import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { BusinessDocument, BusinessDocumentType } from '../../model/AppModels';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { JsonObject } from '../../model/OmaXTypes';
import ReactJson from 'react-json-view';

interface Props {
  busDoc?: JsonObject<BusinessDocument<any>>,
  handleClose: () => void
}

const BusinessDocumentDialog: React.FC<Props> = ({busDoc, handleClose }) => {
  const { t } = useTranslation();
  const [json, setJson] = useState<any>();

  useEffect(() => {
    setJson(() => undefined);
    if (busDoc?.jsonData.type == BusinessDocumentType.ERECEIPT) {
      setJson(() => busDoc.jsonData.jsonData);
    }
  }, [busDoc]);

  return (
    <Dialog open={!!busDoc} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        {!json &&
          <div style={{width: "100%", height: "100%"}}>
            <p>{t("common.actions.loading")}</p>
          </div>
        }
        {json &&
          <div style={{width: "100%", height: "100%"}}>
            <div style={{borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}>
              <ReactJson src={json} name={false} displayDataTypes={false}/>
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions sx={{display: "flex"}}>
        <Button variant="contained" onClick={handleClose}>{t("common.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BusinessDocumentDialog;