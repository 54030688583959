import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as OmaYritysApi from "../../../api/OmaYritysApi";
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppStateContext } from '../../../state/AppStateContext';
import { Report } from '../../../model/AppModels';

interface Props {
  report?: Report,
  handleClose: () => void
}

const SendReportDialog: React.FC<Props> = ({report, handleClose}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation(["translation"]);
  const [sending, setSending] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>(undefined);

  const submit = async () => {
    setSending(() => true);
    setNotification(() => undefined);
    if (report) {
      try {
        const res = await OmaYritysApi.SendReport(report.id);
        if (res.data) {
          appContext.getReportsAsync();
        }
        setSending(() => false);
        setNotification(() => "success");
      }
      catch(err) {
        console.error("Error while sending a report", err);
        setSending(() => false);
        setNotification(() => "error");
      }
    }
  }

  useEffect(() => {
    setSending(() => false);
    setNotification(() => undefined);
  }, [report]);

  return (
    <Dialog open={!!report} onClose={handleClose} fullWidth>
      <DialogTitle>
        {t("app.components.SendReportDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{marginBottom: tokens.spacing.s}}>{t("app.components.SendReportDialog.info")}</Typography>
        { notification &&
          <Alert severity={notification === "success" ? "success" : "error"}>
            <AlertTitle>{t(`app.components.SendReportDialog.${notification}Title`)}</AlertTitle>
          </Alert>
        }
        { sending && 
          <Alert severity="info" icon={false}>
            <AlertTitle sx={{display: "flex", alignItems: "center"}}>
              <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
              {t(`app.components.SendReportDialog.sendingReport`)}
            </AlertTitle>
          </Alert>
        }
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={sending}
        >
          { notification ? t("common.actions.close") : t("common.actions.cancel") }
        </Button>
        <Button
          variant="contained"
          onClick={submit}
          disabled={sending || notification === "success"}
        >
          {t(`common.actions.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendReportDialog;