/**
 * PEPPOL BIS Pre-Award Catalogue 1.0 Data Models
 */

import * as Yup from 'yup';
import { 
  AmountType,
  BinaryObjectType,
  CodeType,
  DateType,
  IdentifierType,
  IndicatorType,
  MeasureType,
  NameType,
  NumericType,
  PercentType,
  QuantityType,
  RateType,
  TextType,
  TimeType,
  UnqualifiedDataTypes
} from '../UBL/UnqualifiedDataTypes';

/* tslint:disable:no-unused-variable */
let primitives = [
  "string",
  "boolean",
  "double",
  "integer",
  "long",
  "float",
  "number",
  "any"
];

export class ObjectSerializer {

  public static findCorrectType(data: any, expectedType: string) {
    let typesMap: { [index: string]: any } = {...PreAwardCatalogueTypes, ...UnqualifiedDataTypes};
    if (data === undefined) {
      return expectedType;
    } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
      return expectedType;
    } else if (expectedType === "Date") {
      return expectedType;
    } else {
      if (enumsMap[expectedType]) {
        return expectedType;
      }

      if (!typesMap[expectedType]) {
        return expectedType; // w/e we don't know the type
      }

      // Check the discriminator
      let discriminatorProperty = typesMap[expectedType].discriminator;
      if (discriminatorProperty == null) {
        return expectedType; // the type does not have a discriminator. use it.
      } else {
        if (data[discriminatorProperty]) {
          return data[discriminatorProperty]; // use the type given in the discriminator
        } else {
          return expectedType; // discriminator was not present (or an empty string)
        }
      }
    }
  }

  public static serialize(data: any, type: string) {
    let typesMap: { [index: string]: any } = {...PreAwardCatalogueTypes, ...UnqualifiedDataTypes};
    if (data === undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index in data) {
        let date = data[index];
        transformedData.push(ObjectSerializer.serialize(date, subType));
      }
      return transformedData;
    } else if (type === "Date") {
      return data.toString();
    } else {
      if (enumsMap[type]) {
        return data;
      }
      if (!typesMap[type]) { // in case we dont know the type
        return data;
      }

      // get the map for the correct type.
      let attributeTypes = typesMap[type].getAttributeTypeMap();
      let instance: { [index: string]: any } = {};
      for (let index in attributeTypes) {
        let attributeType = attributeTypes[index];
        instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type);
      }
      return instance;
    }
  }

  public static deserialize(data: any, type: string) {
    let typesMap: { [index: string]: any } = {...PreAwardCatalogueTypes, ...UnqualifiedDataTypes};
    // console.log(`ObjectSerializer.deserialize type ${type}`, data);
    // polymorphism may change the actual type.
    type = ObjectSerializer.findCorrectType(data, type);
    // console.log(`  ObjectSerializer.deserialize correct type ${type}`);
    if (data === undefined) {
      // console.log("  ObjectSerializer.deserialize data is undefined");
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      // console.log("  ObjectSerializer.deserialize data is primitive");
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      // console.log("  ObjectSerializer.deserialize array");
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index in data) {
        let date = data[index];
        transformedData.push(ObjectSerializer.deserialize(date, subType));
      }
      return transformedData;
    } else if (type === "Date") {
      // console.log("  ObjectSerializer.deserialize date");
      return new Date(data);
    } else {
      if (enumsMap[type]) {// is Enum
        // console.log("  ObjectSerializer.deserialize enum");
        return data;
      }

      if (!typesMap[type]) { // dont know the type
        // console.log("  ObjectSerializer.deserialize unknown");
        return data;
      }
      // console.log("  ObjectSerializer.deserialize known type");
      let instance = new typesMap[type]();
      let attributeTypes = typesMap[type].getAttributeTypeMap();
      for (let index in attributeTypes) {
        let attributeType = attributeTypes[index];
        // console.log(`ObjectSerializer.deserialize attribute ${attributeType.name}`);
        instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type);
      }
      // console.log("ObjectSerializer.deserialize instance", instance);
      return instance;
    }
  }
}

export class AllowanceCharge {
  'chargeIndicator': IndicatorType;
  'allowanceChargeReason': TextType;
  'amount': AmountType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "chargeIndicator",
      "baseName": "chargeIndicator",
      "type": "IndicatorType"
    },
    {
      "name": "allowanceChargeReason",
      "baseName": "allowanceChargeReason",
      "type": "TextType"
    },
    {
      "name": "amount",
      "baseName": "amount",
      "type": "AmountType"
    }];

  static getAttributeTypeMap() {
    return AllowanceCharge.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<AllowanceCharge> = Yup.object({
    chargeIndicator: IndicatorType.schema,
    allowanceChargeReason: TextType.schema,
    amount: AmountType.schema
  });
}

export class CommodityClassification {
  'itemClassificationCode': CodeType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "itemClassificationCode",
      "baseName": "itemClassificationCode",
      "type": "CodeType"
    }];

  static getAttributeTypeMap() {
    return CommodityClassification.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<CommodityClassification> = Yup.object({
    itemClassificationCode: CodeType.schema
  });
}

export class Contact {
  'name'?: NameType;
  'telephone'?: TextType;
  'telefax'?: TextType;
  'electronicMail'?: TextType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "NameType"
    },
    {
      "name": "telephone",
      "baseName": "telephone",
      "type": "TextType"
    },
    {
      "name": "telefax",
      "baseName": "telefax",
      "type": "TextType"
    },
    {
      "name": "electronicMail",
      "baseName": "electronicMail",
      "type": "TextType"
    }];

  static getAttributeTypeMap() {
    return Contact.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Contact> = Yup.object({
    name: NameType.schema,
    telephone: TextType.schema,
    telefax: TextType.schema,
    electronicMail: TextType.schema
  });
}

export class Country {
  'identificationCode': CodeType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "identificationCode",
      "baseName": "identificationCode",
      "type": "CodeType"
    }];

  static getAttributeTypeMap() {
    return Country.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Country> = Yup.object({
    identificationCode: CodeType.schema
  });
}

export class DeliveryUnit {
  'batchQuantity': QuantityType;
  'consumerUnitQuantity'?: QuantityType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "batchQuantity",
      "baseName": "batchQuantity",
      "type": "QuantityType"
    },
    {
      "name": "consumerUnitQuantity",
      "baseName": "consumerUnitQuantity",
      "type": "QuantityType"
    }];

  static getAttributeTypeMap() {
    return DeliveryUnit.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<DeliveryUnit> = Yup.object({
    batchQuantity: QuantityType.schema,
    consumerUnitQuantity: QuantityType.schema
  });
}

export class Dimension {
  'attributeID': IdentifierType;
  'measure'?: MeasureType;
  'description'?: Array<TextType>;
  'minimumMeasure'?: MeasureType;
  'maximumMeasure'?: MeasureType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "attributeID",
      "baseName": "attributeID",
      "type": "IdentifierType"
    },
    {
      "name": "measure",
      "baseName": "measure",
      "type": "MeasureType"
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "Array<TextType>"
    },
    {
      "name": "minimumMeasure",
      "baseName": "minimumMeasure",
      "type": "MeasureType"
    },
    {
      "name": "maximumMeasure",
      "baseName": "maximumMeasure",
      "type": "MeasureType"
    }];

  static getAttributeTypeMap() {
    return Dimension.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Dimension> = Yup.object({
    attributeID: IdentifierType.schema,
    measure: MeasureType.schema,
    description: Yup.array().of(TextType.schema),
    minimumMeasure: MeasureType.schema,
    maximumMeasure: MeasureType.schema
  });
}

export class DocumentReference {
  'iD': IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return DocumentReference.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<DocumentReference> = Yup.object({
    iD: IdentifierType.schema
  });
}

export class ExternalReference {
  'URI'?: IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "URI",
      "baseName": "URI",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return ExternalReference.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ExternalReference> = Yup.object({
    URI: IdentifierType.schema
  });
}

export class HazardousItem {
  'iD'?: IdentifierType;
  'uNDGCode'?: CodeType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "uNDGCode",
      "baseName": "uNDGCode",
      "type": "CodeType"
    }];

  static getAttributeTypeMap() {
    return HazardousItem.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<HazardousItem> = Yup.object({
    iD: IdentifierType.schema,
    uNDGCode: CodeType.schema
  });
}

export class ItemComparison {
  'priceAmount'?: AmountType;
  'quantity': QuantityType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "priceAmount",
      "baseName": "priceAmount",
      "type": "AmountType"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "QuantityType"
    }];

  static getAttributeTypeMap() {
    return ItemComparison.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ItemComparison> = Yup.object({
    priceAmount: AmountType.schema,
    quantity: QuantityType.schema
  });
}

export class ItemIdentification {
  'iD': IdentifierType;
  'extendedID'?: IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "extendedID",
      "baseName": "extendedID",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return ItemIdentification.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ItemIdentification> = Yup.object({
    iD: IdentifierType.schema,
    extendedID: IdentifierType.schema
  });
}

export class LineReference {
  'lineID': IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "lineID",
      "baseName": "lineID",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return LineReference.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<LineReference> = Yup.object({
    lineID: IdentifierType.schema
  });
}

export class LotIdentification {
  'expiryDate': DateType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "expiryDate",
      "baseName": "expiryDate",
      "type": "DateType"
    }];

  static getAttributeTypeMap() {
    return LotIdentification.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<LotIdentification> = Yup.object({
    expiryDate: DateType.schema
  });
}

export class PartyIdentification {
  'iD': IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return PartyIdentification.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<PartyIdentification> = Yup.object({
    iD: IdentifierType.schema
  });
}

export class PartyName {
  'name': NameType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "NameType"
    }];

  static getAttributeTypeMap() {
    return PartyName.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<PartyName> = Yup.object({
    name: NameType.schema
  });
}

export class RelatedItem {
  'iD'?: IdentifierType;
  'quantity'?: QuantityType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "QuantityType"
    }];

  static getAttributeTypeMap() {
    return RelatedItem.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<RelatedItem> = Yup.object({
    iD: IdentifierType.schema,
    quantity: QuantityType.schema
  });
}

export class TaxScheme {
  'iD': IdentifierType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    }];

  static getAttributeTypeMap() {
    return TaxScheme.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<TaxScheme> = Yup.object({
    iD: IdentifierType.schema
  });
}

export class TaxCategory {
  'iD'?: CodeType;
  'percent'?: PercentType;
  'taxScheme': TaxScheme;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "CodeType"
    },
    {
      "name": "percent",
      "baseName": "percent",
      "type": "PercentType"
    },
    {
      "name": "taxScheme",
      "baseName": "taxScheme",
      "type": "TaxScheme"
    }];

  static getAttributeTypeMap() {
    return TaxCategory.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<TaxCategory> = Yup.object({
    iD: CodeType.schema,
    percent: PercentType.schema,
    taxScheme: TaxScheme.schema,
  });
}

export class TransactionConditions {
  'actionCode'?: CodeType;
  'description'?: Array<TextType>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "actionCode",
      "baseName": "actionCode",
      "type": "CodeType"
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "Array<TextType>"
    }];

  static getAttributeTypeMap() {
    return TransactionConditions.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<TransactionConditions> = Yup.object({
    actionCode: CodeType.schema,
    description: Yup.array().of(TextType.schema)
  });
}

export class ValidityPeriod {
  'startDate': DateType;
  'endDate': DateType;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "startDate",
      "baseName": "startDate",
      "type": "DateType"
    },
    {
      "name": "endDate",
      "baseName": "endDate",
      "type": "DateType"
    }];

  static getAttributeTypeMap() {
    return ValidityPeriod.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ValidityPeriod> = Yup.object({
    startDate: DateType.schema,
    endDate: DateType.schema
  });
}






export class Address {
  'streetName'?: NameType;
  'additionalStreetName'?: NameType;
  'cityName'?: NameType;
  'postalZone'?: TextType;
  'countrySubentity'?: TextType;
  'country'?: Country;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "streetName",
      "baseName": "StreetName",
      "type": "NameType"
    },
    {
      "name": "additionalStreetName",
      "baseName": "additionalStreetName",
      "type": "NameType"
    },
    {
      "name": "cityName",
      "baseName": "cityName",
      "type": "NameType"
    },
    {
      "name": "postalZone",
      "baseName": "postalZone",
      "type": "TextType"
    },
    {
      "name": "countrySubentity",
      "baseName": "countrySubentity",
      "type": "TextType"
    },
    {
      "name": "country",
      "baseName": "country",
      "type": "Country"
    }];

  static getAttributeTypeMap() {
    return Address.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Address> = Yup.object({
    streetName: NameType.schema,
    additionalStreetName: NameType.schema,
    cityName: NameType.schema,
    postalZone: TextType.schema,
    countrySubentity: TextType.schema,
    country: Country.schema
  });
}

export class Attachment {
  'embeddedDocumentBinaryObject'?: BinaryObjectType;
  'externalReference'?: ExternalReference;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "embeddedDocumentBinaryObject",
      "baseName": "embeddedDocumentBinaryObject",
      "type": "BinaryObjectType"
    },
    {
      "name": "externalReference",
      "baseName": "externalReference",
      "type": "ExternalReference"
    }];

  static getAttributeTypeMap() {
    return Attachment.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Attachment> = Yup.object({
    embeddedDocumentBinaryObject: BinaryObjectType.schema,
    externalReference: ExternalReference.schema
  });
}

export class AttachmentDocumentReference {
  'iD': IdentifierType;
  'documentTypeCode'?: CodeType;
  'documentType'?: TextType;
  'documentDescription'?: Array<TextType>;
  'attachment'?: Attachment;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "documentTypeCode",
      "baseName": "documentTypeCode",
      "type": "CodeType"
    },
    {
      "name": "documentType",
      "baseName": "documentType",
      "type": "TextType"
    },
    {
      "name": "documentDescription",
      "baseName": "documentDescription",
      "type": "Array<TextType>"
    },
    {
      "name": "attachment",
      "baseName": "attachment",
      "type": "Attachment"
    }];

  static getAttributeTypeMap() {
    return AttachmentDocumentReference.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<AttachmentDocumentReference> = Yup.object({
    iD: IdentifierType.schema,
    documentTypeCode: CodeType.schema,
    documentType: TextType.schema,
    documentDescription: Yup.array().of(TextType.schema),
    attachment: Attachment.schema,
  });
}

export class Party {
  'endpointID'?: IdentifierType;
  'partyIdentification'?: Array<PartyIdentification>;
  'partyName'?: Array<PartyName>;
  'postaladdress'?: Address;
  'contact'?: Contact;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "endpointID",
      "baseName": "endpointID",
      "type": "IdentifierType"
    },
    {
      "name": "partyIdentification",
      "baseName": "partyIdentification",
      "type": "Array<PartyIdentification>"
    },
    {
      "name": "partyName",
      "baseName": "partyName",
      "type": "Array<PartyName>"
    },
    {
      "name": "postaladdress",
      "baseName": "postaladdress",
      "type": "Address"
    },
    {
      "name": "contact",
      "baseName": "contact",
      "type": "Contact"
    }];

  static getAttributeTypeMap() {
    return Party.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Party> = Yup.object({
    endpointID: IdentifierType.schema,
    partyIdentification: Yup.array().of(PartyIdentification.schema),
    partyName: Yup.array().of(PartyName.schema),
    postaladdress: Address.schema,
    contact: Contact.schema,
  });
}

export class ContractorParty {
  'party': Party;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "party",
      "baseName": "party",
      "type": "Party"
    }];

  static getAttributeTypeMap() {
    return ContractorParty.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ContractorParty> = Yup.object({
    party: Party.schema
  });
}

export class SupplierParty {
  'party': Party;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "party",
      "baseName": "party",
      "type": "Party"
    }];

  static getAttributeTypeMap() {
    return SupplierParty.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<SupplierParty> = Yup.object({
    party: Party.schema
  });
}

export class Price {
  'priceAmount': AmountType;
  'priceTypeCode'?: CodeType;
  'orderableUnitFactorRate'?: RateType;
  'validityPeriod'?: Array<ValidityPeriod>;
  'allowanceCharge'?: Array<AllowanceCharge>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "priceAmount",
      "baseName": "priceAmount",
      "type": "AmountType"
    },
    {
      "name": "priceTypeCode",
      "baseName": "priceTypeCode",
      "type": "CodeType"
    },
    {
      "name": "orderableUnitFactorRate",
      "baseName": "orderableUnitFactorRate",
      "type": "RateType"
    },
    {
      "name": "validityPeriod",
      "baseName": "validityPeriod",
      "type": "Array<ValidityPeriod>"
    },
    {
      "name": "allowanceCharge",
      "baseName": "allowanceCharge",
      "type": "Array<AllowanceCharge>"
    }];

  static getAttributeTypeMap() {
    return Price.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Price> = Yup.object({
    priceAmount: AmountType.schema,
    priceTypeCode: CodeType.schema,
    orderableUnitFactorRate: RateType.schema,
    validityPeriod: Yup.array().of(ValidityPeriod.schema),
    allowanceCharge: Yup.array().of(AllowanceCharge.schema),
  });
}

export class Certificate {
  'iD': IdentifierType;
  'certificateTypeCode': CodeType;
  'certificateType': TextType;
  'remarks'?: Array<TextType>;
  'issuerParty'?: Party;
  'documentReference'?: Array<DocumentReference>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "certificateTypeCode",
      "baseName": "certificateTypeCode",
      "type": "CodeType"
    },
    {
      "name": "certificateType",
      "baseName": "certificateType",
      "type": "TextType"
    },
    {
      "name": "remarks",
      "baseName": "remarks",
      "type": "Array<TextType>"
    },
    {
      "name": "issuerParty",
      "baseName": "issuerParty",
      "type": "Party"
    },
    {
      "name": "documentReference",
      "baseName": "documentReference",
      "type": "Array<DocumentReference>"
    }];

  static getAttributeTypeMap() {
    return Certificate.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Certificate> = Yup.object({
    iD: IdentifierType.schema,
    certificateTypeCode: CodeType.schema,
    certificateType: TextType.schema,
    remarks: Yup.array().of(TextType.schema),
    issuerParty: Party.schema,
    documentReference: Yup.array().of(DocumentReference.schema)
  });
}

export class Contract {
  'iD'?: IdentifierType;
  'issueDate'?: DateType;
  'contractTypeCode': CodeType;
  'contractDocumentReference': DocumentReference;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "issueDate",
      "baseName": "issueDate",
      "type": "DateType"
    },
    {
      "name": "contractTypeCode",
      "baseName": "contractTypeCode",
      "type": "CodeType"
    },
    {
      "name": "contractDocumentReference",
      "baseName": "contractDocumentReference",
      "type": "DocumentReference"
    }];

  static getAttributeTypeMap() {
    return Contract.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Contract> = Yup.object({
    iD: IdentifierType.schema,
    issueDate: DateType.schema,
    contractTypeCode: CodeType.schema,
    contractDocumentReference: DocumentReference.schema
  });
}

export class ItemProperty {
  'iD'?: IdentifierType;
  'name': NameType;
  'nameCode'?: CodeType;
  'value'?: TextType;
  'valueQualifier'?: TextType;
  'rangeDimension'?: Dimension;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "NameType"
    },
    {
      "name": "nameCode",
      "baseName": "nameCode",
      "type": "CodeType"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "TextType"
    },
    {
      "name": "valueQualifier",
      "baseName": "valueQualifier",
      "type": "TextType"
    },
    {
      "name": "rangeDimension",
      "baseName": "rangeDimension",
      "type": "Dimension"
    }];

  static getAttributeTypeMap() {
    return ItemProperty.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ItemProperty> = Yup.object({
    iD: IdentifierType.schema,
    name: NameType.schema,
    nameCode: CodeType.schema,
    value: TextType.schema,
    valueQualifier: TextType.schema,
    rangeDimension: Dimension.schema
  });
}

export class ItemInstance {
  'bestBeforeDate'?: DateType;
  'additionalItemProperty'?: Array<ItemProperty>;
  'lotIdentification'?: LotIdentification;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "bestBeforeDate",
      "baseName": "bestBeforeDate",
      "type": "DateType"
    },
    {
      "name": "additionalItemProperty",
      "baseName": "additionalItemProperty",
      "type": "Array<ItemProperty>"
    },
    {
      "name": "lotIdentification",
      "baseName": "lotIdentification",
      "type": "LotIdentification"
    }];

  static getAttributeTypeMap() {
    return ItemInstance.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ItemInstance> = Yup.object({
    bestBeforeDate: DateType.schema,
    additionalItemProperty: Yup.array().of(ItemProperty.schema),
    lotIdentification: LotIdentification.schema
  });
}

export class ItemLocationQuantity {
  'applicableTerritoryAddress'?: Array<Address>;
  'price'?: Price;
  'deliveryUnit'?: Array<DeliveryUnit>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "applicableTerritoryAddress",
      "baseName": "applicableTerritoryAddress",
      "type": "Array<Address>"
    },
    {
      "name": "price",
      "baseName": "price",
      "type": "Price"
    },
    {
      "name": "deliveryUnit",
      "baseName": "deliveryUnit",
      "type": "Array<DeliveryUnit>"
    }];

  static getAttributeTypeMap() {
    return ItemLocationQuantity.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ItemLocationQuantity> = Yup.object({
    applicableTerritoryAddress: Yup.array().of(Address.schema),
    price: Price.schema,
    deliveryUnit: Yup.array().of(DeliveryUnit.schema)
  });
}

export class Item {
  'description'?: Array<TextType>;
  'packQuantity'?: QuantityType;
  'packSizeNumeric'?: NumericType;
  'name': NameType;
  'keyword'?: Array<TextType>;
  'brandName'?: Array<NameType>;
  'sellersItemIdentification'?: ItemIdentification;
  'manufacturersItemIdentification'?: Array<ItemIdentification>;
  'standardItemIdentification'?: ItemIdentification;
  'itemSpecificationDocumentReference'?: Array<AttachmentDocumentReference>;
  'originCountry'?: Country;
  'commodityClassification'?: Array<CommodityClassification>;
  'transactionConditions'?: Array<TransactionConditions>;
  'hazardousItem'?: Array<HazardousItem>;
  'classifiedTaxCategory'?: Array<TaxCategory>;
  'additionalItemProperty'?: Array<ItemProperty>;
  'manufacturerParty'?: Array<Party>;
  'itemInstance'?: Array<ItemInstance>;
  'certificate'?: Array<Certificate>;
  'dimension'?: Array<Dimension>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "description",
      "baseName": "description",
      "type": "Array<TextType>"
    },
    {
      "name": "packQuantity",
      "baseName": "packQuantity",
      "type": "QuantityType"
    },
    {
      "name": "packSizeNumeric",
      "baseName": "packSizeNumeric",
      "type": "NumericType"
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "NameType"
    },
    {
      "name": "keyword",
      "baseName": "keyword",
      "type": "Array<TextType>"
    },
    {
      "name": "brandName",
      "baseName": "brandName",
      "type": "Array<NameType>"
    },
    {
      "name": "sellersItemIdentification",
      "baseName": "sellersItemIdentification",
      "type": "ItemIdentification"
    },
    {
      "name": "manufacturersItemIdentification",
      "baseName": "manufacturersItemIdentification",
      "type": "Array<ItemIdentification>"
    },
    {
      "name": "standardItemIdentification",
      "baseName": "standardItemIdentification",
      "type": "ItemIdentification"
    },
    {
      "name": "itemSpecificationDocumentReference",
      "baseName": "itemSpecificationDocumentReference",
      "type": "Array<AttachmentDocumentReference>"
    },
    {
      "name": "originCountry",
      "baseName": "originCountry",
      "type": "Country"
    },
    {
      "name": "commodityClassification",
      "baseName": "commodityClassification",
      "type": "Array<CommodityClassification>"
    },
    {
      "name": "transactionConditions",
      "baseName": "transactionConditions",
      "type": "Array<TransactionConditions>"
    },
    {
      "name": "hazardousItem",
      "baseName": "hazardousItem",
      "type": "Array<HazardousItem>"
    },
    {
      "name": "classifiedTaxCategory",
      "baseName": "classifiedTaxCategory",
      "type": "Array<TaxCategory>"
    },
    {
      "name": "additionalItemProperty",
      "baseName": "additionalItemProperty",
      "type": "Array<ItemProperty>"
    },
    {
      "name": "manufacturerParty",
      "baseName": "manufacturerParty",
      "type": "Array<Party>"
    },
    {
      "name": "itemInstance",
      "baseName": "itemInstance",
      "type": "Array<ItemInstance>"
    },
    {
      "name": "certificate",
      "baseName": "certificate",
      "type": "Array<Certificate>"
    },
    {
      "name": "dimension",
      "baseName": "dimension",
      "type": "Array<Dimension>"
    }];

  static getAttributeTypeMap() {
    return Item.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Item> = Yup.object({
    description: Yup.array().of(TextType.schema),
    packQuantity: QuantityType.schema,
    packSizeNumeric: NumericType.schema,
    name: NameType.schema,
    keyword: Yup.array().of(TextType.schema),
    brandName: Yup.array().of(NameType.schema),
    sellersItemIdentification: ItemIdentification.schema,
    manufacturersItemIdentification: Yup.array().of(ItemIdentification.schema),
    standardItemIdentification: ItemIdentification.schema,
    itemSpecificationDocumentReference: Yup.array().of(AttachmentDocumentReference.schema),
    originCountry: Country.schema,
    commodityClassification: Yup.array().of(CommodityClassification.schema),
    transactionConditions: Yup.array().of(TransactionConditions.schema),
    hazardousItem: Yup.array().of(HazardousItem.schema),
    classifiedTaxCategory: Yup.array().of(TaxCategory.schema),
    additionalItemProperty: Yup.array().of(ItemProperty.schema),
    manufacturerParty: Yup.array().of(Party.schema),
    itemInstance: Yup.array().of(ItemInstance.schema),
    certificate: Yup.array().of(Certificate.schema),
    dimension: Yup.array().of(Dimension.schema),
  });
}

export class CatalogueLine {
  'iD': IdentifierType;
  'contractSubdivision'?: TextType;
  'orderableIndicator'?: IndicatorType;
  'orderableUnit'?: TextType;
  'contentUnitQuantity'?: QuantityType;
  'orderQuantityIncrementNumeric'?: NumericType;
  'minimumOrderQuantity'?: QuantityType;
  'maximumOrderQuantity'?: QuantityType;
  'warrantyInformation'?: Array<TextType>;
  'packLevelCode'?: CodeType;
  'lineValidityPeriod'?: ValidityPeriod;
  'itemComparison'?: Array<ItemComparison>;
  'componentRelatedItem'?: Array<RelatedItem>;
  'requiredRelatedItem'?: Array<RelatedItem>;
  'complementaryRelatedItem'?: Array<RelatedItem>;
  'replacedRelatedItem'?: Array<RelatedItem>;
  'requiredItemLocationQuantity'?: Array<ItemLocationQuantity>;
  'item'?: Item;
  'itemId'?: string;
  'callForTendersLineReference'?: LineReference;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "contractSubdivision",
      "baseName": "contractSubdivision",
      "type": "TextType"
    },
    {
      "name": "orderableIndicator",
      "baseName": "orderableIndicator",
      "type": "IndicatorType"
    },
    {
      "name": "orderableUnit",
      "baseName": "orderableUnit",
      "type": "TextType"
    },
    {
      "name": "contentUnitQuantity",
      "baseName": "contentUnitQuantity",
      "type": "QuantityType"
    },
    {
      "name": "orderQuantityIncrementNumeric",
      "baseName": "orderQuantityIncrementNumeric",
      "type": "NumericType"
    },
    {
      "name": "minimumOrderQuantity",
      "baseName": "minimumOrderQuantity",
      "type": "QuantityType"
    },
    {
      "name": "maximumOrderQuantity",
      "baseName": "maximumOrderQuantity",
      "type": "QuantityType"
    },
    {
      "name": "warrantyInformation",
      "baseName": "warrantyInformation",
      "type": "Array<TextType>"
    },
    {
      "name": "packLevelCode",
      "baseName": "packLevelCode",
      "type": "CodeType"
    },
    {
      "name": "lineValidityPeriod",
      "baseName": "lineValidityPeriod",
      "type": "ValidityPeriod"
    },
    {
      "name": "itemComparison",
      "baseName": "itemComparison",
      "type": "Array<ItemComparison>"
    },
    {
      "name": "componentRelatedItem",
      "baseName": "componentRelatedItem",
      "type": "Array<RelatedItem>"
    },
    {
      "name": "requiredRelatedItem",
      "baseName": "requiredRelatedItem",
      "type": "Array<RelatedItem>"
    },
    {
      "name": "complementaryRelatedItem",
      "baseName": "complementaryRelatedItem",
      "type": "Array<RelatedItem>"
    },
    {
      "name": "replacedRelatedItem",
      "baseName": "replacedRelatedItem",
      "type": "Array<RelatedItem>"
    },
    {
      "name": "requiredItemLocationQuantity",
      "baseName": "requiredItemLocationQuantity",
      "type": "Array<ItemLocationQuantity>"
    },
    {
      "name": "item",
      "baseName": "item",
      "type": "Item"
    },
    {
      "name": "callForTendersLineReference",
      "baseName": "callForTendersLineReference",
      "type": "LineReference"
    }];

  static getAttributeTypeMap() {
    return CatalogueLine.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<CatalogueLine> = Yup.object({
    iD: IdentifierType.schema,
    contractSubdivision: TextType.schema,
    orderableIndicator: IndicatorType.schema,
    orderableUnit: TextType.schema,
    contentUnitQuantity: QuantityType.schema,
    orderQuantityIncrementNumeric: NumericType.schema,
    minimumOrderQuantity: QuantityType.schema,
    maximumOrderQuantity: QuantityType.schema,
    warrantyInformation: Yup.array().of(TextType.schema),
    packLevelCode: CodeType.schema,
    lineValidityPeriod: ValidityPeriod.schema,
    itemComparison: Yup.array().of(ItemComparison.schema),
    componentRelatedItem: Yup.array().of(RelatedItem.schema),
    requiredRelatedItem: Yup.array().of(RelatedItem.schema),
    complementaryRelatedItem: Yup.array().of(RelatedItem.schema),
    replacedRelatedItem: Yup.array().of(RelatedItem.schema),
    requiredItemLocationQuantity: Yup.array().of(ItemLocationQuantity.schema),
    item: Item.schema,
    itemId: Yup.string(),
    callForTendersLineReference: LineReference.schema
  });
}

export class Catalogue {
  'uBLVersionID': IdentifierType;
  'customizationID': IdentifierType;
  'profileID': IdentifierType;
  'iD': IdentifierType;
  'name'?: NameType;
  'issueDate': DateType;
  'issueTime'?: TimeType;
  'note'?: Array<TextType>;
  'versionID'?: IdentifierType;
  'validityPeriod'?: Array<ValidityPeriod>;
  'referencedContract'?: Array<Contract>;
  'providerParty'?: Party;
  'receiverParty'?: Party;
  'sellerSupplierParty'?: SupplierParty;
  'contractorCustomerParty'?: ContractorParty;
  'catalogueLine': Array<CatalogueLine>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "uBLVersionID",
      "baseName": "uBLVersionID",
      "type": "IdentifierType"
    },
    {
      "name": "customizationID",
      "baseName": "customizationID",
      "type": "IdentifierType"
    },
    {
      "name": "profileID",
      "baseName": "profileID",
      "type": "IdentifierType"
    },
    {
      "name": "iD",
      "baseName": "iD",
      "type": "IdentifierType"
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "NameType"
    },
    {
      "name": "issueDate",
      "baseName": "issueDate",
      "type": "DateType"
    },
    {
      "name": "issueTime",
      "baseName": "issueTime",
      "type": "TimeType"
    },
    {
      "name": "note",
      "baseName": "note",
      "type": "Array<TextType>"
    },
    {
      "name": "versionID",
      "baseName": "versionID",
      "type": "IdentifierType"
    },
    {
      "name": "validityPeriod",
      "baseName": "validityPeriod",
      "type": "Array<ValidityPeriod>"
    },
    {
      "name": "referencedContract",
      "baseName": "referencedContract",
      "type": "Array<Contract>"
    },
    {
      "name": "providerParty",
      "baseName": "ProviderParty",
      "type": "Party"
    },
    {
      "name": "receiverParty",
      "baseName": "receiverParty",
      "type": "Party"
    },
    {
      "name": "sellerSupplierParty",
      "baseName": "sellerSupplierParty",
      "type": "SupplierParty"
    },
    {
      "name": "contractorCustomerParty",
      "baseName": "contractorCustomerParty",
      "type": "ContractorParty"
    },
    {
      "name": "catalogueLine",
      "baseName": "catalogueLine",
      "type": "Array<CatalogueLine>"
    }];

  static getAttributeTypeMap() {
    return Catalogue.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<Catalogue> = Yup.object({
    uBLVersionID: IdentifierType.schema,
    customizationID: IdentifierType.schema,
    profileID: IdentifierType.schema,
    iD: IdentifierType.schema,
    name: NameType.schema,
    issueDate: DateType.schema,
    issueTime: TimeType.schema,
    note: Yup.array().of(TextType.schema),
    versionID: IdentifierType.schema,
    validityPeriod: Yup.array().of(ValidityPeriod.schema),
    referencedContract: Yup.array().of(Contract.schema),
    providerParty: Party.schema,
    receiverParty: Party.schema,
    sellerSupplierParty: SupplierParty.schema,
    contractorCustomerParty: ContractorParty.schema,
    catalogueLine: Yup.array().of(CatalogueLine.schema).required()
  });
}

let enumsMap: { [index: string]: any } = {
}

let PreAwardCatalogueTypes: { [index: string]: any } = {
  "Address": Address,
  "AllowanceCharge": AllowanceCharge,
  "Attachment": Attachment,
  "AttachmentDocumentReference": AttachmentDocumentReference,
  "Catalogue": Catalogue,
  "CatalogueLine": CatalogueLine,
  "Certificate": Certificate,
  "CommodityClassification": CommodityClassification,
  "Contact": Contact,
  "Contract": Contract,
  "ContractorParty": ContractorParty,
  "Country": Country,
  "DeliveryUnit": DeliveryUnit,
  "Dimension": Dimension,
  "DocumentReference": DocumentReference,
  "ExternalReference": ExternalReference,
  "HazardousItem": HazardousItem,
  "Item": Item,
  "ItemComparison": ItemComparison,
  "ItemIdentification": ItemIdentification,
  "ItemInstance": ItemInstance,
  "ItemLocationQuantity": ItemLocationQuantity,
  "ItemProperty": ItemProperty,
  "LineReference": LineReference,
  "LotIdentification": LotIdentification,
  "Party": Party,
  "PartyIdentification": PartyIdentification,
  "PartyName": PartyName,
  "Price": Price,
  "RelatedItem": RelatedItem,
  "SupplierParty": SupplierParty,
  "TaxCategory": TaxCategory,
  "TaxScheme": TaxScheme,
  "TransactionConditions": TransactionConditions,
  "ValidityPeriod": ValidityPeriod,
}

export const GetCatalogueTemplate = (): Catalogue => {
  const date = new Date();
  const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const currentDatePlusYear = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());

  return {
    uBLVersionID: {value: "2.1"},
    customizationID: {value: "urn:www.cenbii.eu:transaction:biitrdm068:ver3.0:extended:urn:www.peppol.eu:bis:peppol35a:ver1.0"},
    profileID: {value: "urn:www.cenbii.eu:profile:bii35:ver3.0"},
    iD: {value: ""},
    name: {value: ""},
    issueDate: {value: currentDate},
    validityPeriod: [
      {
        startDate: {value: currentDate},
        endDate: {value: new Date(currentDatePlusYear)}
      }
    ],
    catalogueLine: []
  };
}

export const GetCatalogueLineTemplate = (lineId: string): CatalogueLine => {
  return {
    iD: {value: lineId}
  }
}

export const GetCatalogueItemTemplate = (): Item => {
  return {
    name: {value: ""},
    classifiedTaxCategory: [
      {
        iD: { value: "", listID: "UNCL5305"},
        taxScheme: {
          iD: {value: "VAT"}
        }
      }
    ]
  }
}