import { EpcisEvent } from './EpcisModels';
import { FinvoiceDocument, StandardBusinessDocument } from './OmaXTypes';
import { Item } from './Peppol/PreAwardCatalogue';
import { QuantityType } from './UBL/UnqualifiedDataTypes';

export interface Invoice {
  type: InvoiceType,
  document: StandardBusinessDocument|FinvoiceDocument
}

export enum InvoiceType {
  PEPPOL = "peppol",
  FINVOICE = "finvoice"
}

export interface CreateInvoiceRequest {
  type: InvoiceType,
  customerCode: string,
  invoiceLines: CreateInvoiceRequestInvoiceLine[]
}

export interface CreateInvoiceRequestInvoiceLine {
  id: string,
  quantity: number|string,
  unitCode: string,
  price: number|string,
  itemID: string
}

export class Product extends Item {
  'createdOn'?: string;
  'updatedOn'?: string;
  'quantity': QuantityType;
  'epcisEvents'?: Array<EpcisEvent>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "createdOn",
      "baseName": "createdOn",
      "type": "string"
    },
    {
      "name": "updatedOn",
      "baseName": "updatedOn",
      "type": "string"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "QuantityType"
    },
    {
      "name": "epcisEvents",
      "baseName": "epcisEvents",
      "type": "Array<EpcisEvent>"
    }
  ];

  static getAttributeTypeMap() {
    return [...Item.attributeTypeMap, ...Product.attributeTypeMap];
  }
}

export const GetProductTemplate = (): Product => {
  return {
    name: {value: ""},
    classifiedTaxCategory: [
      {
        iD: { value: "S", listID: "UNCL5305"},
        percent: { value: 24 },
        taxScheme: {
          iD: {value: "VAT"}
        }
      }
    ],
    quantity: {value: 1}
  }
}

export interface PeppolInvoiceAttachment {
  id?: string|null,
  typeCode?: string|null,
  description?: string|null,
  mimeCode?: string|null,
  filename?: string|null,
  content?: any
}

export interface GeneralLedgerEntry {
  id: string,
  entityType: string,
  entityID: string,
  sourceType: string,
  sourceID: string,
  entryNumber: string,
  postingDate: string,
  description: string,
  accountNumber: string,
  currency: string,
  balanceType: string,
  decimals: number,
  monetaryAmount: string,
  sessionAccountId?: string
}

export interface CreateGeneralLedgerEntryData {
  sourceLineID: string,
  description: string,
  accountNumber: string
}

export interface CreateGeneralLedgerEntriesWrapper {
  sourceType: string,
  sourceID: string
  entries: CreateGeneralLedgerEntryData[]
}

export enum BalanceType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT"
}

export interface ChartOfAccounts {
  salesAccounts: AccountingAccount[],
  purchaseAccounts: AccountingAccount[],
}

export interface AccountingAccount {
  title: string,
  code: string,
  description: string
}

export interface CreateReportRequest {
  reportType: ReportType|string,
  periodStart?: string,
  periodEnd?: string,
  description?: string
}

export enum ReportType {
  VAT = "VAT",
  JOURNAL = "JOURNAL",
  REPORT_BALANCES = "REPORT_BALANCES",
  VISUALIZE_INLINE_XBRL = "VISUALIZE_INLINE_XBRL"
}

export enum ReportStatus {
  CREATED = "created",
  SENDING = "sending",
  SENT = "sent"
}

export interface Report {
  id: string,
  reportType: ReportType,
  ownerID: string,
  createdOn: string,
  periodStart: string,
  periodEnd: string,
  description: string,
  status: ReportStatus,
  sessionAccountID?: string,
  xmlData: any,
  jsonData: any
}

export interface BusinessDocument<T> {
  id: string,
  type: BusinessDocumentType,
  ownerID: string,
  createdOn: string,
  accountingNumber?: string,
  sessionAccountID?: string,
  dataType: BusinessDocumentDataType,
  xmlData: T,
  jsonData: T,
  stringData: string
}

export enum BusinessDocumentType {
  ERECEIPT = "eReceipt"
}

export enum BusinessDocumentDataType {
  XMLData = "xml",
  JSONData = "json",
  StringData = "string"
}
