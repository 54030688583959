import * as Yup from 'yup';

export class ID {
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return ID.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ID> = Yup.object({
    value: Yup.string()
  });
}

export class ReadPoint {
  'id'?: ID;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "ID"
    },
  ]

  static getAttributeTypeMap() {
    return ReadPoint.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ReadPoint> = Yup.object({
    id: ID.schema
  });
}

export class BizLocation {
  'id'?: ID;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "ID"
    },
  ]

  static getAttributeTypeMap() {
    return BizLocation.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<BizLocation> = Yup.object({
    id: ID.schema
  });
}

export class TypeValue {
  'type'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return TypeValue.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<TypeValue> = Yup.object({
    type: Yup.string(),
    value: Yup.string()
  });
}

export class ILMD {
  'items'?: Array<any>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "items",
      "baseName": "items",
      "type": "Array<any>"
    }
  ]

  static getAttributeTypeMap() {
    return ILMD.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<ILMD> = Yup.object({
    items: Yup.array()
  });
}

export class EpcisEvent {
  readonly eventType: string;
  'eventTime'?: string;
  'recordTime'?: string;
  'eventTimeZoneOffset'?: string;
  'eventId'?: string;
  'action'?: string;
  'bizStep'?: string;
  'disposition'?: string;
  'readPoint'?: ReadPoint;
  'bizLocation'?: BizLocation;
  'bizTransactionList'?: Array<TypeValue>;
  'sourceList'?: Array<TypeValue>;
  'destinationList'?: Array<TypeValue>;
  'ilmd'?: ILMD;

  constructor(type?: string) {
    this.eventType = type ?? "EpcisEvent";
  }

  static discriminator: string | undefined = "eventType";

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "eventType",
      "baseName": "eventType",
      "type": "string"
    },
    {
      "name": "eventTime",
      "baseName": "eventTime",
      "type": "Date"
    },
    {
      "name": "recordTime",
      "baseName": "recordTime",
      "type": "Date"
    },
    {
      "name": "eventTimeZoneOffset",
      "baseName": "eventTimeZoneOffset",
      "type": "string"
    },
    {
      "name": "eventId",
      "baseName": "eventId",
      "type": "string"
    },
    {
      "name": "action",
      "baseName": "action",
      "type": "string"
    },
    {
      "name": "bizStep",
      "baseName": "bizStep",
      "type": "string"
    },
    {
      "name": "disposition",
      "baseName": "disposition",
      "type": "string"
    },
    {
      "name": "readPoint",
      "baseName": "readPoint",
      "type": "ReadPoint"
    },
    {
      "name": "bizLocation",
      "baseName": "bizLocation",
      "type": "BizLocation"
    },
    {
      "name": "bizTransactionList",
      "baseName": "bizTransactionList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "sourceList",
      "baseName": "sourceList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "destinationList",
      "baseName": "destinationList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "ilmd",
      "baseName": "ilmd",
      "type": "ILMD"
    }
  ]

  static getAttributeTypeMap() {
    return EpcisEvent.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<EpcisEvent> = Yup.object({
    eventType: Yup.string().required(),
    eventTime: Yup.string(),
    recordTime: Yup.string(),
    eventTimeZoneOffset: Yup.string(),
    eventId: Yup.string(),
    action: Yup.string(),
    bizStep: Yup.string(),
    disposition: Yup.string(),
    readPoint: ReadPoint.schema,
    bizLocation: BizLocation.schema,
    bizTransactionList: Yup.array().of(TypeValue.schema),
    sourceList: Yup.array(),
    destinationList: Yup.array(),
    ilmd: ILMD.schema,
  });
}

export class EPC {
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return EPC.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<EPC> = Yup.object({
    value: Yup.string()
  });
}

export class QuantityElement {
  'epcClass'?: string;
  'quantity'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "epcClass",
      "baseName": "epcClass",
      "type": "string"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "string"
    }
  ]

  static getAttributeTypeMap() {
    return QuantityElement.attributeTypeMap;
  }

  static schema: Yup.ObjectSchema<QuantityElement> = Yup.object({
    epcClass: Yup.string(),
    quantity: Yup.string()
  });
}

export class ObjectEvent extends EpcisEvent {
  'epcList'?: Array<EPC>;
  'quantityList'?: Array<QuantityElement>;

  constructor() {
    super("ObjectEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "epcList",
      "baseName": "epcList",
      "type": "Array<EPC>"
    },
    {
      "name": "quantityList",
      "baseName": "quantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...ObjectEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }

  static schema: Yup.ObjectSchema<ObjectEvent> = EpcisEvent.schema.shape({
    epcList: Yup.array().of(EPC.schema),
    quantityList: Yup.array().of(QuantityElement.schema)
  });
}

export class AggregationEvent extends EpcisEvent {
  'parentID'?: string;
  'childEPCs'?: Array<EPC>;
  'childQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("AggregationEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "childEPCs",
      "baseName": "childEPCs",
      "type": "Array<EPC>"
    },
    {
      "name": "childQuantityList",
      "baseName": "childQuantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...AggregationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }

  static schema: Yup.ObjectSchema<AggregationEvent> = EpcisEvent.schema.shape({
    parentID: Yup.string(),
    childEPCs: Yup.array().of(EPC.schema),
    childQuantityList: Yup.array().of(QuantityElement.schema)
  });
}

export class TransactionEvent extends EpcisEvent {
  'parentID'?: string;
  'epcList'?: Array<EPC>;
  'quantityList'?: Array<QuantityElement>;

  constructor() {
    super("TransactionEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "epcList",
      "baseName": "epcList",
      "type": "Array<EPC>"
    },
    {
      "name": "quantityList",
      "baseName": "quantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...TransactionEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }

  static schema: Yup.ObjectSchema<TransactionEvent> = EpcisEvent.schema.shape({
    parentID: Yup.string(),
    epcList: Yup.array().of(EPC.schema),
    quantityList: Yup.array().of(QuantityElement.schema)
  });
}

export class TransformationEvent extends EpcisEvent {
  'transformationId'?: string;
  'inputEPCList'?: Array<EPC>;
  'inputQuantityList'?: Array<QuantityElement>;
  'outputEPCList'?: Array<EPC>;
  'outputQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("TransformationEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "inputEPCList",
      "baseName": "inputEPCList",
      "type": "Array<EPC>"
    },
    {
      "name": "inputQuantityList",
      "baseName": "inputQuantityList",
      "type": "Array<QuantityElement>"
    },
    {
      "name": "outputEPCList",
      "baseName": "outputEPCList",
      "type": "Array<EPC>"
    },
    {
      "name": "outputQuantityList",
      "baseName": "outputQuantityList",
      "type": "Array<QuantityElement>"
    },
    {
      "name": "transformationId",
      "baseName": "transformationId",
      "type": "string"
    }
  ]

  static getAttributeTypeMap() {
    return [...TransformationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }

  static schema: Yup.ObjectSchema<TransformationEvent> = EpcisEvent.schema.shape({
    inputEPCList: Yup.array().of(EPC.schema),
    inputQuantityList: Yup.array().of(QuantityElement.schema),
    outputEPCList: Yup.array().of(EPC.schema),
    outputQuantityList: Yup.array().of(QuantityElement.schema),
    transformationId: Yup.string()
  });
}

export class AssociationEvent extends EpcisEvent {
  'parentID'?: string;
  'childEPCs'?: Array<EPC>;
  'childQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("AssociationEvent");
  }

  readonly eventType: string = "AssociationEvent";

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "childEPCs",
      "baseName": "childEPCs",
      "type": "Array<EPC>"
    },
    {
      "name": "childQuantityList",
      "baseName": "childQuantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...AssociationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }

  static schema: Yup.ObjectSchema<AssociationEvent> = EpcisEvent.schema.shape({
    parentID: Yup.string(),
    childEPCs: Yup.array().of(EPC.schema),
    childQuantityList: Yup.array().of(QuantityElement.schema)
  });
}

export const EpcisTypes: { [index: string]: any } = {
  "ID": ID,
  "ReadPoint": ReadPoint,
  "BizLocation": BizLocation,
  "TypeValue": TypeValue,
  "ILMD": ILMD,
  "EpcisEvent": EpcisEvent,
  "EPC": EPC,
  "QuantityElement": QuantityElement,
  "ObjectEvent": ObjectEvent,
  "AggregationEvent": AggregationEvent,
  "TransactionEvent": TransactionEvent,
  "TransformationEvent": TransformationEvent,
  "AssociationEvent": AssociationEvent
}

export const GetEpcisEventType = (type: string) => ({
  "EpcisEvent": EpcisEvent,
  "ObjectEvent": ObjectEvent,
  "AggregationEvent": AggregationEvent,
  "TransactionEvent": TransactionEvent,
  "TransformationEvent": TransformationEvent,
  "AssociationEvent": AssociationEvent
})[type];