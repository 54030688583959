/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../state/AppStateContext';
import { Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useNavigate } from 'react-router';
import ConfirmLogoutSessionDialog from '../modals/ConfirmLogoutSessionDialog';
import ConfirmDeleteSessionDialog from '../modals/ConfirmDeleteSessionDialog';
import { SessionRole } from '../../model/OmaXTypes';

const HeaderContent = styled.div`
  display: flex;
`

const CompanyInfo = styled.div`
  text-align: right;
  font-size: ${tokens.typography.bodyTextSmall};
  // border-right: 1px solid ${tokens.colors.brandBase};
  padding: 0 ${tokens.spacing.xxs};
  margin-left: ${tokens.spacing.xs};
`

const Logout = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-right: 1px solid ${tokens.colors.brandBase};
  padding: 0 ${tokens.spacing.xxs};
`

const UserMenu: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  const theme = useTheme();
  const wideScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [openConfirmLogoutSession, setOpenConfirmLogoutSession] = useState<boolean>(false);
  const [openConfirmDeleteSession, setOpenConfirmDeleteSession] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutSession = () => {
    setOpenConfirmLogoutSession(() => false);
    appContext.logout()
    .then(() => {
      navigate("/login");
    });
  }

  const deleteSession = () => {
    setOpenConfirmDeleteSession(() => false);
    appContext.deleteSession()
    .then(() => {
      navigate("/login");
    });
  }

  if (!appContext.company) {
    return <></>;
  }
  
  const GetMenuItems = (): JSX.Element[] => {
    return appContext.sessionAccount?.role === SessionRole.Admin ?
    (
      [
      <MenuItem key="menuitem1" disableGutters sx={{paddingY: 0}} onClick={handleClose}>
        <Button variant="text" onClick={() => logoutSession()} fullWidth>
          {t("app.header.logout")}
        </Button>
      </MenuItem>
      ]
    ) :
    (
      [
        <MenuItem key="menuitem1" disableGutters sx={{paddingY: 0}} onClick={handleClose}>
          <Button variant="text" onClick={() => setOpenConfirmLogoutSession(() => true)} fullWidth>
            {t("app.header.logoutSession")}
          </Button>
        </MenuItem>,
        <MenuItem key="menuitem2" disableGutters sx={{paddingY: 0}} onClick={handleClose}>
          <Button variant="text" onClick={() => setOpenConfirmDeleteSession(() => true)} fullWidth>
            {t("app.header.deleteSession")}
          </Button>
        </MenuItem>
      ]
    )
  }

  return (
    <HeaderContent>
      { wideScreen && 
        <CompanyInfo>
          <div>{appContext.company?.companyName}</div>
          <div>{appContext.company?.code}</div>
        </CompanyInfo>
      }
      <div>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: tokens.spacing.xs }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: tokens.colors.brandBase }}>
            <AccountCircleIcon/>
          </Avatar>
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        id={"user-menu"}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        { GetMenuItems() }
      </Menu>
      <ConfirmLogoutSessionDialog
        open={openConfirmLogoutSession}
        textToCopy={appContext.sessionAccount?.id}
        handleClose={() => setOpenConfirmLogoutSession(() => false)}
        confirmAction={() => logoutSession()}
        cancelAction={() => setOpenConfirmLogoutSession(() => false)}
      />
      <ConfirmDeleteSessionDialog
        open={openConfirmDeleteSession}
        handleClose={() => setOpenConfirmDeleteSession(() => false)}
        confirmAction={() => deleteSession()}
        cancelAction={() => setOpenConfirmDeleteSession(() => false)}
      />
    </HeaderContent>
  );
}

export default UserMenu;