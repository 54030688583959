/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Button, Grid, ListItemText, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import _ from 'lodash';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { DeleteCatalogue } from '../../../api/OmaYritysApi';
import Card from '../../common/Card';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { JsonObject } from '../../../model/OmaXTypes';
import { useCustomTranslations } from '../../../utils/useCustomTranslations';
import ConfirmDialog from '../../common/ConfirmDialog';
import { Product } from '../../../model/AppModels';
import { toDateTimeString } from '../../../utils/dateUtils';
import EpcisEventsDialog from './EpcisEventsDialog';
import CreateEpcisEventDialog from './CreateEpcisEventDialog';
import { hasClassifiedTaxCategory } from '../../../utils/peppolUtils';

const styles = {
  productCatalog: css({
    // verticalAlign: 'middle'
  }),
  catalogHeader: css({
    marginTop: tokens.spacing.xs,
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    textAlign: 'center',
    fontWeight: 'bold',
    '& > *': {
      borderRight: '1px solid black',
    }
  }),
  catalogCell: css({
    display: "flex",
    alignItems: 'center'
  }),
  catalogCellRight: css({
    display: "flex",
    alignItems: 'center',
    justifyContent: "right",
    textAlign: "right"
  })
}

const ProductsView: React.FC = () => {
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "preawardcatalogue"]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<Function>(() => () => setShowConfirmModal(false)); // override this

  useEffect(() => {
    if (appContext.company) {
      appContext.getCataloguesAsync();
      appContext.getCatalogueProductsAsync();
    }
  }, []);

  const confirmDeleteProductItem = (id: string) => {
    setConfirmAction(() => () => deleteProduct(id));
    setShowConfirmModal(true);
  }
  
  const deleteProduct = (id: string) => {
    console.log("deleteProduct with id", id);
    const productToDelete = appContext.catalogueProducts[id];
    if (productToDelete) {
      DeleteCatalogue(id)
      .then(res => {
        if (res.status == 200 && appContext.company?.id) {
          // Reload products to state after successful deletion of a product.
          appContext.getCatalogueProductsAsync();
        }
      })
      .catch(err => {
        console.error("DeleteMiniSuomiCatalogue err", err);
      })
      .finally(() => setShowConfirmModal(false));
    }
  }

  return (
    <>
      <Page
        breadcrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/products", label: "products", current: true}
        ]}
      >
        <Card
          title={t("app.components.ProductsView.heading")}
          titleAction={
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
              <Button 
                startIcon={<AddCircleOutlineIcon/>}
                variant="text"
                onClick={() => navigate("/products/new")}
              >
                {t("app.components.ProductsView.add")}
              </Button>
            </Box>
          }
          styles={css({
            paddingTop: tokens.spacing.xxs,
            paddingBottom: tokens.spacing.xxs,
            '@media (min-width: 992px)': {
              paddingTop: tokens.spacing.xs,
              paddingBottom: tokens.spacing.xs,
            }
          })}
        >
          { appContext.catalogueProducts && 
            <Box sx={{ flexGrow: 1 }} style={{marginTop: tokens.spacing.xs, marginBottom: tokens.spacing.xs}}>
              { Object.entries(appContext.catalogueProducts).map(([key, value], i) => <ProductItemAccordion key={i} id={key} itemObject={value} isEditable={!!value.sessionAccountID || appContext.isAdmin()} />)}
            </Box>
          }
        </Card>
      </Page>
    </>
  )
}

const ProductItemAccordion: React.FC<{id: string, itemObject: JsonObject<Product>, isEditable: boolean}> = ({id, itemObject, isEditable}) => {
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ct = useCustomTranslations();
  const product = itemObject.jsonData;
  // console.log("product", product);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("app.components.ProductsView.delete.confirm.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("app.components.ProductsView.delete.confirm.message");

  const [showEpcisEvents, setShowEpcisEvents] = useState<boolean>(false);
  const [createEpcisEventProductId, setCreateEpcisEventProductId] = useState<string|undefined>(undefined);

  const confirmDeleteProduct = (id: string) => {
    setConfirmHeading("app.components.ProductsView.delete.confirm.heading");
    setConfirmMessage("app.components.ProductsView.delete.confirm.message");
    setConfirmAction(() => () => deleteProduct(id));
    setShowConfirmModal(true);
  }

  const deleteProduct = (id: string) => {
    console.log("deleteProduct with id", id);
    const productToDelete = appContext.catalogueProducts[id];
    if (productToDelete) {
      DeleteCatalogue(id)
      .then(res => {
        if (res.status == 200 && appContext.company?.id) {
          // Reload products to state after successful deletion of a product.
          appContext.getCatalogueProductsAsync();
        }
      })
      .catch(err => {
        console.error("DeleteMiniSuomiCatalogue err", err);
      })
      .finally(() => setShowConfirmModal(false));
    }
  }

  const productName = () => {
    if (product.name?.value) {
      return product.name?.value;
    }
    return <i>{t("app.components.ProductsView.noName")}</i>;
  }

  const productBrandName = () => {
    return product.brandName ? product.brandName[0].value : "";
  }

  const createNewEventCallback = () => {
    return setCreateEpcisEventProductId(undefined);
  }

  return (
    <Accordion sx={{backgroundColor: itemObject.sessionAccountID ? tokens.colors.highlightLight2 : undefined}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container sx={{alignItems: "center", marginRight: {sm: tokens.spacing.xs}}}>
          <Grid item xs={12} sm={5} sx={{display: "flex", alignItems: "center"}}>
            <ListItemText primaryTypographyProps={{variant: "button", fontWeight: "700"}} primary={productName()} sx={{maxWidth: "fit-content"}} />
            { !hasClassifiedTaxCategory(product) &&
              <Tooltip title={t("app.components.CatalogueProductItemView.taxCategoryInfo.info")}>
                <WarningAmberIcon color="warning"/>
              </Tooltip>
            }
          </Grid>
          <Grid item xs={12} sm={true}>
            <ListItemText primaryTypographyProps={{variant: "button"}} primary={productBrandName()} />
          </Grid>
          <Grid container item xs={"auto"} spacing={1}>
            <Grid item xs={6}>
              <Button onClick={() => navigate(`/products/${id}/edit`)}>{isEditable ? t("common.actions.edit") : t("common.actions.open")}</Button>
            </Grid>
            <Grid item xs={6}>
              { isEditable && <Button color="error" onClick={() => confirmDeleteProduct(id)}>{t("common.actions.delete")}</Button> }
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} sx={{alignItems: "center"}}>
          { !hasClassifiedTaxCategory(product) &&
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>{t(`app.components.CatalogueProductItemView.taxCategoryInfo.info`)}</AlertTitle>
              </Alert>
            </Grid>
          }
          <Grid item container xs={12} md={6}>
            <Grid item xs={6} sx={{fontWeight: 700}}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={t("app.components.ProductsView.productId")}/>
            </Grid>
            <Grid item xs={6}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={itemObject.id}/>
            </Grid>
            <Grid item xs={6} sx={{fontWeight: 700}}>
              {t("app.components.ProductsView.createdOn")}
            </Grid>
            <Grid item xs={6}>
              {toDateTimeString(product.createdOn)}
            </Grid>
            <Grid item xs={6} sx={{fontWeight: 700}}>
              {t("app.components.ProductsView.updatedOn")}
            </Grid>
            <Grid item xs={6}>
              {toDateTimeString(product.updatedOn)}
            </Grid>
            <Grid item xs={6} sx={{fontWeight: 700}}>
              {t("app.components.ProductsView.description")}
            </Grid>
            <Grid item xs={6}>
              {product.description && product.description[0].value}
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item>
            <Button variant="contained" onClick={() => setShowEpcisEvents(true)}>
              {t("app.components.ProductsView.viewEvents")}
            </Button>
            </Grid>
            <Grid item>
            <Button variant="contained" onClick={() => setCreateEpcisEventProductId(id)}>
              {t("app.components.ProductsView.addEvent")}
            </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <EpcisEventsDialog open={showEpcisEvents} handleClose={() => setShowEpcisEvents(false)} productObject={itemObject} />
      <CreateEpcisEventDialog productId={createEpcisEventProductId} handleClose={() => setCreateEpcisEventProductId(undefined)} onSubmit={createNewEventCallback} />
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Accordion>
  );
}

export default ProductsView;
