import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Card from '../../common/Card';
import Page from '../../common/Page';
import PurchaseInvoicesTable from '../../PurchaseInvoicesTable';
import ReceiveInvoiceDialog from '../../modals/ReceiveInvoiceDialog';
import PurchaseReceiptsTable from '../../PurchaseReceiptsTable';

const ProcurementsView: React.FC = () => {
  const { t } = useTranslation();
  const [openReceiveInvoice, setOpenReceiveInvoice] = useState<boolean>(false);

  return (
    <>
      <Page
        breadcrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/procurements", label: "procurements", current: true}
        ]}
      >
        <Card title={t("app.components.ProcurementsView.callsForTenders.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card title={t("app.components.ProcurementsView.tenders.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card title={t("app.components.ProcurementsView.ordersSent.heading")}>
          <p>Inbox-tyyppinen taulukko, esim 10 viimeisintä. Klikkaamalla tai rullaamalla lisää.
          Suodattamalla erilaisia listauksia</p>
        </Card>
        <br />
        <Card
          title={t("app.components.ProcurementsView.purchaseInvoices.heading")}
          titleAction={
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
            <Button 
              startIcon={<AddCircleOutlineIcon/>}
              variant="text"
              onClick={() => setOpenReceiveInvoice(() => true)}
            >
              {t("app.components.ProcurementsView.purchaseInvoices.addBtn")}
            </Button>
          </Box>
          }
        >
          <PurchaseInvoicesTable/>
          <ReceiveInvoiceDialog open={openReceiveInvoice} handleClose={() => setOpenReceiveInvoice(() => false)} />
        </Card>
        <br />
        <Card
          title={t("app.components.ProcurementsView.purchaseReceipts.heading")}
        >
          <PurchaseReceiptsTable/>
        </Card>
      </Page>
    </>
  )
}

export default ProcurementsView;