import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { Invoice } from '../../model/AppModels';
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppStateContext } from '../../state/AppStateContext';

interface Props {
  invoice?: Invoice,
  handleClose: () => void
}

const AcceptInvoiceDialog: React.FC<Props> = ({invoice, handleClose}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [accepting, setAccepting] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>(undefined);

  useEffect(() => {
    setAccepting(() => false);
    setNotification(() => undefined);
  }, [invoice]);

  const submit = async () => {
    setAccepting(() => true);
    setNotification(() => undefined);
    if (invoice) {
      OmaYritysApi.AcceptPeppolInvoice(invoice.document.id)
      .then(() => {
        setNotification(() => "success");
        appContext.getPeppolDocumentsAsync();
      })
      .catch(() => setNotification(() => "error"))
      .finally(() => setAccepting(() => false));
    }
  }

  return (
    <Dialog open={!!invoice} onClose={handleClose} fullWidth>
      <DialogTitle>
        {t("app.components.AcceptInvoiceDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{marginBottom: tokens.spacing.s}}>{t("app.components.AcceptInvoiceDialog.info")}</Typography>
        { notification &&
          <Alert severity={notification === "success" ? "success" : "error"}>
            <AlertTitle>{t(`app.components.AcceptInvoiceDialog.${notification}Title`)}</AlertTitle>
          </Alert>
        }
        { accepting && 
          <Alert severity="info" icon={false}>
            <AlertTitle sx={{display: "flex", alignItems: "center"}}>
              <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
              {t(`app.components.AcceptInvoiceDialog.acceptingInvoice`)}
            </AlertTitle>
          </Alert>
        }
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={accepting}
        >
          { notification ? t("common.actions.close") : t("common.actions.cancel") }
        </Button>
        <Button
          variant="contained"
          onClick={submit}
          disabled={accepting || notification === "success"}
        >
          {t(`common.actions.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcceptInvoiceDialog;