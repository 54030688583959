import axios, { AxiosResponse } from 'axios';

const apiRootUrl = '/'

const api = axios.create({
  baseURL: apiRootUrl
});

export async function GetFile(filepath: string): Promise<AxiosResponse<string>> {
  return api.get<string>(`/${filepath}`);
}