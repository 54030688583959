import {jsx} from '@emotion/react'
import React from "react";
import {FlexCol, FlexRow } from "../../common/CommonStyles";
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { Address } from '../../../model/address';
import Card from '../../common/Card'
import { Label } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete'



class AddressEditor extends React.Component<any, Address> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      
  }

  edit: boolean = true;

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  toggleEdit() {
    this.edit = !this.edit;
    this.setState({});
  }

  render() {
    
      return (
        <Card title=''>
              {/*<Switch
        checked={this.edit}
        onChange={this.toggleEdit.bind(this)}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
              />*/}
              {
                this.edit? 
                <FlexCol>
                  <div>
                  <IconButton onClick={() => this.props.deleteAddress(this.props.data.Id)} >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    </div>
              <FlexRow>
                <TextField name='StreetName' label="Katuosoite" value={this.state.StreetName} onChange={this.handleChange.bind(this)} variant="outlined" />
               </FlexRow>
               <FlexRow>
               
                <TextField name='AdminUnitLevel1' label="Admin unit level" value={this.state.AdminUnitLevel1} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexRow>
               <FlexRow>

                <TextField name='AdminUnitLevel2' label="Admin unit level" value={this.state.AdminUnitLevel2} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexRow>
               <FlexRow>

                <TextField name='AddressTypeCode' label="Osoitetyyppi" value={this.state.AddressTypeCode} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexRow>
               <FlexRow>

                <TextField name='PoBox' label="Postilokero" value={this.state.PoBox} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexRow>
               <FlexRow>

                <TextField name='PostCode' label="Postinumero" value={this.state.PostCode} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexRow>
               <FlexRow>

                <TextField name='PostName' label="Postitoimialue" value={this.state.PostName} onChange={this.handleChange.bind(this)} variant="outlined" />
              </FlexRow>

              
            </FlexCol>
            :
            <FlexCol>
              <FlexRow>
               {this.state.StreetName}
               <br/>
           
               
                {this.state.AdminUnitLevel1} 
                <br/>
               {this.state.AdminUnitLevel2} 
               <br/>
                {this.state.AddressTypeCode} 
                <br/>
           {this.state.PoBox} 
           <br/>
                {this.state.PostCode} 
                <br/>
                {this.state.PostName} 
</FlexRow>
</FlexCol>
              }
           
            </Card>
      );
  }
}

export default AddressEditor;
