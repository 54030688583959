/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../common/Page';
import { useAppStateContext } from '../../state/AppStateContext';
import PageNotFoundView from './PageNotFoundView';
import { CatalogueReferencedContractForm, InputWrapper, CataloguePartyForm, ToolTippedCard, TooltippedTextField } from '../common/CustomFormikComponents';
import { JsonObject, JsonObjectRequestData } from '../../model/OmaXTypes';
import { PostCatalogue } from '../../api/OmaYritysApi';
import Card from '../common/Card';
import { Catalogue, GetCatalogueTemplate } from '../../model/Peppol/PreAwardCatalogue';
import ConfirmDialog from '../common/ConfirmDialog';
import { NameType } from '../../model/UBL/UnqualifiedDataTypes';

const CatalogueView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { catalogueId } = useParams<{catalogueId: string}>();
  // console.log("catalogueId", catalogueId);
  const [existingCatalogueObject, setExistingCatalogueObject] = useState<JsonObject<Catalogue>|undefined>(undefined);
  const [existingCatalogue, setExistingCatalogue] = useState<Catalogue|undefined>(undefined);
  // Catalogue is editable if it is a new one, it belongs to a session or the user is an admin
  const isEditable = !existingCatalogue || !!existingCatalogueObject?.sessionAccountID || appContext.isAdmin();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("app.components.CatalogueView.cancel.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("app.components.CatalogueView.cancel.message");

  useEffect(() => {
    if (!existingCatalogue && catalogueId) {
      const catalogueObject = catalogueId ? appContext.catalogues[catalogueId] : undefined;
      const catalogue = catalogueObject?.jsonData;
      if (catalogue) {
        formik.resetForm({ values: catalogue});
        setExistingCatalogueObject(catalogueObject);
        setExistingCatalogue(catalogue);
      }
    }
  }, [appContext.catalogues]);

  const submit = async (values: Catalogue) => {
    console.log("onSubmit", values);
    // Copy lines from existing catalog if it exists
    const catalogueToUpdate = existingCatalogue ? {...values, catalogueLine: existingCatalogue.catalogueLine} : values;
    const formData: JsonObjectRequestData<Catalogue> = {
      id: catalogueId ?? undefined,
      dataType: "Catalogue",
      jsonData: catalogueToUpdate
    };
    const resp = await PostCatalogue(formData);
    console.log("Response", resp);
    navigate("/catalogues");
  }

  const formik = useFormik({
    initialValues: GetCatalogueTemplate(),
    validationSchema: Catalogue.schema.shape({
      name: NameType.schema.shape({
        value: Yup.string().required("required")
      })
    }),
    onSubmit: submit
  });

  const onGoBack = () => {
    // Notify the user if the form has changes. Else just go back
    if (formik.dirty) {
      setConfirmHeading("app.components.CatalogueView.cancel.heading");
      setConfirmMessage("app.components.CatalogueView.cancel.message");
      setConfirmAction(() => () => navigate(-1));
      setShowConfirmModal(true);
    }
    else {
      navigate(-1);
    }
  }

  // useEffect(() => {
  //   console.log("Formikprops.values", formik.values);
  // }, [formik.values]);

  // console.log("formik.dirty", formik.dirty);
  // console.log("Formikprops.values", formik.values);
  // console.log("Formikprops.errors", formik.errors);
  // console.log("Formikprops.touched", formik.touched);

  if (catalogueId && !existingCatalogue) {
    console.log("PageNotFoundView");
    return (
      <PageNotFoundView
        title={t("app.components.CatalogueView.pageNotFoundTitle")}
        breadCrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/catalogues", label: "catalogues", current: false},
          { href: "/catalogues/:catalogueId/edit", label: "edit-catalogue", current: true}
        ]}
      >
        <Link to={"/products"}>{t("app.components.CatalogueView.pageNotFoundLink")}</Link>
      </PageNotFoundView>
    );
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/catalogues", label: "catalogues", current: false},
        ( existingCatalogue ? 
          { href: "/catalogues/:catalogueId/edit", label: "edit-catalogue", current: true} :
          { href: "/catalogues/new", label: "new-catalogue", current: true}
        )
      ]}
    >
      <Card title={t(`app.components.CatalogueView.heading-${existingCatalogue ? "edit" : "new"}`)}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Typography sx={{marginBottom: tokens.spacing.m}}>
            <Trans
              i18nKey="app.components.CatalogueView.info"
              components={{
                link1: <a href="https://docs.peppol.eu/pracc/catalogue/1.0/" target="_blank" rel="noreferrer">a PEPPOL Pre-Award Catalogue</a>
              }}
            />
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="uBLVersionID" fieldPath="uBLVersionID.value" memberOfClass="Catalogue" formikProps={formik} disabled={true} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="profileID" fieldPath="profileID.value" memberOfClass="Catalogue" formikProps={formik} disabled={true} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TooltippedTextField fieldName="customizationID" fieldPath="customizationID.value" memberOfClass="Catalogue" formikProps={formik} disabled={true} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="iD" fieldPath="iD.value" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="versionID" fieldPath="versionID.value" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="name" fieldPath="name.value" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="note" fieldPath="note[0].value" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="issueDate" fieldPath="issueDate.valueString" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="issueTime" fieldPath="issueTime.valueString" memberOfClass="Catalogue" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <ToolTippedCard fieldName="validityPeriod" memberOfClass="Catalogue">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="startDate" fieldPath="validityPeriod[0].startDate.valueString" memberOfClass="Period" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="endDate" fieldPath="validityPeriod[0].endDate.valueString" memberOfClass="Period" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </ToolTippedCard>
            </Grid>
            <Grid item xs={12}>
              <CatalogueReferencedContractForm formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <CataloguePartyForm partyFieldName="providerParty" partyFieldPath="providerParty" formikProps={formik} fieldsFilter={["endpointID", "iD", "name", "postaladdress"]} required />
            </Grid>
            <Grid item xs={12}>
              <CataloguePartyForm partyFieldName="receiverParty" partyFieldPath="receiverParty" formikProps={formik} fieldsFilter={["endpointID", "iD", "name", "postaladdress"]} required />
            </Grid>
            <Grid item xs={12}>
              <CataloguePartyForm partyFieldName="sellerSupplierParty" partyFieldPath="sellerSupplierParty.party" formikProps={formik} fieldsFilter={["iD", "name", "postaladdress", "contact"]} />
            </Grid>
            <Grid item xs={12}>
              <CataloguePartyForm partyFieldName="contractorCustomerParty" partyFieldPath="contractorCustomerParty.party" formikProps={formik} fieldsFilter={["iD", "name", "contact"]} />
            </Grid>
            <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
              <Button
                variant="outlined"
                onClick={onGoBack}
              >
                {t("common.actions.back")}
              </Button>
              { isEditable &&
                <Button variant="contained" type="submit">
                  {t("common.actions.save")}
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </Card>
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Page>
  );
}

export default CatalogueView;