import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Box, Button, TablePagination, TableFooter, ListItemText } from '@mui/material';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import TablePaginationActions from '../../common/TablePaginationActions';
import { useTranslation } from 'react-i18next';
import { CreateDeliveryOrder } from '../../../api/OmaYritysApi';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const OrderTable: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(1);

  const addDeliveryOrder = async () => {
    console.log("Add new delivery order.")
    const newDeliveryOrder = {
      orderNumber: "f0e80d2c-5ec2-4a92-85f9-5c1220f6f92d",
      fromAddress: "C10F8BE4-6117-4B5A-8A65-4DE595546E1D",
      actualShipmentDatetime: new Date().toISOString(),
      volumeMeasure: "1",
      volumeUnit: "m3",
      weightMeasure: "500",
      weightUnit: "kg",
      packageType: "Box",
      packageBarcode: "",
      eFTIDataId: "",
      carrierParty: "CarrierXYZ",
      modified: new Date().toISOString()
    }

    try {
      const response = await CreateDeliveryOrder(newDeliveryOrder);
    } catch (error) {
      console.error("Error while creating delivery order:", error)
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{overflow: "auto"}}>
      <TableContainer component={Paper} style={{minWidth: "800px"}}>
        <Table aria-label="simple table">
          <colgroup>
            <col width="15%"/>
            <col width="40%"/>
            <col width="15%"/>
            <col width="20%"/>
            <col width="10%"/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCellThin align="left">{t("app.components.OrderTable.product")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.buyer")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.quantity")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.orderDate")}</TableCellThin>
              <TableCellThin align="left">{t("app.components.OrderTable.delivery")}</TableCellThin>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellThin align="left">Polttopuu</TableCellThin>
              <TableCellThin align="left">
                <ListItemText primary="Anaani Testifirma" secondary="0216:00377006370100100" />
              </TableCellThin>
              <TableCellThin align="left">1</TableCellThin>
              <TableCellThin align="left">10.8.2024</TableCellThin>
              <TableCellThin align="left"><Button onClick={() => addDeliveryOrder()} >{t("app.components.OrderTable.deliverBtn")}</Button></TableCellThin>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination 
                labelRowsPerPage="Rivejä per sivu:"
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrderTable;