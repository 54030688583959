/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import Card from '../../common/Card';
import { BalanceType, GeneralLedgerEntry } from '../../../model/AppModels';
import XbrlGlDocumentDialog from '../../modals/XbrlGlDocumentDialog';
import TablePaginationActions from '../../common/TablePaginationActions';

const TableCellThin = styled(TableCell)`
  padding: ${tokens.spacing.xxs};
`

const AccountingView: React.FC = () => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [debitPage, setDebitPage] = useState(0);
  const [creditPage, setCreditPage] = useState(0);
  const [debitRowsPerPage, setDebitRowsPerPage] = useState(10);
  const [creditRowsPerPage, setCreditRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [dialogEntry, setDialogEntry] = useState<GeneralLedgerEntry|undefined>(undefined);
  const [debitEntries, setDebitEntries] = useState<GeneralLedgerEntry[]>([]);
  const [creditEntries, setCreditEntries] = useState<GeneralLedgerEntry[]>([]);

  useEffect(() => {
    setLoading(() => true);
    appContext.getAccountingEntriesAsync()
    .then(entries => {
      const dEntries = entries.filter(it => it.balanceType == BalanceType.DEBIT);
      setDebitEntries(() => dEntries.sort((a, b) => b.entryNumber > a.entryNumber ? -1 : 1));
      const cEntries = entries.filter(it => it.balanceType == BalanceType.CREDIT);
      setCreditEntries(() => cEntries.sort((a, b) => b.entryNumber > a.entryNumber ? -1 : 1));
    })
    .finally(() => setLoading(() => false));
  }, []);

  const handleDebitChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setDebitPage(newPage);
  };

  const handleDebitChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDebitRowsPerPage(parseInt(event.target.value, 10));
    setDebitPage(0);
  };

  const handleCreditChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCreditPage(newPage);
  };

  const handleCreditChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCreditRowsPerPage(parseInt(event.target.value, 10));
    setCreditPage(0);
  };

  const getDocumentType = (entryNumber?: string) => {
    if (entryNumber?.includes("SI")) {
      return "XBRL-GL:SalesInvoice";
    }
    else if (entryNumber?.includes("PI")) {
      return "XBRL-GL:PurchaseInvoice";
    }
    else if (entryNumber?.includes("SR")) {
      return "XBRL-GL:SalesReceipt";
    }
    else if (entryNumber?.includes("PR")) {
      return "XBRL-GL:PurchaseReceipt";
    }
    return "";
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/accounting", label: "accounting", current: true}
      ]}
    >
      <Card
        title={t("app.components.AccountingView.salesHeading")}
      >
        <Box sx={{overflow: "auto"}}>
          <TableContainer component={Paper} style={{minWidth: "600px"}}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{"& > * ": {fontWeight: "700"}}}>
                  <TableCellThin>{t("app.components.AccountingView.entryNumber")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.accountNumber")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.monetaryAmount")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.balanceType")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.postingDate")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.description")}</TableCellThin>
                  <TableCellThin align="right">{t("app.components.AccountingView.xbrl-gl")}</TableCellThin>
                </TableRow>
              </TableHead>
              <TableBody>
                { (loading) &&
                  <TableRow>
                    <TableCellThin colSpan={8} sx={{padding: 0}}>
                      <LinearProgress/>
                    </TableCellThin>
                  </TableRow>
                }
                {(creditRowsPerPage > 0
                  ? creditEntries.slice(creditPage * creditRowsPerPage, creditPage * creditRowsPerPage + creditRowsPerPage)
                  : creditEntries
                ).map((e, eIndex) => (
                  <TableRow
                    key={eIndex}
                    sx={{backgroundColor: e.sessionAccountId ? tokens.colors.highlightLight2 : undefined}}
                  >
                    <TableCellThin component="th" scope="row">{e.entryNumber}</TableCellThin>
                    <TableCellThin align="left">{e.accountNumber}</TableCellThin>
                    <TableCellThin align="left">{e.monetaryAmount}</TableCellThin>
                    <TableCellThin align="left">{e.balanceType}</TableCellThin>
                    <TableCellThin align="left">{e.postingDate}</TableCellThin>
                    <TableCellThin align="left">{e.description}</TableCellThin>
                    <TableCellThin align="right"><Link style={{padding: 0, cursor: "pointer"}} onClick={() => setDialogEntry(e)}>{t("common.actions.open")}</Link></TableCellThin>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={creditEntries.length}
                    rowsPerPage={creditRowsPerPage}
                    page={creditPage}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleCreditChangePage}
                    onRowsPerPageChange={handleCreditChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Card
        title={t("app.components.AccountingView.purchasesHeading")}
        styles={css({
          marginTop: tokens.spacing.m,
        })}
      >
        <Box sx={{overflow: "auto"}}>
          <TableContainer component={Paper} style={{minWidth: "600px"}}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{"& > * ": {fontWeight: "700"}}}>
                  <TableCellThin>{t("app.components.AccountingView.entryNumber")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.accountNumber")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.monetaryAmount")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.balanceType")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.postingDate")}</TableCellThin>
                  <TableCellThin align="left">{t("app.components.AccountingView.description")}</TableCellThin>
                  <TableCellThin align="right">{t("app.components.AccountingView.xbrl-gl")}</TableCellThin>
                </TableRow>
              </TableHead>
              <TableBody>
                { (loading) &&
                  <TableRow>
                    <TableCellThin colSpan={8} sx={{padding: 0}}>
                      <LinearProgress/>
                    </TableCellThin>
                  </TableRow>
                }
                {(debitRowsPerPage > 0
                  ? debitEntries.slice(debitPage * debitRowsPerPage, debitPage * debitRowsPerPage + debitRowsPerPage)
                  : debitEntries
                ).map((e, eIndex) => (
                  <TableRow
                    key={eIndex}
                    sx={{backgroundColor: e.sessionAccountId ? tokens.colors.highlightLight2 : undefined}}
                  >
                    <TableCellThin component="th" scope="row">{e.entryNumber}</TableCellThin>
                    <TableCellThin align="left">{e.accountNumber}</TableCellThin>
                    <TableCellThin align="left">{e.monetaryAmount}</TableCellThin>
                    <TableCellThin align="left">{e.balanceType}</TableCellThin>
                    <TableCellThin align="left">{e.postingDate}</TableCellThin>
                    <TableCellThin align="left">{e.description}</TableCellThin>
                    <TableCellThin align="right"><Link style={{padding: 0, cursor: "pointer"}} onClick={() => setDialogEntry(e)}>{t("common.actions.open")}</Link></TableCellThin>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage={t("app.components.TablePagination.labelRowsPerPage")}
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={debitEntries.length}
                    rowsPerPage={debitRowsPerPage}
                    page={debitPage}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleDebitChangePage}
                    onRowsPerPageChange={handleDebitChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <XbrlGlDocumentDialog accountingNumber={dialogEntry?.entryNumber} docStandard="gl-plt-fi-all-2023-06-22" docType={getDocumentType(dialogEntry?.entryNumber)} handleClose={() => setDialogEntry(() => undefined)} />
    </Page>
  )
}

export default AccountingView;