import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Popover, Select, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAppStateContext } from '../../../state/AppStateContext';
import { InputWrapper } from '../../common/CustomFormikComponents';
import { getStandardItemIdentificationAsEPC } from '../../../utils/peppolUtils';
import { PostCreateEpcisEvent } from '../../../api/OmaYritysApi';

interface AvailableEpcisEvent {
  eventKey: string
}

const AvailableEpcisEvents: AvailableEpcisEvent[] = [
  {
    eventKey: "tradeItemIdentifier"
  },
  {
    eventKey: "sgtin"
  }
]

const EpcInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{margin: tokens.spacing.xs, maxWidth: "sm"}}>
      <Typography variant="button">{t("app.components.CreateEpcisEventDialog.epc.epcInfo.heading")}</Typography>
      <Typography>{t("app.components.CreateEpcisEventDialog.epc.epcInfo.paragraph1")}</Typography>
      <br/>
      <Typography>{t("app.components.CreateEpcisEventDialog.epc.epcInfo.paragraph2")}</Typography>
    </Box>
  )
}

export interface EpcisEventRequest {
  eventType: string,
  epc?: string,
  additionalValue?: string
}

const CreateEpcisEventDialog: React.FC<{productId?: string, handleClose: () => void, onSubmit: () => void}> = ({productId, handleClose, onSubmit}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [epc, setEpc] = useState<string|undefined>(undefined);
  const [epcAnchorEl, setEpcAnchorEl] = useState<HTMLButtonElement|null>(null);

  useEffect(() => {
    if (productId) {
      const productObject = appContext.catalogueProducts[productId];
      const epc = getStandardItemIdentificationAsEPC(productObject);
      console.log("EPC:", epc);
      setEpc(() => epc);
    }
    else {
      formik.resetForm({values: {eventType: "", epc: "", additionalValue: ""} as EpcisEventRequest});
    }
  }, [productId]);

  const submit = async (eventRequest: EpcisEventRequest) => {
    console.log("onSubmit", eventRequest);
    PostCreateEpcisEvent(productId!, eventRequest)
    .then(res => {
      handleClose();
    })
  }

  const formik = useFormik({
    initialValues: {eventType: "", epc: "", additionalValue: ""} as EpcisEventRequest,
    validationSchema: Yup.object({
      eventType: Yup.string().required("required"),
      epc: Yup.string().required("required"),
      additionalValue: Yup.string().when("eventType", ([eventType], schema) => {
        if (eventType === "tradeItemIdentifier") {
          return schema.required("required");
        }
        return schema;
      })
    }),
    onSubmit: submit
  });

  useEffect(() => {
    if (formik.values.eventType === "sgtin") {
      formik.resetForm({values: {eventType: "sgtin", epc: epc ?? "", additionalValue: ""} as EpcisEventRequest});
      // formik.setFieldValue("epc", epc ?? "", true);
      // formik.setFieldTouched("epc", true, true);
    }
    else if (formik.values.eventType === "tradeItemIdentifier") {
      formik.resetForm({values: {eventType: "tradeItemIdentifier", epc: epc ?? "", additionalValue: ""} as EpcisEventRequest});
      // formik.setFieldValue("epc", epc ?? "", true);
      // formik.setFieldTouched("epc", true, true);
    }
  }, [formik.values.eventType]);

  const availableEpcisEvents = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {t("app.components.CreateEpcisEventDialog.selectEvent.label")}
    </MenuItem>];
    return empty.concat(AvailableEpcisEvents
    .map(it => (
      <MenuItem key={"selectEvent-" + it.eventKey} value={it.eventKey}>
        <ListItemText primaryTypographyProps={{fontWeight: "700"}} primary={t(`app.components.CreateEpcisEventDialog.selectEvent.${it.eventKey}`)}/>
      </MenuItem>
      )
    ));
  }

  // useEffect(() => {
  //   console.log("Formikprops.dirty", formik.dirty);
  // }, [formik.dirty]);
  // useEffect(() => {
  //   console.log("Formikprops.values", formik.values);
  // }, [formik.values]);
  // useEffect(() => {
  //   console.log("Formikprops.errors", formik.errors);
  // }, [formik.errors]);
  // useEffect(() => {
  //   console.log("Formikprops.touched", formik.touched);
  // }, [formik.touched]);

  const getErrorMessage = (errorKey?: string, param?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + param;
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  return (
    <Dialog open={!!productId} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {t("app.components.CreateEpcisEventDialog.title")}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputWrapper>
                <Box display="flex" sx={{"& > *": {flex: 1}}}>
                  <FormControl error={Boolean(formik.touched.eventType) && Boolean(formik.errors.eventType)}>
                    <InputLabel className="MuiInputLabel-outlined" id={"eventType"}>{t("app.components.CreateEpcisEventDialog.selectEvent.label")}</InputLabel>
                    <Select 
                      name={"eventType"}
                      labelId={"eventType"}
                      value={formik.values.eventType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      required
                    >
                      { availableEpcisEvents() }
                    </Select>
                    { Boolean(formik.touched.eventType) && formik.errors.eventType && 
                      <FormHelperText>{getErrorMessage(formik.errors.eventType)}</FormHelperText> 
                    }
                  </FormControl>
                </Box>
              </InputWrapper>
            </Grid>
            { formik.values.eventType === "tradeItemIdentifier" &&
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Button
                        sx={{marginBottom: tokens.spacing.s}}
                        color="primary" variant="outlined"
                        startIcon={<InfoIcon/>}
                        onClick={event => setEpcAnchorEl(event.currentTarget)}
                      >
                        {t("app.components.CreateEpcisEventDialog.epc.epcInfoBtn")}
                      </Button>
                      { !formik.values.epc &&
                        <Typography sx={{marginY: tokens.spacing.s}} color={tokens.colors.alertBase}>
                          {t("common.validation.epcRequired")}
                        </Typography>
                      }
                      <TextField
                        sx={{display: "flex"}}
                        name="epc"
                        label={t(`app.components.CreateEpcisEventDialog.epc.${formik.values.eventType}`)}
                        value={formik.values.epc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.epc && Boolean(formik.errors.epc)}
                        helperText={formik.touched.epc && Boolean(formik.errors.epc) && getErrorMessage(formik.errors.epc)}
                        variant="outlined"
                        disabled
                        required
                      />
                      <Popover
                        open={!!epcAnchorEl}
                        anchorEl={epcAnchorEl}
                        onClose={() => setEpcAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                      >
                        <EpcInfo/>
                      </Popover>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <TextField
                        sx={{display: "flex"}}
                        name="additionalValue"
                        label={t(`app.components.CreateEpcisEventDialog.additionalValue.${formik.values.eventType}`)}
                        value={formik.values.additionalValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.additionalValue && Boolean(formik.errors.additionalValue)}
                        helperText={formik.touched.additionalValue && Boolean(formik.errors.additionalValue) && getErrorMessage(formik.errors.additionalValue)}
                        variant="outlined"
                        required
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Grid>
            }
            { formik.values.eventType === "sgtin" &&
              <Grid item xs={12}>
                <InputWrapper>
                  <Button
                    sx={{marginBottom: tokens.spacing.s}}
                    color="primary" variant="outlined"
                    startIcon={<InfoIcon/>}
                    onClick={event => setEpcAnchorEl(event.currentTarget)}
                  >
                    {t("app.components.CreateEpcisEventDialog.epc.epcInfoBtn")}
                  </Button>
                  { !formik.values.epc &&
                    <Typography sx={{marginY: tokens.spacing.s}} color={tokens.colors.alertBase}>
                      {t("common.validation.epcRequired")}
                    </Typography>
                  }
                  <TextField
                    sx={{display: "flex"}}
                    name="epc"
                    label={t(`app.components.CreateEpcisEventDialog.epc.${formik.values.eventType}`)}
                    value={formik.values.epc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.epc && Boolean(formik.errors.epc)}
                    helperText={formik.touched.epc && Boolean(formik.errors.epc) && getErrorMessage(formik.errors.epc)}
                    variant="outlined"
                    disabled
                    required
                  />
                  <Popover
                    open={!!epcAnchorEl}
                    anchorEl={epcAnchorEl}
                    onClose={() => setEpcAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <EpcInfo/>
                  </Popover>
                </InputWrapper>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button variant="contained" onClick={handleClose}>{t("common.actions.cancel")}</Button>
          <Button variant="contained" type="submit">{t("common.actions.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateEpcisEventDialog;