import React from "react";
import {default as ReactModal} from 'react-modal';
import { Button, Heading, Paragraph, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../common/CommonStyles";

interface Props {
  isOpen: boolean,
  heading: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string,
  styleContent?: {},
  zIndex?: number,
  confirmAction: Function,
  cancelAction: Function
}

const ConfirmModal: React.FC<Props> = (props: Props) => {

  const defaultStyleContent = {
    top: '10%',
    margin: '0 auto',
    width: '50%',
    height: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white'
  };
  const defaultOverLay = {
    position: 'fixed' as 'fixed',
    display: 'flex',
    justifyContent: 'center',
    zIndex: props.zIndex ?? 10,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflowY: "auto" as 'auto'
  }

  return (
    <ReactModal isOpen={props.isOpen} style={{
      content: {
        ...defaultStyleContent, ...props.styleContent
      },
      overlay: {
        ...defaultOverLay
      },
    }} ariaHideApp={false} contentLabel="Ladataan...">
      <FlexCol>
        <FlexRow>
          <Heading variant='h2'><b>{props.heading}</b></Heading>
        </FlexRow>
        <FlexRow>
          <Paragraph style={{margin: `${tokens.spacing.m} 0`}}>
            {props.message}
          </Paragraph>
        </FlexRow>
        <FlexRow>
        <Button onClick={() => props.confirmAction()}
                style={{margin: `${tokens.spacing.m} ${tokens.spacing.m}`}}>
          {props.confirmButtonText}
        </Button>
        <Button onClick={() => props.cancelAction()}
                style={{margin: `${tokens.spacing.m} ${tokens.spacing.m}`}}>
          {props.cancelButtonText}
        </Button>
      </FlexRow>
      </FlexCol>
    </ReactModal>
  );
}

export default ConfirmModal;