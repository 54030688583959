/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Alert, AlertTitle, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import Card from '../../common/Card';
import { PostCreateReport } from '../../../api/OmaYritysApi';
import { CreateReportRequest, ReportType } from '../../../model/AppModels';
import { InputWrapper } from '../../common/CustomFormikComponents';
import ConfirmDialog from '../../common/ConfirmDialog';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';

const GetReportTypeTemplate = (): CreateReportRequest => {
  return {
    reportType: "",
    periodStart: "",
    periodEnd: "",
    description: ""
  };
}

const CreateReportForm: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const [sending, setSending] = useState<boolean>(false);
  const [notification, setNotification] = useState<string>();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("common.messages.cancel.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("common.messages.cancel.message");

  const submit = async (reportRequest: CreateReportRequest) => {
    setSending(() => true);
    setNotification(() => undefined);
    try {
      const resp = await PostCreateReport(reportRequest);
      setNotification(() => "success");
      appContext.getReportsAsync();
      navigate("/reporting");
    }
    catch(err) {
      setSending(() => false);
      const axiosError = err as AxiosError;
      const errorData = axiosError.response?.data ;
      let errorKey = "error";
      if (typeof errorData === "string" && errorData.startsWith("MiniSuomiErrorKey")) {
        errorKey = errorData.replace("MiniSuomiErrorKey-", "");
      }
      setNotification(() => errorKey);
    }
    finally {
      setSending(() => false);
    }
  }

  const formik = useFormik({
    initialValues: GetReportTypeTemplate(),
    validationSchema: Yup.object({
      reportType: Yup.mixed<ReportType>().oneOf(Object.values(ReportType)).required("required"),
      periodStart: Yup.string().test("isValid", "invalidPeriodStart", (val, ctx) => {
        if (!val) {
          return false;
        }
        const start = dayjs(val);
        const end = ctx.parent.periodEnd && dayjs(ctx.parent.periodEnd);
        return !end || start.isSame(end) || start.isBefore(end);
      }).required("required"),
      periodEnd: Yup.string().test("isValid", "invalidPeriodEnd", (val, ctx) => {
        if (!val) {
          return false;
        }
        const end = dayjs(val);
        const start = ctx.parent.periodStart && dayjs(ctx.parent.periodStart);
        return !start || end.isSame(start) || end.isAfter(start);
      }).required("required"),
      description: Yup.string().max(512, "maxLengthError")
    }),
    onSubmit: submit
  });

  const availableReportTypes = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {t("app.components.CreateReportForm.select-reportType")}
    </MenuItem>];
    return empty.concat(Object.values(ReportType)
    .map(it => (
      <MenuItem key={"reportTypeItem-" + it} value={it}>
        <ListItemText primaryTypographyProps={{fontWeight: "700"}} primary={t(`app.models.ReportType.${it}`)} />
      </MenuItem>
      )
    ));
  }

  const onGoBack = () => {
    // Notify the user if the form has changes. Else just go back
    if (formik.dirty) {
      setConfirmHeading("common.messages.cancel.heading");
      setConfirmMessage("common.messages.cancel.message");
      setConfirmAction(() => () => navigate(-1));
      setShowConfirmModal(true);
    }
    else {
      navigate(-1);
    }
  }

  const getErrorMessage = (errorKey?: string, param?: string) => {
    if (errorKey === "invalidPeriodStart" || errorKey === "invalidPeriodEnd") {
      return t(`app.components.CreateReportForm.${errorKey}`);
    }
    if (errorKey === "minLengthError" || errorKey === "maxLengthError") {
      return t(`common.validation.${errorKey}`) + param;
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/reporting", label: "salesInvoices", current: false},
        { href: "/reporting/new", label: "new-report", current: true}
      ]}
    >
      <Card title={t(`app.components.CreateReportForm.heading`)}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputWrapper>
                <Box display="flex" sx={{"& > *": {flex: 1}}}>
                  <FormControl error={Boolean(formik.touched.reportType) && Boolean(formik.errors.reportType)}>
                    <InputLabel className="MuiInputLabel-outlined" id={"reportType"}>{t("app.components.CreateReportForm.reportType")}</InputLabel>
                    <Select 
                      name={"reportType"}
                      labelId={"reportType"}
                      value={formik.values.reportType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      required
                    >
                      { availableReportTypes() }
                    </Select>
                    { Boolean(formik.touched.reportType) && formik.errors.reportType && 
                      <FormHelperText>{getErrorMessage(formik.errors.reportType)}</FormHelperText> 
                    }
                  </FormControl>
                </Box>
              </InputWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWrapper>
                <DatePicker
                  sx={{display: "flex"}}
                  name={"periodStart"}
                  label={t("app.components.CreateReportForm.periodStart")}
                  value={dayjs(formik.values.periodStart)}
                  onChange={value => {
                    formik.setFieldTouched("periodStart", true);
                    formik.setFieldValue("periodStart", value?.isValid() ? value.format("YYYY-MM-DD") : "");
                  }}
                  slotProps={{
                    textField: {
                      onBlur: formik.handleBlur,
                      error: Boolean(formik.touched.periodStart) && Boolean(formik.errors.periodStart),
                      helperText: Boolean(formik.touched.periodStart) && Boolean(formik.errors.periodStart) && getErrorMessage(formik.errors.periodStart),
                      variant: "outlined",
                      required: true
                    }
                  }}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWrapper>
                <DatePicker
                  sx={{display: "flex"}}
                  name={"periodEnd"}
                  label={t("app.components.CreateReportForm.periodEnd")}
                  value={dayjs(formik.values.periodEnd)}
                  onChange={value => {
                    formik.setFieldTouched("periodEnd", true);
                    formik.setFieldValue("periodEnd", value?.isValid() ? value.format("YYYY-MM-DD") : "");
                  }}
                  slotProps={{
                    textField: {
                      onBlur: formik.handleBlur,
                      error: Boolean(formik.touched.periodEnd) && Boolean(formik.errors.periodEnd),
                      helperText: Boolean(formik.touched.periodEnd) && Boolean(formik.errors.periodEnd) && getErrorMessage(formik.errors.periodEnd),
                      variant: "outlined",
                      required: true
                    }
                  }}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TextField
                  sx={{display: "flex"}}
                  name={"description"}
                  label={t("app.components.CreateReportForm.description")}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched.description) && Boolean(formik.errors.description)}
                  helperText={Boolean(formik.touched.description) && Boolean(formik.errors.description) && getErrorMessage(formik.errors.description, "512")}
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </InputWrapper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            { notification &&
              <Alert severity={notification === "success" ? "success" : "error"} sx={{marginTop: tokens.spacing.m}}>
                <AlertTitle>{t(`app.components.CreateReportForm.${notification}Title`)}</AlertTitle>
                {t(`app.components.CreateReportForm.${notification}Msg`)}
              </Alert>
            }
            { sending && 
              <Alert severity="info" icon={false} sx={{marginTop: tokens.spacing.m}}>
                <AlertTitle sx={{display: "flex", alignItems: "center"}}>
                  <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
                  {t(`app.components.CreateReportForm.creatingReport`)}
                </AlertTitle>
              </Alert>
            }
          </Grid>
          <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
            <Button
              variant="outlined"
              onClick={onGoBack}
            >
              {t("common.actions.back")}
            </Button>
            <Button variant="contained" type="submit">
              {t("common.actions.save")}
            </Button>
          </Grid>
        </form>
      </Card>
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Page>
  );
}

export default CreateReportForm;