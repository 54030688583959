/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _ from 'lodash';
import { Button, suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { IconPlus } from 'suomifi-icons';
import { DeleteCatalogue, PostCatalogue } from '../../../api/OmaYritysApi';
import { Catalogue } from '../../../model/Peppol/PreAwardCatalogue';
import Card from '../../common/Card';
import { FlexCol } from '../../common/CommonStyles';
import ConfirmModal from '../../common/ConfirmModal';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { JsonObject } from '../../../model/OmaXTypes';

const styles = {
  productCatalog: css({
    // verticalAlign: 'middle'
  }),
  catalogHeader: css({
    marginTop: tokens.spacing.xs,
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    textAlign: 'center',
    fontWeight: 'bold',
    '& > *': {
      borderRight: '1px solid black',
    }
  }),
  catalogCell: css({
    display: "flex",
    alignItems: 'center'
  }),
  catalogCellRight: css({
    display: "flex",
    alignItems: 'center',
    justifyContent: "right",
    textAlign: "right"
  })
}

const CataloguesView: React.FC = () => {
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "preawardcatalogue"]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<Function>(() => () => setShowConfirmModal(false)); // override this

  useEffect(() => {
    if (appContext.company) {
      appContext.getCataloguesAsync();
      appContext.getCatalogueProductsAsync();
    }
  }, []);

  const confirmDeleteProductItem = (id: string) => {
    setConfirmAction(() => () => deleteProduct(id));
    setShowConfirmModal(true);
  }
  
  const deleteProduct = (id: string) => {
    console.log("deleteProduct with id", id);
    const productToDelete = appContext.catalogueProducts[id];
    if (productToDelete) {
      DeleteCatalogue(id)
      .then(res => {
        if (res.status == 200 && appContext.company?.id) {
          // Reload products to state after successful deletion of a product.
          appContext.getCatalogueProductsAsync();
        }
      })
      .catch(err => {
        console.error("DeleteMiniSuomiCatalogue err", err);
      })
      .finally(() => setShowConfirmModal(false));
    }
  }

  return (
    <>
      <Page
        breadcrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/catalogues", label: "catalogues", current: true}
        ]}
      >
        <Card
          title={t("app.components.CataloguesView.heading")}
          titleAction={
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
              <Button 
                icon={ <IconPlus/>}
                variant="secondaryNoBorder"
                onClick={() => navigate("/catalogues/new")}
              >
                {t("app.components.CataloguesView.add")}
              </Button>
            </Box>
          }
          styles={css({
            paddingTop: tokens.spacing.xxs,
            paddingBottom: tokens.spacing.m,
            '@media (min-width: 992px)': {
              paddingTop: tokens.spacing.xs,
              paddingBottom: tokens.spacing.xxl,
            }
          })}
        >
        { appContext.catalogues && 
          <Box sx={{ flexGrow: 1 }} style={{marginTop: tokens.spacing.xs, marginBottom: tokens.spacing.xs}}>
            { Object.entries(appContext.catalogues).map(([key, value], i) => <CatalogueAccordion key={i} id={key} catalogueObject={value} isEditable={!!value.sessionAccountID || appContext.isAdmin()} />)}
          </Box>
        }
        </Card>
      </Page>

      <ConfirmModal
        isOpen={showConfirmModal}
        heading={t("app.components.CataloguesView.delete.confirm.heading")}
        message={t("app.components.CataloguesView.delete.confirm.message")}
        confirmButtonText={t("common.actions.accept")}
        cancelButtonText={t("common.actions.cancel")}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
      />
    </>
  )
}

const CatalogueAccordion: React.FC<{id: string, catalogueObject: JsonObject<Catalogue>, isEditable: boolean}> = ({id, catalogueObject, isEditable}) => {
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "preawardcatalogue"]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<Function>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>('');
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const catalogue = catalogueObject.jsonData;

  const tp = (type: string, obj: string, prop: string|undefined = undefined) => {
    return prop ? 
      t(`components.schemas.${obj}.properties.${prop}.${type}`, {ns: "preawardcatalogue"}) :
      t(`components.schemas.${obj}.${type}`, {ns: "preawardcatalogue"});
  }

  const getProductName = (productId: string) => {
    return appContext.catalogueProducts[productId]?.jsonData.name ?? "Product not found";
  }

  const confirmDeleteCatalogue = (id: string) => {
    setConfirmHeading(t("app.productsView.catalogues.delete.confirm.heading"));
    setConfirmMessage(t("app.productsView.catalogues.delete.confirm.message"));
    setConfirmAction(() => () => deleteCatalogue(id));
    setShowConfirmModal(true);
  }

  const confirmDeleteCatalogueLine = (catalogueId: string, catalogueLineId: string) => {
    setConfirmHeading(t("app.productsView.catalogues.catalogueLines.delete.confirm.heading"));
    setConfirmMessage(t("app.productsView.catalogues.catalogueLines.delete.confirm.message"));
    setConfirmAction(() => () => deleteCatalogueLine(catalogueId, catalogueLineId));
    setShowConfirmModal(true);
  }

  const deleteCatalogue = (id: string) => {
    console.log("deleteCatalogue with id", id);
    const catalogueToDelete = appContext.catalogues[id];
    if (catalogueToDelete) {
      DeleteCatalogue(id)
      .then(res => {
        console.log("DeleteMiniSuomiCatalogue res", res);
        if (res.status == 200 && appContext.company?.id) {
          // Reload catalogs to state after successful deletion of a catalogue.
          appContext.getCataloguesAsync();
        }
      })
      .catch(err => {
        console.error("DeleteMiniSuomiCatalogue err", err);
      })
      .finally(() => setShowConfirmModal(false));
    }
  }

  // Deleting a catalogue line from catalogue actually means removing the line from
  // catalogue and updating the catalogue.
  const deleteCatalogueLine = (catalogueId: string, catalogueLineId: string) => {
    console.log(`deleteCatalogueItem ${catalogueLineId} from catalogue ${catalogueId}`);
    let catalogueObject = {...appContext.catalogues[catalogueId]};
    if (catalogueObject) {
      const catalogue = catalogueObject.jsonData;
      catalogue.catalogueLine = catalogue.catalogueLine?.filter(line => line.iD !== catalogueLineId);
      PostCatalogue({
        id: catalogueId ?? undefined,
        dataType: "Catalogue",
        jsonData: catalogue
      })
      .then(res => {
        console.log("Delete CatalogueItem res", res);
        if (res.status == 200 && appContext.company?.id) {
          // Reload catalog to state after successful deletion of a catalogue line.
          appContext.getCataloguesAsync();
        }
      })
      .catch(err => {
        console.error("DeleteMiniSuomiCatalogue err", err);
      })
      .finally(() => setShowConfirmModal(false));
    }
  }

  return (
    <Accordion sx={{backgroundColor: catalogueObject.sessionAccountID ? tokens.colors.highlightLight2 : undefined}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container>
          <Grid item xs={8} css={styles.catalogCell}>
            <span>{tp("title", "Catalogue", "name")}: <b>{catalogue.name?.value}</b></span>
          </Grid>
          <Grid item xs={4} css={styles.catalogCellRight}>
            <Button style={{marginRight: tokens.spacing.xs}} onClick={() => navigate(`/catalogues/${id}/edit`)}>{isEditable ? t("common.actions.edit") : t("common.actions.open")}</Button>
            { isEditable && <Button onClick={() => confirmDeleteCatalogue(id)}>{t("common.actions.delete")}</Button> }
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ flexGrow: 1 }}>
          { catalogue.catalogueLine &&
            <Grid container>
              <Grid item xs={12}>
                <span>{tp("title", "Catalogue", "catalogueLine")}</span>
              </Grid>
              <Grid item xs={12}>
                {catalogue.catalogueLine.map((line, index) => 
                  <Accordion key={`catalogueline-${id}-${index}`} style={{marginTop: tokens.spacing.xs, marginBottom: tokens.spacing.xs}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container>
                        <Grid item xs={4} css={styles.catalogCell}>
                          <span>{tp("title", "CatalogueLine", "iD")}: <b>{line.iD.value}</b></span>
                        </Grid>
                        <Grid item xs={4} css={styles.catalogCell}>
                          <span>{tp("title", "CatalogueLine", "item")}: <b>{line.item?.name.value}</b></span>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "right"}}>
                          <Button style={{marginRight: tokens.spacing.xs}} onClick={() => navigate(`/catalogues/${id}/line/${line.iD.value}/edit`)}>{isEditable ? t("common.actions.edit") : t("common.actions.open")}</Button>
                          { isEditable && <Button onClick={() => confirmDeleteCatalogueLine(id, line.iD.value!)}>{t("common.actions.delete")}</Button> }
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ flexGrow: 1 }}>
                        <FlexCol>
                        </FlexCol>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            </Grid>
          }
          { isEditable && 
            <Grid container>
              <Button style={{marginRight: tokens.spacing.xs, marginTop: tokens.spacing.xs}} onClick={() => navigate(`/catalogues/${id}/new-line`)}>{t("app.components.CataloguesView.catalogueLines.add.title")}</Button>
            </Grid>
          }
        </Box>
      </AccordionDetails>

      <ConfirmModal
        isOpen={showConfirmModal}
        heading={confirmHeading}
        message={confirmMessage}
        confirmButtonText={t("common.actions.accept")}
        cancelButtonText={t("common.actions.cancel")}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
      />
    </Accordion>
  );
}

export default CataloguesView;