import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../common/Page';
import { useAppStateContext } from '../../state/AppStateContext';
import PageNotFoundView from './PageNotFoundView';
import { HazardousItemForm, InputWrapper, ItemCertificateForm, ItemCommodityClassificationForm, ItemDimensionForm, ItemInstanceForm, ItemPropertyForm, ItemSpecificationDocumentReferenceForm, TextFieldListForm, ToolTippedCard, TooltippedTextField } from '../common/CustomFormikComponents';
import { JsonObject, JsonObjectRequestData } from '../../model/OmaXTypes';
import { PostCatalogue } from '../../api/OmaYritysApi';
import Card from '../common/Card';
import ConfirmDialog from '../common/ConfirmDialog';
import { GetProductTemplate, Product } from '../../model/AppModels';
import { IdentifierType, NameType } from '../../model/UBL/UnqualifiedDataTypes';
import { ItemIdentification } from '../../model/Peppol/PreAwardCatalogue';
import { hasClassifiedTaxCategory } from '../../utils/peppolUtils';

const SgtinInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{margin: tokens.spacing.xs, maxWidth: "sm"}}>
      <Typography variant="button">{t("app.components.CatalogueProductItemView.sgtinInfo.heading")}</Typography>
      <Typography>{t("app.components.CatalogueProductItemView.sgtinInfo.paragraph1")}</Typography>
      <ul style={{listStylePosition: "outside", paddingLeft: "revert"}}>
        <li>{t("app.components.CatalogueProductItemView.sgtinInfo.bullet1")}<strong>06400001000254</strong></li>
        <li>{t("app.components.CatalogueProductItemView.sgtinInfo.bullet2")}<strong>SGTIN</strong></li>
        <li>{t("app.components.CatalogueProductItemView.sgtinInfo.bullet3")}<strong>10001</strong></li>
      </ul>
      <br/>
      <Typography>{t("app.components.CatalogueProductItemView.sgtinInfo.paragraph2")}<strong>06400001000254-10001</strong></Typography>
    </Box>
  )
}

const TaxCategoryInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{margin: tokens.spacing.xs, maxWidth: "sm"}}>
      <Typography variant="button">{t("app.components.CatalogueProductItemView.taxCategoryInfo.heading")}</Typography>
      <Typography>{t("app.components.CatalogueProductItemView.taxCategoryInfo.info")}</Typography>
      <ul style={{listStylePosition: "outside", paddingLeft: "revert"}}>
        <li>
          <Trans
            i18nKey="app.components.CatalogueProductItemView.taxCategoryInfo.bullet1"
            components={{
              link1: <a href="https://docs.peppol.eu/poacc/billing/3.0/codelist/UNCL5305/" target="_blank" rel="noreferrer">UNCL5305</a>
            }}
          />
        </li>
        <li>{t("app.components.CatalogueProductItemView.taxCategoryInfo.bullet2")}</li>
        <li>{t("app.components.CatalogueProductItemView.taxCategoryInfo.bullet3")}</li>
      </ul>
    </Box>
  )
}

const CatalogueProductItemView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { productId } = useParams<{productId: string}>();
  // console.log("productId", productId);
  const [existingProductObject, setExistingProductObject] = useState<JsonObject<Product>|undefined>(undefined);
  const [existingProduct, setExistingProduct] = useState<Product|undefined>(undefined);
  // Product item is editable if it is a new one, it belongs to a session or the user is an admin
  const isEditable = !existingProduct || !!existingProductObject?.sessionAccountID || appContext.isAdmin();
  const [taxCategoryNotification, setTaxCategoryNotification] = useState<string>();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("app.components.CatalogueProductItemView.cancel.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("app.components.CatalogueProductItemView.cancel.message");

  useEffect(() => {
    if (!existingProduct && productId) {
      const productObject = productId ? appContext.catalogueProducts[productId] : undefined;
      const product = productObject?.jsonData;
      if (product) {
        formik.resetForm({ values: product});
        setExistingProductObject(productObject);
        setExistingProduct(product);
      }
    }
  }, [appContext.catalogueProducts]);

  const submit = async (productToSubmit: Product) => {
    const now = new Date().toISOString();
    if (!productToSubmit.createdOn) {
      productToSubmit.createdOn = now;
    }
    productToSubmit.updatedOn = now;
    console.log("onSubmit", productToSubmit);
    const formData: JsonObjectRequestData<Product> = {
      id: productId ?? undefined,
      dataType: "CatalogueProductItem",
      jsonData: productToSubmit
    };
    const resp = await PostCatalogue(formData);
    console.log("Response", resp);
    navigate("/products");
  }

  const formik = useFormik({
    initialValues: GetProductTemplate(),
    validationSchema: Product.schema.shape({
      name: NameType.schema.shape({
        value: Yup.string().required("required")
      }),
      standardItemIdentification: ItemIdentification.schema.shape({
        iD: IdentifierType.schema.shape({
          value: Yup.string().when("schemeID", ([schemeID], schema) => {
            if (schemeID === "SGTIN") {
              return schema
              .test("gtin", "sgtinGtinLength", val => !!val && val?.length === 14)
              .required("required");
            }
            return schema;
          })
        }),
        extendedID: IdentifierType.schema.when("iD", ([iD], schema) => {
          if (iD?.schemeID === "SGTIN") {
            return schema.shape({
              value: Yup.string()
              .test("serialNumber", "sgtinSerialLength", val => !!val && val?.length >= 1 && val?.length <= 12)
              .test("serialNumber", "numberType", val => !!val && /^\d+$/.test(val))
              .required("required").typeError("numberType")
            })
          }
          return schema;
        })
      })
    }),
    onSubmit: submit
  });

  useEffect(() => {
    if (hasClassifiedTaxCategory(formik.values)) {
      setTaxCategoryNotification(() => undefined);
    }
    else {
      setTaxCategoryNotification(() => "app.components.CatalogueProductItemView.taxCategoryInfo.info");
    }
  }, [formik.values]);

  const onGoBack = () => {
    // Notify the user if the form has changes. Else just go back
    if (formik.dirty) {
      setConfirmHeading("app.components.CatalogueProductItemView.cancel.heading");
      setConfirmMessage("app.components.CatalogueProductItemView.cancel.message");
      setConfirmAction(() => () => navigate(-1));
      setShowConfirmModal(true);
    }
    else {
      navigate(-1);
    }
  }

  // useEffect(() => {
  //   console.log("Formikprops.values", formik.values);
  // }, [formik.values]);
  // useEffect(() => {
  //   console.log("Formikprops.errors", formik.errors);
  // }, [formik.errors]);

  // console.log("Formikprops.values", formik.values);
  // console.log("Formikprops.errors", formik.errors);
  // console.log("Formikprops.touched", formik.touched);

  if (productId && !existingProduct) {
    return (
      <PageNotFoundView
        title={t("app.components.CatalogueProductItemView.pageNotFoundTitle")}
        breadCrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/products", label: "products", current: false},
          { href: "/products/:productId/edit", label: "edit-product", current: true}
        ]}
      >
        <Link to={"/products"}>{t("app.components.CatalogueProductItemView.pageNotFoundLink")}</Link>
      </PageNotFoundView>
    );
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/products", label: "products", current: false},
        ( existingProduct ? 
          { href: "/products/:productId/edit", label: "edit-product", current: true} :
          { href: "/products/new", label: "new-product", current: true}
        )
      ]}
    >
      <Card title={t(`app.components.CatalogueProductItemView.heading-${existingProduct ? "edit" : "new"}`)}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Typography sx={{marginBottom: tokens.spacing.m}}>
            {t("app.components.CatalogueProductItemView.info")}
          </Typography>
          { taxCategoryNotification &&
            <Alert severity="warning" sx={{marginBottom: tokens.spacing.s}}>
              <AlertTitle>{t(`app.components.CatalogueProductItemView.taxCategoryInfo.info`)}</AlertTitle>
            </Alert>
          }
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="name" fieldPath="name.value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="brandName" fieldPath="brandName[0].value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TooltippedTextField fieldName="description" fieldPath="description[0].value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="packQuantity" fieldPath="packQuantity.value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="packSizeNumeric" fieldPath="packSizeNumeric.value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextFieldListForm fieldName="keyword" fieldPath="keyword" memberOfClass="Item" formikProps={formik} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="originCountry" fieldPath="originCountry.identificationCode.value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="manufacturerParty" fieldPath="manufacturerParty[0].partyName[0].name.value" memberOfClass="Item" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TooltippedTextField fieldName="sellersItemIdentification" fieldPath="sellersItemIdentification.iD.value" memberOfClass="Item" formikProps={formik} />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="schemeID" fieldPath="sellersItemIdentification.iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TooltippedTextField fieldName="manufacturersItemIdentification" fieldPath="manufacturersItemIdentification[0].iD.value" memberOfClass="Item" formikProps={formik} />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="schemeID" fieldPath="manufacturersItemIdentification[0].iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <ToolTippedCard fieldName="standardItemIdentification" memberOfClass="Item" popoverI18nKey={"app.components.CatalogueProductItemView.sgtinInfoBtn"} popoverEl={<SgtinInfo/>}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={9}>
                    <InputWrapper>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <TooltippedTextField fieldName="standardItemIdentification" fieldPath="standardItemIdentification.iD.value" memberOfClass="Item" formikProps={formik} />
                        </Grid>
                        <Grid item xs={4}>
                          <TooltippedTextField fieldName="schemeID" fieldPath="standardItemIdentification.iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                        </Grid>
                      </Grid>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputWrapper>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TooltippedTextField fieldName="extendedID" fieldPath="standardItemIdentification.extendedID.value" memberOfClass="ItemIdentification" formikProps={formik} />
                        </Grid>
                      </Grid>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </ToolTippedCard>
            </Grid>
            <Grid item xs={12}>
              <ToolTippedCard fieldName="classifiedTaxCategory" memberOfClass="Item" popoverI18nKey={"app.components.CatalogueProductItemView.taxCategoryInfoBtn"} popoverEl={<TaxCategoryInfo/>}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TooltippedTextField fieldName="iD" fieldPath="classifiedTaxCategory[0].iD.value" memberOfClass="TaxCategory" formikProps={formik} />
                        </Grid>
                        <Grid item xs={6}>
                          <TooltippedTextField fieldName="listID" fieldPath="classifiedTaxCategory[0].iD.listID" memberOfClass="CodeType" formikProps={formik} disabled />
                        </Grid>
                      </Grid>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="percent" fieldPath="classifiedTaxCategory[0].percent.value" memberOfClass="TaxCategory" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="taxScheme" fieldPath="classifiedTaxCategory[0].taxScheme.iD.value" memberOfClass="TaxCategory" formikProps={formik} disabled />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </ToolTippedCard>
            </Grid>
            <Grid item xs={12}>
              <ToolTippedCard fieldName="transactionConditions" memberOfClass="Item">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="actionCode" fieldPath="transactionConditions[0].actionCode.value" memberOfClass="TransactionConditions" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="description" fieldPath="transactionConditions[0].description[0].value" memberOfClass="TransactionConditions" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </ToolTippedCard>
            </Grid>
            <Grid item xs={12}>
              <ItemSpecificationDocumentReferenceForm formikProps={formik}/>
            </Grid>
            <Grid item xs={12}>
              <ItemCommodityClassificationForm formikProps={formik}/>
            </Grid>
            
            <Grid item xs={12}>
              <HazardousItemForm formikProps={formik}/>
            </Grid>
            <Grid item xs={12}>
              <ItemPropertyForm formikProps={formik}/>
            </Grid>
            <Grid item xs={12}>
              <ItemInstanceForm formikProps={formik}/>
            </Grid>
            <Grid item xs={12}>
              <ItemCertificateForm formikProps={formik}/>
            </Grid>
            <Grid item xs={12}>
              <ItemDimensionForm formikProps={formik}/>
            </Grid>
            <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
              <Button
                variant="outlined"
                onClick={onGoBack}
              >
                {t("common.actions.back")}
              </Button>
              { isEditable &&
                <Button variant="contained" type="submit">
                  {t("common.actions.save")}
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </Card>
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Page>
  );
}

export default CatalogueProductItemView;