import {jsx} from '@emotion/react'
import React from "react";
import {FlexCol, FlexRow } from "../../common/CommonStyles";
import TextField from '@mui/material/TextField';
import { ContactDetails } from '../../../model/contactDetails';
import { Expander, ExpanderContent, ExpanderTitleButton } from 'suomifi-ui-components'
import Chip from '@mui/material/Chip';
import PhoneIcon from '@mui/icons-material/Phone';
import AddPhone from '@mui/icons-material/AddIcCall';
import AddIcon from '@mui/icons-material/Add';
import EInvoiceIcon from '@mui/icons-material/Receipt'
import EMailIcon from '@mui/icons-material/Email'
import OperatorIdIcon from '@mui/icons-material/Fingerprint'
import OperatorNameIcon from '@mui/icons-material/Person'
import HomePageIcon from '@mui/icons-material/Home'

import * as _ from "lodash";
import { v4 } from 'uuid';
import { Button, SvgIconTypeMap } from '@mui/material';
import PopupEditor from '../../common/PopupEditor';
import IconButton from '@mui/material/IconButton';
import AddressEditor from './AddressEditor';
import { Address } from '../../../model/address';
import { ActionType } from './actionType';
import { ContactDetailType } from '../../../model/contactDetailType';

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import { Contact } from '../../../model/contact';
import { OverridableComponent } from '@mui/material/OverridableComponent';

class ContactDetailEditor extends React.Component<any, ContactDetails> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
  }

  dialVisible: boolean = false;
  editorOpen: boolean = false;
  deleteOpen: boolean = false;
  editedId?: string = "";

  private actions : ActionType[] = [
    { icon: <AddPhone />, description: 'Puhelinnumero', actionType: ContactDetailType.PhoneContact  },
    { icon: <EInvoiceIcon />, description: 'E-laskuosoite', actionType: ContactDetailType.EInvoice },
    { icon: <EMailIcon />, description: 'Sähköposti',actionType: ContactDetailType.EMail },
    { icon: <OperatorIdIcon />, description: 'Operaattori ID', actionType: ContactDetailType.OperatorId },
    { icon: <OperatorNameIcon />, description: 'Operaattori nimi', actionType: ContactDetailType.OperatorName },
    { icon: <HomePageIcon />, description: 'Kotisivu', actionType: ContactDetailType.Homepage },
  ];

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  deleteAddress(addressId: string) {
    var address = this.state.Addresses.find(a => a.Id === addressId);
    if (address !== undefined) {
      var index = this.state.Addresses.indexOf(address);
      if (index >= 0) {
        var change = {} as any;
        change.Addresses = this.state.Addresses;
        change.Addresses.splice(index, 1);
        this.setState(change);
      }
    }
  }

  SaveContact(type: ActionType) {
    this.editorOpen = false;
    
    if (this.editedId != "") {
      var item = this.state.Contacts.find(p => p.Id === this.editedId);
      this.editedId = "";
      if (item !== undefined) {
        item.Value = this.state.CurrentValue;
      }
    }
    else
    {
      var p = new Contact();
      p.Id = v4();
      p.Value = this.state.CurrentValue;
      p.ContactType = type.actionType;
      this.state.Contacts.push(p);
      this.state.Contacts.sort((a,b) => {
        if (a.ContactType === undefined || b.ContactType === undefined) 
          return 0;
        return a.ContactType - b.ContactType;
      });
    }

    this.setState({ CurrentValue: "" });
  }

  Cancel() {
    this.editedId = "";
    this.deleteOpen = this.editorOpen = false;
    var change = {} as any;
    change.CurrentValue = "";
    this.setState(change);
  }
  
  addNewAddress() {
    var change = this.state;
    var address = new Address();
    address.Id = v4();
    change.Addresses.push(address);
    this.setState(change);
  }

  handleDelete(item: Contact) {
    var item = this.state.Contacts.find(t => t.Id == item.Id) as Contact;

    if (item !== null){
      var index = this.state.Contacts.indexOf(item);

      if (index >= 0) {
        this.editedId = item.Id;
        var change = {} as any;
        change.CurrentValue = item.Value;
        var action = this.actions.find(a => a.actionType == item.ContactType);
        if (action !== undefined) {
          change.currentType = action;
        }
        this.deleteOpen = true;
        this.setState(change);
      }
    }
  };

  delete() {
    var item = this.state.Contacts.find(t => t.Id == this.editedId) as Contact;

    if (item !== null){
      var index = this.state.Contacts.indexOf(item);

      if (index >= 0) {
        this.state.Contacts.splice(index, 1);
        var change = {} as any
        change.Contacts = this.state.Contacts;
       
        this.setState(change);
      }
    }

    this.Cancel();
  }

  handleClick(item: Contact) {
    this.editorOpen = true;
    var change = {} as any;
    change.CurrentValue = item.Value;
    var action = this.actions.find(a => a.actionType == item.ContactType);
    change.currentType = action;
    this.setState(change);
    this.editedId = item.Id;
  };

  handleClose() {
    this.dialVisible = false;
    this.setState({});
  };
  
  selectOption(param: any) {
    this.editorOpen = true;
   var s = {} as any
    s.currentType = param;
    s.editorOpen = true;
    this.setState(s);
  }

  handleOpen() {
    this.dialVisible = true;
    this.setState({});
  };

  getIcon(contactType?: ContactDetailType) : OverridableComponent<SvgIconTypeMap> {
    switch (contactType)
    {
      case ContactDetailType.EInvoice:
        return <EInvoiceIcon/> as any;
      case ContactDetailType.EMail:
        return <EMailIcon/> as any;
      case ContactDetailType.Homepage:
        return <HomePageIcon/> as any;
      case ContactDetailType.OperatorId:
        return <OperatorIdIcon/> as any;
      case ContactDetailType.OperatorName:
        return <OperatorNameIcon/> as any;
      case ContactDetailType.PhoneContact:
        return <PhoneIcon /> as any;
    }
     
    return undefined as any;
  }

  currentType(): string {
    if ((this.state as any).currentType === undefined) {
      return "";
    }

    var type = (this.state as any).currentType as ActionType;
    return type.description;
  }
 
  render() {
    
      return (
        <>
      <FlexCol>
        <Expander className="expander-test" defaultOpen={true}>
          <ExpanderTitleButton asHeading="h3">
            Yhteystiedot
          </ExpanderTitleButton>
          <ExpanderContent>
                <FlexRow>
                    <SpeedDial
                    ariaLabel="Yhteystietojen lisäys"
                    className="speedDial"
                    icon={<SpeedDialIcon/>}
                    onClose={this.handleClose.bind(this)}
                    onOpen={this.handleOpen.bind(this)}
                    open={this.dialVisible}
                    direction='right'
                    >
                    {this.actions.map((action) => (
                      <SpeedDialAction
                        key={action.description}
                        icon={action.icon}
                        tooltipTitle={action.description}
                        itemType={action.actionType as any}
                        onClick={() => this.selectOption(action)}
                      />
                    ))}
                  </SpeedDial>

                  </FlexRow>

                <div style={{padding: 10 }}>
                  {
                    this.state.Contacts.map((item: any) => (
                      <Chip
                        icon={this.getIcon(item.ContactType) as any}
                        label={item.Value}
                        onClick={() => this.handleClick(item)}
                        onDelete={() => this.handleDelete(item)}
                        variant="outlined"
                      />
                      ))
                    }
                  <PopupEditor title={"Syötä " + this.currentType().toLowerCase()} isOpen={this.editorOpen}>
                    <table>
                    <tr>
                      <TextField name='CurrentValue' label={this.currentType()} value={this.state.CurrentValue } onChange={this.handleChange.bind(this)} variant="outlined" />
                    </tr>
                    <tr>
                      <td>
                        <Button onClick={() => this.SaveContact((this.state as any).currentType)} color="primary">Tallenna</Button>
                      </td>
                      <td>
                      <Button color="secondary" onClick={() => this.Cancel()}>Peruuta</Button>
                      </td>
                    </tr>
                    </table>
                  </PopupEditor>
                  
                  <PopupEditor title={"Poistetaanko " + this.currentType().toLowerCase() + " " + this.state.CurrentValue + "?"} isOpen={this.deleteOpen}>
                      <table>
                      <tr>
                        <td>
                        <Button onClick={() => this.delete()} color="primary">Kyllä</Button>
                        </td>
                        <td>
                        <Button color="secondary" onClick={() => this.Cancel()}>Peruuta</Button>
                        </td>
                      </tr>

                      </table>
                  </PopupEditor>
                  
              </div>
              

              </ExpanderContent>
              </Expander>
              </FlexCol>
          <FlexCol>
              <Expander className="expander-test" defaultOpen={true}>
          <ExpanderTitleButton asHeading="h3">
            Osoitetiedot
          </ExpanderTitleButton>
          <ExpanderContent>
          <FlexRow>
                    <IconButton aria-label="add"  onClick={() => this.addNewAddress()}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                  </FlexRow>
              {this.state.Addresses.map(addr => 
                  <AddressEditor data={addr} deleteAddress={this.deleteAddress.bind(this)}></AddressEditor>
                )}
          </ExpanderContent>
        </Expander>
      </FlexCol>
            </>
      );
  }
}

export default ContactDetailEditor;
