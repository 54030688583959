import React, { PropsWithChildren, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import XMLViewer from "react-xml-viewer";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Buffer } from "buffer";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { Invoice, InvoiceType, PeppolInvoiceAttachment } from '../../model/AppModels';
import { Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, IconButton, Switch, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { SBDStatus } from '../../model/OmaXTypes';
import { useAppStateContext } from '../../state/AppStateContext';

interface Props {
  invoice?: Invoice,
  stylesheet: string,
  handleClose: () => void,
  postInvoice: () => void,
  importInvoice?: () => void,
  acceptInvoice?: () => void,
  sendInvoice?: () => void
}

  /**
   * @param invoice
   * - Dialog will open when invoice is not undefined.
   * @param stylesheet
   * - Stylesheet to render invoice with SaxonJS.
   * @param handleClose
   * - Action that will be triggered when the dialog should be closed.
   * @param importProducts
   * - Optional function to import products from the invoice. If the parameter is undefined, the import button is hidden.
   */
const InvoiceDocumentDialog: React.FC<Props> = ({invoice, stylesheet, handleClose, postInvoice, importInvoice, acceptInvoice, sendInvoice}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [xml, setXml] = useState<string>("");
  const [invoiceAttachments, setInvoiceAttachments] = useState<PeppolInvoiceAttachment[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [visualize, setVisualize] = useState<boolean>(true);
  const [receiverName, setReceiverName] = useState<string|undefined>(undefined);
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    let xmlD = "";
    setXml(() => "");
    setInvoiceAttachments(() => []);
    setSelectedTab(() => 0);
    setVisualize(() => true);
    if (invoice?.type === InvoiceType.PEPPOL) {
      OmaYritysApi.GetPeppolDocumentContent(invoice.document.id)
      .then(res => {
        xmlD = res.data;
        setXml(() => res.data);
        getAttachments(res.data);
      });
    }
    if (invoice?.type === InvoiceType.FINVOICE) {
      OmaYritysApi.GetFinvoiceDocumentContent(invoice.document.id)
      .then(res => {
        xmlD = res.data;
        setXml(() => res.data);
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice && invoice.type == InvoiceType.PEPPOL) {
      appContext.searchParticipantAsync(invoice.document.receiverId)
      .then(name => {
        if (name) {
          setReceiverName(() => name);
        }
      });
    }
  }, [invoice]);

  const getAttachments = (xmlString: string) => {
    try {
      const xmlParser = new DOMParser();
      const xmlDoc = xmlParser.parseFromString(xmlString, "text/xml");
      // Xml should contain one Invoice element
      const invoice = xmlDoc.getElementsByTagName("Invoice").item(0);
      // Invoice may contain multiple AdditionalDocumentReferences
      const additionalDocumentReferences = invoice?.getElementsByTagName("cac:AdditionalDocumentReference");
      const attachments: PeppolInvoiceAttachment[] = [];
      if (additionalDocumentReferences) {
        for (let i = 0; i < additionalDocumentReferences.length; i++) {
          const referenceDoc = additionalDocumentReferences.item(i)!;
          const id = referenceDoc.getElementsByTagName("cbc:ID")?.item(0)?.textContent;
          const typeCode = referenceDoc.getElementsByTagName("cbc:DocumentTypeCode")?.item(0)?.textContent;
          const description = referenceDoc.getElementsByTagName("cbc:DocumentDescription")?.item(0)?.textContent;
          const attachmentDoc = referenceDoc.getElementsByTagName("cac:Attachment")?.item(0);
          const binaryObjectDoc = attachmentDoc?.getElementsByTagName("cbc:EmbeddedDocumentBinaryObject")?.item(0);
          const mimeCode = binaryObjectDoc?.getAttribute("mimeCode");
          const filename = binaryObjectDoc?.getAttribute("filename");
          const content = binaryObjectDoc?.textContent;
          const attachment: PeppolInvoiceAttachment = { id, typeCode, description, mimeCode, filename, content };
          attachments.push(attachment);
        }
      }
      setInvoiceAttachments(() => attachments);
    }
    catch (ex) {
      console.error("Error when reading attachments", ex);
    }
  }

  const onAction = (action: () => void) => {
    action();
  }

  const isEditable = (i: Invoice) => {
    return i.document.sessionAccountID || appContext.isAdmin();
  }

  return (
    <Dialog open={!!invoice} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <TabPanel index={0} value={selectedTab}>
          {!xml &&
            <p>{t("app.components.InvoiceDocumentModal.loading")}</p>
          }
          {xml &&
            <div style={{width: "100%", height: "100%"}}>
              <FormGroup sx={{position: "absolute", right: "45px", top: "30px", paddingX: tokens.spacing.xs, backgroundColor: tokens.colors.depthLight1, opacity: 0.8, borderRadius: "30px"}}>
                <FormControlLabel 
                  control={<Switch checked={visualize} 
                  onChange={() => setVisualize(old => !old)} />} 
                  label={t("common.actions.visualize")} 
                  labelPlacement="start" 
                />
              </FormGroup>
              <iframe style={{display: visualize ? "block" : "none"}} width="99%" height="98%" srcDoc={`
                <script src="/js/SaxonJS2.js"></script>
                <script>
                  window.onload = async function() {
                    var output = await SaxonJS.transform({
                        stylesheetLocation: "/stylesheets/${stylesheet}",
                        sourceText: \`${xml}\`,
                        destination: "replaceBody"
                    }, "async");
                  }     
                </script>
              `}/>
              <div style={{display: visualize ? "none": "block", borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}>
                <XMLViewer xml={xml} collapsible />
              </div>
            </div>
          }
        </TabPanel>
        { invoiceAttachments.map((ia, iaIndex) => 
          <TabPanel key={`invoice-attach-tabpanel q-${iaIndex}`} index={iaIndex+1} value={selectedTab}>
            <div style={{borderWidth: "2px", borderStyle: "inset", padding: tokens.spacing.xs}}>
              { ia.mimeCode === "application/xml" && <XMLViewer xml={Buffer.from(ia.content, "base64")} collapsible />}
              { ia.mimeCode !== "application/xml" && Buffer.from(ia.content, "base64")}
            </div>
          </TabPanel>
        )}
        { invoiceAttachments.length > 0 && 
          <Tabs variant="scrollable" value={selectedTab} onChange={handleTabChange} sx={{backgroundColor: tokens.colors.depthLight2}}>
            <Tab label="Invoice" />
            {invoiceAttachments.map((ia, iaIndex) => 
              <Tab key={`invoice-attach-tab-${iaIndex}`} label={ia.filename} />
            )}
          </Tabs>
        }
        <Grid container sx={{marginTop: tokens.spacing.s}} spacing={1}>
          { !!acceptInvoice && invoice && isEditable(invoice) &&
            invoice.type === InvoiceType.PEPPOL &&
            invoice.document.status === SBDStatus.RECEIVED &&
            <Grid item>
              <Button variant="outlined" onClick={() => onAction(acceptInvoice)}>{t("app.components.InvoiceDocumentModal.acceptInvoiceBtn")}</Button>
            </Grid>
          }
          { !!sendInvoice && invoice && isEditable(invoice) &&
            invoice.type === InvoiceType.PEPPOL &&
            invoice.document.status === SBDStatus.CREATED &&
            <Grid item>
              <Button variant="outlined" onClick={() => onAction(sendInvoice)}>{t("app.components.InvoiceDocumentModal.sendInvoiceBtn")}</Button>
            </Grid>
          }
          { !!importInvoice && invoice &&
            invoice.type === InvoiceType.PEPPOL &&
            [SBDStatus.ACCEPTED, SBDStatus.PAID].includes(invoice.document?.status) &&
            <Grid item>
              <Button variant="outlined" onClick={() => onAction(importInvoice)}>{t("app.components.InvoiceDocumentModal.importItemsBtn")}</Button>
            </Grid>
          }
          { invoice && isEditable(invoice) &&
            invoice.type === InvoiceType.PEPPOL &&
            [SBDStatus.ACCEPTED, SBDStatus.PAID, SBDStatus.SENDING, SBDStatus.SENT].includes(invoice.document?.status) &&
            invoice.document.accountingNumber === undefined &&
            <Grid item>
              <Button variant="outlined" onClick={() => onAction(postInvoice)}>{t("app.components.InvoiceDocumentModal.postInvoice")}</Button>
            </Grid>
          }
        </Grid>
        { invoice?.type === InvoiceType.PEPPOL && invoice.document.status === SBDStatus.SENDING &&
          <Box sx={{marginTop: tokens.spacing.s}}>
            <Typography>
              <Trans
                i18nKey="app.components.SendInvoiceDialog.copyInvoiceIdInfo"
                values={{receiverName}}
                components={{
                  bold: <strong/>,
                  italic: <i/>
                }}
              />
            </Typography>
            <Grid container>
              <Grid item xs={true}>
                <TextField
                  fullWidth
                  value={invoice?.document.id}
                />
              </Grid>
              <Grid item xs="auto" sx={{display: "flex", alignItems: "center"}}>
                <Tooltip disableFocusListener disableTouchListener title={t("common.actions.copyToClipboard")} placement='top-start'>
                  <IconButton onClick={() => navigator.clipboard.writeText(invoice?.document.id ?? "")}>
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        }
      </DialogContent>
      <DialogActions sx={{display: "flex"}}>
        <Button variant="contained" onClick={handleClose}>{t("common.actions.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

const TabPanel: React.FC<PropsWithChildren<{index: number, value: number}>> = ({index, value, children}) => {
  return (
    <Box id={`invoice-dialog-panel-${index}`} hidden={value !== index} sx={{height: "60vh", overflow: "auto", fontSize: "0.75em"}}>
      {children}
    </Box>
  )
}

export default InvoiceDocumentDialog;