import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { Invoice } from '../../model/AppModels';
import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { useAppStateContext } from '../../state/AppStateContext';

interface Props {
  invoice?: Invoice,
  handleClose: () => void
}

const SendInvoiceDialog: React.FC<Props> = ({invoice, handleClose}) => {
  const appContext = useAppStateContext();
  const { i18n, t } = useTranslation(["translation"]);
  const [sending, setSending] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>(undefined);
  const [receiverName, setReceiverName] = useState<string|undefined>(undefined);

  const submit = async () => {
    setSending(() => true);
    setNotification(() => undefined);
    if (invoice) {
      try {
        const res = await OmaYritysApi.SendPeppolInvoice(invoice.document.id);
        if (res.data) {
          appContext.getPeppolDocumentsAsync();
        }
        setSending(() => false);
        setNotification(() => "success");
      }
      catch(err) {
        console.error("Error while sending an invoice", err);
        setSending(() => false);
        setNotification(() => "error");
      }
    }
  }

  useEffect(() => {
    setSending(() => false);
    setNotification(() => undefined);
    setReceiverName(() => undefined);
    if (invoice) {
      appContext.searchParticipantAsync(invoice.document.receiverId)
      .then(name => {
        if (name) {
          setReceiverName(() => name);
        }
      });
    }
  }, [invoice]);

  return (
    <Dialog open={!!invoice} onClose={handleClose} fullWidth>
      <DialogTitle>
        {t("app.components.SendInvoiceDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{marginBottom: tokens.spacing.s}}>{t("app.components.SendInvoiceDialog.info")}</Typography>
        { notification &&
          <Alert severity={notification === "success" ? "success" : "error"}>
            <AlertTitle>{t(`app.components.SendInvoiceDialog.${notification}Title`)}</AlertTitle>
          </Alert>
        }
        { sending && 
          <Alert severity="info" icon={false}>
            <AlertTitle sx={{display: "flex", alignItems: "center"}}>
              <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
              {t(`app.components.SendInvoiceDialog.sendingInvoice`)}
            </AlertTitle>
          </Alert>
        }
        { notification === "success" &&
          <Box sx={{marginTop: tokens.spacing.s}}>
            <Typography>
              <Trans
                i18nKey="app.components.SendInvoiceDialog.copyInvoiceIdInfo"
                values={{receiverName}}
                components={{
                  bold: <strong/>,
                  italic: <i/>
                }}
              />
            </Typography>
            <Grid container>
              <Grid item xs={true}>
                <TextField
                  fullWidth
                  value={invoice?.document.id}
                />
              </Grid>
              <Grid item xs="auto" sx={{display: "flex", alignItems: "center"}}>
                <Tooltip disableFocusListener disableTouchListener title={t("common.actions.copyToClipboard")} placement='top-start'>
                  <IconButton onClick={() => navigator.clipboard.writeText(invoice?.document.id ?? "")}>
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        }
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={sending}
        >
          { notification ? t("common.actions.close") : t("common.actions.cancel") }
        </Button>
        <Button
          variant="contained"
          onClick={submit}
          disabled={sending || notification === "success"}
        >
          {t(`common.actions.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendInvoiceDialog;