/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../common/Page';
import { useAppStateContext } from '../../state/AppStateContext';
import PageNotFoundView from './PageNotFoundView';
import { InputWrapper, QuantityForm, ToolTippedCard, TooltippedSelect, TooltippedSwitch, TooltippedTextField } from '../common/CustomFormikComponents';
import { ItemComparisonForm, CatalogueLineRelatedItemForm, RequiredItemLocationQuantityForm } from './CatalogueLineFormComponents';
import { JsonObject, JsonObjectRequestData } from '../../model/OmaXTypes';
import { PostCatalogue } from '../../api/OmaYritysApi';
import { Catalogue, CatalogueLine, GetCatalogueLineTemplate } from '../../model/Peppol/PreAwardCatalogue';
import Card from '../common/Card';
import ConfirmDialog from '../common/ConfirmDialog';

const CatalogueLineView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { catalogueId, catalogueLineId } = useParams<{catalogueId: string, catalogueLineId: string}>();
  // console.log("catalogueId", catalogueId);
  // console.log("catalogueLineId", catalogueLineId);
  const [existingCatalogueObject, setExistingCatalogueObject] = useState<JsonObject<Catalogue>|undefined>(undefined);
  const [existingCatalogue, setExistingCatalogue] = useState<Catalogue|undefined>(undefined);
  const [existingLineIndex, setExistingLineIndex] = useState<number|undefined>(undefined);
  const [existingLine, setExistingLine] = useState<CatalogueLine|undefined>(undefined);
  // Catalogue row is editable if it belongs to a session or the user is an admin
  const isEditable = !!existingCatalogueObject?.sessionAccountID || appContext.isAdmin();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("app.components.CatalogueLineView.cancel.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("app.components.CatalogueLineView.cancel.message");

  useEffect(() => {
    if (!existingCatalogue && catalogueId) {
      const catalogueObject = catalogueId ? appContext.catalogues[catalogueId] : undefined;
      const catalogue = catalogueObject?.jsonData;
      if (catalogue) {
        setExistingCatalogueObject(catalogueObject);
        setExistingCatalogue(catalogue);
      }
      const lineIndex = catalogue?.catalogueLine?.findIndex(line => line.iD.value === catalogueLineId);
      console.log("Existing lineIndex", lineIndex);
      const line = lineIndex !== undefined && lineIndex !== -1 ? catalogue?.catalogueLine?.at(lineIndex) : undefined;
      if (line) {
        console.log(`Reset line form with existing line at index ${lineIndex}`);
        formik.resetForm({ values: line});
        setExistingLineIndex(lineIndex);
        setExistingLine(line);
      }
      else if (catalogue) {
        const newLineIndex = catalogue.catalogueLine?.length.toString() ?? "0";
        console.log(`No existing line, adding a new line with index ${newLineIndex}`);
        formik.resetForm({ values: GetCatalogueLineTemplate(newLineIndex)});
      }
    }
  }, [appContext.catalogues]);

  const submit = async (values: CatalogueLine) => {
    console.log("onSubmit", values);
    // Add a new line to the catalogue or update an existing line in the catalogue
    // and save the whole catalogue.
    const catalogueToUpdate: Catalogue = {...existingCatalogue!};
    if (!catalogueToUpdate.catalogueLine || catalogueToUpdate.catalogueLine.length === 0) {
      console.log("No previous catalogue lines");
      catalogueToUpdate.catalogueLine = [values];
    }
    else if (existingLineIndex !== undefined && existingLine) {
      console.log(`Updating existing catalogue line from index ${existingLineIndex}`, existingLine);
      catalogueToUpdate.catalogueLine[existingLineIndex] = values;
    }
    else {
      console.log("Adding a new catalogue line to the catalogue");
      catalogueToUpdate.catalogueLine = [...catalogueToUpdate.catalogueLine, values];
    }
    const formData: JsonObjectRequestData<Catalogue> = {
      id: catalogueId ?? undefined,
      dataType: "Catalogue",
      jsonData: catalogueToUpdate
    };
    const resp = await PostCatalogue(formData);
    console.log("Response", resp);
    navigate("/catalogues");
  }

  const formik = useFormik({
    initialValues: GetCatalogueLineTemplate(existingCatalogue?.catalogueLine?.length.toString() ?? "0"),
    validationSchema: CatalogueLine.schema,
    onSubmit: submit
  });

  const onGoBack = () => {
    // Notify the user if the form has changes. Else just go back
    if (formik.dirty) {
      setConfirmHeading("app.components.CatalogueLineView.cancel.heading");
      setConfirmMessage("app.components.CatalogueLineView.cancel.message");
      setConfirmAction(() => () => navigate(-1));
      setShowConfirmModal(true);
    }
    else {
      navigate(-1);
    }
  }

  const availableProductItems = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {"Select product item"}
    </MenuItem>];
    return empty.concat(Object.entries(appContext.catalogueProducts).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value.jsonData.name.value}
        </MenuItem>
      )
    ));
  }

  // console.log("Formikprops.values", formik.values);
  // console.log("Formikprops.errors", formik.errors);
  // console.log("Formikprops.touched", formik.touched);

  if ((catalogueId && !existingCatalogue) || (catalogueLineId && !existingLine)) {
    return (
      <PageNotFoundView
        title={t("app.components.CatalogueLineView.pageNotFoundTitle")}
        breadCrumbs={[
          { href: "/", label: "frontpage", current: false},
          { href: "/catalogues", label: "catalogues", current: false},
          { href: "/catalogues/:catalogueId/line/:catalogueLineId/edit", label: "edit-catalogueline", current: true}
        ]}
      >
        <Link to={"/products"}>{t("app.components.CatalogueLineView.pageNotFoundLink")}</Link>
      </PageNotFoundView>
    );
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/catalogues", label: "catalogues", current: false},
        ( existingLine ? 
          { href: "/catalogues/:catalogueId/line/:catalogueLineId/edit", label: "edit-catalogueline", current: true} :
          { href: "/catalogues/:catalogueId/new-line", label: "new-catalogueline", current: true}
        )
      ]}
    >
      <Card title={t(`app.components.CatalogueLineView.heading-${existingLine ? "edit" : "new"}`)}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Typography sx={{marginBottom: tokens.spacing.m}}>
            {t("app.components.CatalogueLineView.info")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <InputWrapper>
                <TooltippedTextField fieldName="iD" fieldPath="iD.value" memberOfClass="CatalogueLine" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={6} sm={6} sx={{display: "flex"}}>
              <InputWrapper>
                <TooltippedSwitch fieldName="orderableIndicator" fieldPath={`orderableIndicator.value`} memberOfClass="CatalogueLine" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <QuantityForm fieldName="contentUnitQuantity" fieldPath="contentUnitQuantity" memberOfClass="CatalogueLine" formikProps={formik} required />
            </Grid>
            <Grid item xs={6} md={3}>
              <InputWrapper>
                <TooltippedTextField fieldName="orderableUnit" fieldPath={`orderableUnit.value`} memberOfClass="CatalogueLine" formikProps={formik} fieldType="number" />
              </InputWrapper>
            </Grid>
            <Grid item xs={6} md={3}>
              <InputWrapper>
                <TooltippedTextField fieldName="orderQuantityIncrementNumeric" fieldPath={`orderQuantityIncrementNumeric.value`} memberOfClass="CatalogueLine" formikProps={formik} fieldType="number" />
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <QuantityForm fieldName="minimumOrderQuantity" fieldPath="minimumOrderQuantity" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12} md={6}>
              <QuantityForm fieldName="maximumOrderQuantity" fieldPath="maximumOrderQuantity" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TooltippedTextField fieldName="contractSubdivision" fieldPath={`contractSubdivision.value`} memberOfClass="CatalogueLine" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TooltippedTextField fieldName="warrantyInformation" fieldPath={`warrantyInformation[0].value`} memberOfClass="CatalogueLine" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TooltippedTextField fieldName="packLevelCode" fieldPath="packLevelCode.value" memberOfClass="CatalogueLine" formikProps={formik} />
                  </Grid>
                  <Grid item xs={4}>
                    <TooltippedTextField fieldName="listID" fieldPath="contentUnitQuantity.listID" memberOfClass="CodeType" formikProps={formik} />
                  </Grid>
                </Grid>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <TooltippedTextField fieldName="callForTendersLineReference" fieldPath={`callForTendersLineReference.iD.value`} memberOfClass="CatalogueLine" formikProps={formik} />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <ToolTippedCard fieldName="lineValidityPeriod" memberOfClass="CatalogueLine">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="startDate" fieldPath="lineValidityPeriod.startDate.valueString" memberOfClass="Period" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper>
                      <TooltippedTextField fieldName="endDate" fieldPath="lineValidityPeriod.endDate.valueString" memberOfClass="Period" formikProps={formik} />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </ToolTippedCard>
            </Grid>
            <Grid item xs={12}>
              <ItemComparisonForm fieldName="itemComparison" fieldPath="itemComparison" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <CatalogueLineRelatedItemForm fieldName="componentRelatedItem" fieldPath="componentRelatedItem" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <CatalogueLineRelatedItemForm fieldName="requiredRelatedItem" fieldPath="requiredRelatedItem" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <CatalogueLineRelatedItemForm fieldName="complementaryRelatedItem" fieldPath="complementaryRelatedItem" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <CatalogueLineRelatedItemForm fieldName="replacedRelatedItem" fieldPath="replacedRelatedItem" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <RequiredItemLocationQuantityForm fieldName="requiredItemLocationQuantity" fieldPath="requiredItemLocationQuantity" memberOfClass="CatalogueLine" formikProps={formik} />
            </Grid>
            <Grid item xs={12}>
              <InputWrapper>
                <Box display="flex" sx={{"& > *": {flex: 1}}}>
                  <TooltippedSelect fieldName="itemId" fieldPath="itemId" memberOfClass="CatalogueLine" availableValues={availableProductItems()} formikProps={formik} />
                </Box>
              </InputWrapper>
            </Grid>
            <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
              <Button
                variant="outlined"
                onClick={onGoBack}
              >
                {t("common.actions.back")}
              </Button>
              { isEditable &&
                <Button variant="contained" type="submit">
                  {t("common.actions.save")}
                </Button>
              }
            </Grid>
          </Grid>
        </form>
      </Card>
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Page>
  );
}

export default CatalogueLineView;