import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface Props {
  open: boolean,
  handleClose: () => void,
  confirmAction: () => void,
  cancelAction: () => void
}

const ConfirmDeleteSessionDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        {t("app.header.confirmDeleteSessionTitle")}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t("app.header.confirmDeleteSessionContentText")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{justifyContent: "space-between"}}>
        <Button variant="contained" color="secondary" onClick={props.cancelAction}>{t("common.actions.cancel")}</Button>
        <Button variant="contained" color="primary" onClick={props.confirmAction}>{t("app.header.confirmDeleteBtn")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteSessionDialog;