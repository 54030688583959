import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useAppStateContext } from '../../state/AppStateContext';

interface Props {
  open: boolean,
  handleClose: () => void
}

interface FormData {
  invoiceId: string|undefined
}

const ReceiveInvoiceDialog: React.FC<Props> = ({open, handleClose}) => {
  const appContext = useAppStateContext();
  const { t } = useTranslation();
  const [importing, setImporting] = useState<boolean>(false);
  const [notification, setNotification] = useState<string|undefined>();

  const submit = async (formData: FormData) => {
    setImporting(() => true);
    setNotification(() => undefined);
    if (formData.invoiceId) {
      try {
        const res = await OmaYritysApi.ReceivePeppolInvoice(formData.invoiceId);
        if (res.data) {
          appContext.getPeppolDocumentsAsync();
        }
        setImporting(() => false);
        setNotification(() => "success");
      }
      catch(err) {
        console.error("Error while receiving an invoice", err);
        setImporting(() => false);
        setNotification(() => "error");
      }
    }
  }

  const formik = useFormik({
    initialValues: {invoiceId : ""} as FormData,
    validationSchema: Yup.object({
      invoiceId: Yup.string().required("required")
    }),
    onSubmit: submit
  });

  useEffect(() => {
    setImporting(() => false);
    setNotification(() => undefined);
    if (open) {
      formik.resetForm({values: {invoiceId: ""}});
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {t("app.components.ReceiveInvoiceDialog.title")}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{marginBottom: tokens.spacing.s}}>{t("app.components.ReceiveInvoiceDialog.info")}</Typography>
          { notification &&
            <Alert severity={notification === "success" ? "success" : "error"}>
              <AlertTitle>{t(`app.components.ReceiveInvoiceDialog.${notification}Title`)}</AlertTitle>
            </Alert>
          }
          { importing && 
            <Alert severity="info" icon={false}>
              <AlertTitle sx={{display: "flex", alignItems: "center"}}>
                <CircularProgress sx={{marginRight: tokens.spacing.s}}/>
                {t(`app.components.ReceiveInvoiceDialog.receivingInvoice`)}
              </AlertTitle>
            </Alert>
          }
          <TextField
            sx={{display: "flex", marginTop: tokens.spacing.s}}
            name="invoiceId"
            label={t("app.components.ReceiveInvoiceDialog.invoiceId")}
            value={formik.values.invoiceId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.invoiceId && Boolean(formik.errors.invoiceId)}
            helperText={formik.touched.invoiceId && Boolean(formik.errors.invoiceId) && t(`common.validation.${formik.errors.invoiceId}`)}
            variant="outlined"
            required
          />
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={importing}
          >
            { notification ? t("common.actions.close") : t("common.actions.cancel") }
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={importing || notification === "success"}
          >
            {t(`common.actions.confirm`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ReceiveInvoiceDialog;