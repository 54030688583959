import { Invoice, InvoiceType, Product } from '../model/AppModels';
import { JsonObject } from '../model/OmaXTypes';

export const getLongPeppolParticipantID = (companyCode?: string): string => {
  return "iso6523-actorid-upis::" + getShortPeppolParticipantID(companyCode);
}

export const getShortPeppolParticipantID = (companyCode?: string): string => {
  return `0216:0037${companyCode?.replace("-", "")}00100`;
}

export const getShortParticipantID = (fullParticipantID: string): string => {
  if (fullParticipantID.includes("::")) {
    return fullParticipantID.split("::")[1];
  }
  return fullParticipantID;
}

export const getInvoiceTotalAmount = (pi: Invoice): string => {
  if (pi.type === InvoiceType.PEPPOL) {
    const invoice = pi.document.document["Invoice"];
    const total = invoice["cac:LegalMonetaryTotal"]["cbc:TaxInclusiveAmount"]["#text"];
    return `${total} €`
  }
  
  return "virhe";
}

export const getStandardItemIdentificationAsEPC = (productObject?: JsonObject<Product>) => {
  const schemaID = productObject?.jsonData.standardItemIdentification?.iD.schemeID;
  if (schemaID === "SGTIN") {
    const gtin = productObject?.jsonData.standardItemIdentification?.iD.value;
    const serialNumber = productObject?.jsonData.standardItemIdentification?.extendedID?.value;
    return gtin + "-" + serialNumber;
  }
  return undefined;
}

export const hasClassifiedTaxCategory = (product?: Product) => {
  const taxCategory = product?.classifiedTaxCategory ? product?.classifiedTaxCategory[0] : undefined;
  const id = taxCategory?.iD?.value;
  const percent = taxCategory?.percent?.value;
  return Boolean(id && percent);
}

export const isSender = (invoice?: Invoice, companyCode?: string): boolean => {
  return invoice?.document.senderId === getLongPeppolParticipantID(companyCode);
}

export const isReceiver = (invoice?: Invoice, companyCode?: string): boolean => {
  return invoice?.document.receiverId === getLongPeppolParticipantID(companyCode);
}
