import log from "loglevel";

/* tslint:disable:no-unused-variable */
let primitives = [
  "string",
  "boolean",
  "double",
  "integer",
  "long",
  "float",
  "number",
  "any"
];

export class ObjectSerializer {

  readonly logger: log.Logger;
  readonly logsEnabled: boolean = false;
  readonly typesMap: { [index: string]: any };
  readonly enumsMap: { [index: string]: any };

  private log(...args: any[]): void {
    if (this.logsEnabled) {
      this.logger.debug(...args);
    }
  }

  constructor(typesMap: { [index: string]: any }, enumsMap: { [index: string]: any }) {
    this.logger = log.getLogger(ObjectSerializer.name);
    this.typesMap = typesMap;
    this.enumsMap = enumsMap;
  }

  public findCorrectType(data: any, expectedType: string) {
    if (data === undefined) {
      return expectedType;
    } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
      return expectedType;
    } else if (expectedType === "Date") {
      return expectedType;
    } else {
      if (this.enumsMap[expectedType]) {
        return expectedType;
      }

      if (!this.typesMap[expectedType]) {
        return expectedType; // w/e we don't know the type
      }

      // Check the discriminator
      let discriminatorProperty = this.typesMap[expectedType].discriminator;
      if (discriminatorProperty == null) {
        return expectedType; // the type does not have a discriminator. use it.
      } else {
        if (data[discriminatorProperty]) {
          return data[discriminatorProperty]; // use the type given in the discriminator
        } else {
          return expectedType; // discriminator was not present (or an empty string)
        }
      }
    }
  }

  public serialize(data: any, type: string) {
    if (data === undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index in data) {
        let date = data[index];
        transformedData.push(this.serialize(date, subType));
      }
      return transformedData;
    } else if (type === "Date") {
      return data.toString();
    } else {
      if (this.enumsMap[type]) {
        return data;
      }
      if (!this.typesMap[type]) { // in case we dont know the type
        return data;
      }

      // get the map for the correct type.
      let attributeTypes = this.typesMap[type].getAttributeTypeMap();
      let instance: { [index: string]: any } = {};
      for (let index in attributeTypes) {
        let attributeType = attributeTypes[index];
        instance[attributeType.baseName] = this.serialize(data[attributeType.name], attributeType.type);
      }
      return instance;
    }
  }

  public deserialize(data: any, type: string) {
    this.log(`ObjectSerializer.deserialize type ${type}`, data);
    // polymorphism may change the actual type.
    type = this.findCorrectType(data, type);
    this.log(`  ObjectSerializer.deserialize correct type ${type}`);
    if (data === undefined || data === null) {
      this.log("  ObjectSerializer.deserialize data is undefined");
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      this.log("  ObjectSerializer.deserialize data is primitive");
      return data;
    } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
      this.log("  ObjectSerializer.deserialize array");
      let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index in data) {
        let date = data[index];
        transformedData.push(this.deserialize(date, subType));
      }
      return transformedData;
    } else if (type === "Date") {
      this.log("  ObjectSerializer.deserialize date");
      return new Date(data);
    } else {
      if (this.enumsMap[type]) {// is Enum
        this.log("  ObjectSerializer.deserialize enum");
        return data;
      }

      if (!this.typesMap[type]) { // dont know the type
        this.log("  ObjectSerializer.deserialize unknown");
        return data;
      }
      this.log("  ObjectSerializer.deserialize known type");
      let instance = new this.typesMap[type]();
      let attributeTypes = this.typesMap[type].getAttributeTypeMap();
      for (let index in attributeTypes) {
        let attributeType = attributeTypes[index];
        this.log(`ObjectSerializer.deserialize attribute ${attributeType.name}`);
        instance[attributeType.name] = this.deserialize(data[attributeType.baseName], attributeType.type);
      }
      this.log("ObjectSerializer.deserialize instance", instance);
      return instance;
    }
  }
}
