/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useShareHolders from './useShareHolders';
import Spinner from '../common/Spinner';
import { DataGrid } from '@mui/x-data-grid';

const styles = {
  root: css({

  })
}

const columns = [
  { field: 'lastName', headerName: 'Sukunimi', width: 300 },
  { field: 'firstNames', headerName: 'Etunimi', width: 300 },
  { field: 'seriesOfShares', headerName: 'Osakesarja', width: 300 },
  { field: 'numberOfShares', headerName: 'Osakkeiden lukumäärä', width: 300 }
];



const Osakasluettelo = () => {
  // const user = useSelector(selectUser);
  
  // const shareHolders =  useShareHolders(user.businessId);
  // const loading = false;

  // const rows = [];

  return (
    <>
      {/* {shareHolders.loading ? <Spinner style={{ height: "100%" }} /> :
        <div style={{width:"100%", height: 600}}>
        <DataGrid rows={shareHolders.sHolders} columns={columns} pageSize={50} />
        </div>
      } */}
    </>
  )
}

export default Osakasluettelo
