/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { FormikProps, getIn, useFormik } from "formik";
import * as Yup from 'yup';
import { Box, Button, Card as MuiCard, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Typography, Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import Card from '../../common/Card';
import { JsonObject } from '../../../model/OmaXTypes';
import { CreatePeppolInvoice } from '../../../api/OmaYritysApi';
import { CreateInvoiceRequest, InvoiceType } from '../../../model/AppModels';
import { DeletableMuiCard, InputWrapper } from '../../common/CustomFormikComponents';
import ConfirmDialog from '../../common/ConfirmDialog';
import { hasClassifiedTaxCategory } from '../../../utils/peppolUtils';

interface PeppolParticipant {
  name: string,
  code: string,
  participantID: string
}

const PeppolParticipants: PeppolParticipant[] = [
  {
    name: "Hosharaff 238 Testifirma",
    code: "7011367-6",
    participantID: "0216:00377011367600100"
  },
  {
    name: "Vasagrande Testifirma",
    code: "7023610-7",
    participantID: "0216:00377023610700100"
  },
  {
    name: "Älari Testifirma As.OY",
    code: "7003430-2",
    participantID: "0216:00377003430200100"
  },
  {
    name: "Sallakari Testifirma OY",
    code: "7006370-1",
    participantID: "0216:00377006370100100"
  },
  {
    name: "Acanne Testifirma",
    code: "7025394-3",
    participantID: "0216:00377025394300100"
  },
  {
    name: "Kinnasarjaana Testifirma",
    code: "7021704-5",
    participantID: "0216:00377021704500100"
  }
]

const CreateInvoiceTemplate = (): CreateInvoiceRequest => {
  return {
    type: InvoiceType.PEPPOL,
    customerCode: "",
    invoiceLines: []
  };
}

const SalesInvoiceFormView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { invoiceId } = useParams<{invoiceId: string}>();
  // console.log("invoiceId", invoiceId);
  const [existingInvoiceObject, setExistingInvoiceObject] = useState<JsonObject<any>|undefined>(undefined);
  const [existingInvoice, setExistingInvoice] = useState<any|undefined>(undefined);

  const isEditable = !existingInvoice || !!existingInvoiceObject?.sessionAccountID || appContext.isAdmin();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => setShowConfirmModal(false)); // override this
  const [confirmHeading, setConfirmHeading] = useState<string>("app.components.SalesInvoiceForm.cancel.heading");
  const [confirmMessage, setConfirmMessage] = useState<string>("app.components.SalesInvoiceForm.cancel.message");

  const submit = async (invoiceRequest: CreateInvoiceRequest) => {
    console.log("onSubmit", invoiceRequest);
    const resp = await CreatePeppolInvoice(invoiceRequest);
    console.log("Response", resp);
    // Refresh Peppol documents
    appContext.getPeppolDocumentsAsync();
    navigate("/sales");
  }

  const formik = useFormik({
    initialValues: CreateInvoiceTemplate(),
    validationSchema: Yup.object({
      type: Yup.string().oneOf([InvoiceType.PEPPOL, InvoiceType.FINVOICE]).required("required"),
      customerCode: Yup.string().required("required"),
      invoiceLines: Yup.array().of(Yup.object({
        id: Yup.string().required("required"),
        quantity: Yup.number().min(1, "minValueError").required("required"),
        unitCode: Yup.string().required("required"),
        price: Yup.number().test("isValid", "greaterThanZeroError", (val, ctx) => {
          return !!val && val > 0;
        }).required("required"),
        itemID: Yup.string().required("required"),
      })).min(1).required("required")
    }),
    onSubmit: submit
  });

  const availableCustomers = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {t("app.components.SalesInvoiceForm.select-recipient")}
    </MenuItem>];
    return empty.concat(PeppolParticipants
    .filter(it => it.code !== appContext.company?.code)
    .map(it => (
      <MenuItem key={"participant-" + it.code} value={it.code}>
        <ListItemText primaryTypographyProps={{fontWeight: "700"}} primary={`${it.name} (${it.code})`} secondary={it.participantID} />
      </MenuItem>
      )
    ));
  }

  const onGoBack = () => {
    // Notify the user if the form has changes. Else just go back
    if (formik.dirty) {
      setConfirmHeading("app.components.SalesInvoiceForm.cancel.heading");
      setConfirmMessage("app.components.SalesInvoiceForm.cancel.message");
      setConfirmAction(() => () => navigate(-1));
      setShowConfirmModal(true);
    }
    else {
      navigate(-1);
    }
  }

  return (
    <Page
      breadcrumbs={[
        { href: "/", label: "frontpage", current: false},
        { href: "/sales", label: "salesInvoices", current: false},
        ( existingInvoice ? 
          { href: "/sales/:invoiceId/edit", label: "edit-salesInvoice", current: true} :
          { href: "/sales/new", label: "new-salesInvoice", current: true}
        )
      ]}
    >
      <Card title={t(`app.components.SalesInvoiceForm.heading-new`)}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputWrapper>
                <Box display="flex" sx={{"& > *": {flex: 1}}}>
                  <FormControl error={Boolean(formik.touched.customerCode) && Boolean(formik.errors.customerCode)}>
                    <InputLabel className="MuiInputLabel-outlined" id={"customerCode"}>{t("app.components.SalesInvoiceForm.invoice-recipient")}</InputLabel>
                    <Select 
                      name={"customerCode"}
                      labelId={"customerCode"}
                      value={formik.values.customerCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      required
                    >
                      { availableCustomers() }
                    </Select>
                    { Boolean(formik.touched.customerCode) && formik.errors.customerCode && 
                      <FormHelperText>{formik.errors.customerCode}</FormHelperText> 
                    }
                  </FormControl>
                </Box>
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InvoiceLineForm formikProps={formik} required={true} />
            </Grid>
          </Grid>
          <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
            <Button
              variant="outlined"
              onClick={onGoBack}
            >
              {t("common.actions.back")}
            </Button>
            { isEditable &&
              <Button variant="contained" type="submit">
                {t("common.actions.save")}
              </Button>
            }
          </Grid>
        </form>
      </Card>
      <ConfirmDialog
        open={showConfirmModal}
        titleKey={confirmHeading}
        contentTextKey={confirmMessage}
        confirmKey={"common.actions.confirm"}
        cancelKey={"common.actions.cancel"}
        confirmAction={confirmAction}
        cancelAction={() => setShowConfirmModal(false)}
        handleClose={() => setShowConfirmModal(false)}
      />
    </Page>
  );
}

export default SalesInvoiceFormView;

export const InvoiceLineForm: React.FC<{formikProps: FormikProps<CreateInvoiceRequest>, required?: boolean}> = ({formikProps, required}) => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();

  useEffect(() => {
    if (formikProps.values.invoiceLines.length < 1 && required) {
      add();
    }
  }, []);

  const add = () => {
    const values = formikProps.values.invoiceLines;
    values.push({id: (values.length + 1).toString(), quantity: "", unitCode: "", price: "", itemID: ""});
    formikProps.setFieldValue("invoiceLines", values);
  }

  const del = (itemIndex: number) => {
    const values = formikProps.values.invoiceLines;
    values.splice(itemIndex, 1);
    formikProps.setFieldValue("invoiceLines", values);
  }

  const availableProductItems = () => {
    const empty = [<MenuItem key={"item-empty"} value={""}>
      {t("app.components.SalesInvoiceForm.invoiceLine.select-product")}
    </MenuItem>];
    return empty.concat(Object.entries(appContext.catalogueProducts)
    .map(([key, value]) => {
      if (!hasClassifiedTaxCategory(value.jsonData)) {
        return (
          <Tooltip title={t("app.components.SalesInvoiceForm.invoiceLine.disabledTooltip")} followCursor>
            <div>
            <MenuItem key={"product-item-" + key} value={key} disabled>
              <ListItemText primaryTypographyProps={{fontWeight: "700"}} primary={`${value.jsonData.name.value}`} secondary={key} sx={{maxWidth: "fit-content"}} />
              <WarningAmberIcon color="warning"/>
            </MenuItem>
            </div>
          </Tooltip>
        );
      }
      return (
        <MenuItem key={"product-item-" + key} value={key} disabled={!hasClassifiedTaxCategory(value.jsonData)}>
          <ListItemText primaryTypographyProps={{fontWeight: "700"}} primary={`${value.jsonData.name.value}`} secondary={key} sx={{maxWidth: "fit-content"}} />
        </MenuItem>
      );
    }));
  }

  const getErrorMessage = (errorKey?: string, param?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + param;
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  if (formikProps.values.invoiceLines.length > 0) {
    return (
      <MuiCard sx={{backgroundColor: tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
        { formikProps.values.invoiceLines.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`invoiceLines-${itIndex}`} title={`${t("app.components.SalesInvoiceForm.invoiceLine.heading")} #${it.id}`} deletable={itIndex > 0} deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <InputWrapper>
                    <TextField
                      sx={{display: "flex"}}
                      type="number"
                      name={`invoiceLines[${itIndex}].quantity`}
                      label={t("app.components.SalesInvoiceForm.invoiceLine.quantity")}
                      value={formikProps.values.invoiceLines[itIndex].quantity}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].quantity`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].quantity`))}
                      helperText={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].quantity`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].quantity`)) && getErrorMessage(getIn(formikProps.errors, `invoiceLines[${itIndex}].quantity`), "1")}
                      variant="outlined"
                      required
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputWrapper>
                    <TextField
                      sx={{display: "flex"}}
                      name={`invoiceLines[${itIndex}].unitCode`}
                      label={t("app.components.SalesInvoiceForm.invoiceLine.unitCode")}
                      value={formikProps.values.invoiceLines[itIndex].unitCode}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].unitCode`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].unitCode`))}
                      helperText={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].unitCode`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].unitCode`)) && getErrorMessage(getIn(formikProps.errors, `invoiceLines[${itIndex}].unitCode`))}
                      variant="outlined"
                      required
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputWrapper>
                    <TextField
                      sx={{display: "flex"}}
                      type="number"
                      name={`invoiceLines[${itIndex}].price`}
                      label={t("app.components.SalesInvoiceForm.invoiceLine.price")}
                      value={formikProps.values.invoiceLines[itIndex].price}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].price`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].price`))}
                      helperText={Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].price`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].price`)) && getErrorMessage(getIn(formikProps.errors, `invoiceLines[${itIndex}].price`))}
                      variant="outlined"
                      required
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Box display="flex" sx={{"& > *": {flex: 1}}}>
                      <FormControl error={Boolean(formikProps.touched.customerCode) && Boolean(formikProps.errors.customerCode)}>
                        <InputLabel className="MuiInputLabel-outlined" id={"customerCode"}>{t("app.components.SalesInvoiceForm.invoiceLine.itemID")}</InputLabel>
                        <Select 
                          name={`invoiceLines[${itIndex}].itemID`}
                          labelId={t("app.components.SalesInvoiceForm.invoiceLine.itemID")}
                          value={formikProps.values.invoiceLines[itIndex].itemID}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          variant="outlined"
                          required
                        >
                          { availableProductItems() }
                        </Select>
                        { Boolean(getIn(formikProps.touched, `invoiceLines[${itIndex}].itemID`)) && Boolean(getIn(formikProps.errors, `invoiceLines[${itIndex}].itemID`)) && 
                          <FormHelperText>{getErrorMessage(getIn(formikProps.errors, `invoiceLines[${itIndex}].itemID`))}</FormHelperText> 
                        }
                      </FormControl>
                    </Box>
                  </InputWrapper>
                </Grid>
              </Grid>
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={add}>
            <Typography>{t("app.components.SalesInvoiceForm.new-invoiceLine")}</Typography>
          </Button>
        </Box>
      </MuiCard>
    );
  }

  return (
    <MuiCard sx={{backgroundColor: tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Button variant="outlined" size="small" onClick={add}>
        <Typography>{t("app.components.SalesInvoiceForm.new-invoiceLine")}</Typography>
      </Button>
    </MuiCard>
  );
}