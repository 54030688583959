import axios, { AxiosResponse } from 'axios'
import { BasicInfo } from '../model/CompanyTypes'

const apiRootUrl = process.env.REACT_APP_VFTESTBED_ROOT_URL || 'http://localhost:7075/api'

const api = axios.create({
  baseURL: apiRootUrl
})

export async function FetchLegalEntityBasicInformation(businessId: string): Promise<AxiosResponse<any>> {
  return api.post("/VFTestbed/LegalEntity/BasicInformation", {nationalIdentifier: businessId})
}

export async function FetchNSGAgentBasicInformation(businessId: string): Promise<AxiosResponse<BasicInfo[]>> {
  return api.post("/VFTestbed/NSGAgent/BasicInformation", {nationalIdentifier: businessId})
}