/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Card from '../common/Card';
import Osakasluettelo from './Osakasluettelo';
import OrganizationEditor from './ccid/OrganizationEditor';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip } from '@mui/material';
import { useAppStateContext } from '../../state/AppStateContext';

const Yritystiedot: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();

  return (
    <>
      <Card title={t("app.companyinfo.legalentity.basicInfo.title")}>
        { (appContext.basicInfo) ?
          <p>
            <Trans
              i18nKey="app.companyinfo.legalentity.basicInfo.description"
              components={{
                link1: <a href="https://testbed.fi" target="_blank"> Virtual Finland Testbed</a>,
                link2: <a href="https://definitions.testbed.fi/definitions/draft/NSG/Agent/BasicInformation" target="_blank">NSG/Agent/BasicInformation</a>
              }}
            />
          </p> :
          <p>
            <span>{t("app.companyinfo.legalentity.noBasicInfo")}</span>
          </p>
        }
        { appContext.basicInfo &&
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.name.description")}>
                  <span>{t("app.companyinfo.legalentity.basicInfo.name.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.name}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registrationDate.description")}>
                  <span>{t("app.companyinfo.legalentity.basicInfo.registrationDate.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registrationDate}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.legalForm.description")}>
                  <span>{t("app.companyinfo.legalentity.basicInfo.legalForm.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.legalForm}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.legalStatus.description")}>
                  <span>{t("app.companyinfo.legalentity.basicInfo.legalStatus.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.legalStatus}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.description")}>
                  <span>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.title")}</span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.fullAddress.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.fullAddress.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.fullAddress}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.thoroughfare.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.thoroughfare.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.thoroughfare}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorDesignator.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorDesignator.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.locatorDesignator}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorName.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.locatorName.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.locatorName}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressArea.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressArea.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.addressArea}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.postCode.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.postCode.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.postCode}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.postName.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.postName.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.postName}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.poBox.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.poBox.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.poBox}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_1.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_1.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.adminUnitLevel_1}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_2.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.adminUnitLevel_2.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.adminUnitLevel_2}</span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Tooltip placement="top" title={t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressId.description")}>
                  <span css={{paddingLeft: '10px'}}>{t("app.companyinfo.legalentity.basicInfo.registeredAddress.addressId.title")}</span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={6}>
                <span>{appContext.basicInfo.registeredAddress.addressId}</span>
              </Grid>
            </Grid>
          </Box>
        }
      </Card>
      <br />
      <OrganizationEditor data={{}}></OrganizationEditor>
      <br />
      <Card title='Osakasluettelo'>
        <Osakasluettelo></Osakasluettelo>
      </Card>
    </>
  )
}

export default Yritystiedot
