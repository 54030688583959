import React from "react";
import { FormikProps } from "formik";
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from "@mui/material";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ItemComparison, ItemLocationQuantity, RelatedItem } from '../../model/Peppol/PreAwardCatalogue';
import { useCustomTranslations } from '../../utils/useCustomTranslations';
import { AddressListForm, DeletableMuiCard, DeliveryUnitListForm, InputWrapper, PriceForm, ToolTippedAddButton, ToolTippedCard, TooltippedTextField } from '../common/CustomFormikComponents';

export const ItemComparisonForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>}> = ({fieldName, fieldPath, memberOfClass, formikProps}) => {
  const { t } = useTranslation();
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const items: ItemComparison[] = fieldProps.value ?? [];

  const add = () => {
    items.push({
      quantity: {unitCodeListID: "UNECERec20"}
    });
    formikProps.setFieldValue(fieldPath, items);
  }

  const del = (itemIndex: number) => {
    items.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, items);
  }

  if (items?.length > 0) {
    return (
      <ToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { items.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <TooltippedTextField fieldName="priceAmount" fieldPath={`${fieldPath}[${itIndex}].priceAmount.value`} memberOfClass="ItemComparison" formikProps={formikProps} />
                      </Grid>
                      <Grid item xs={4}>
                        <TooltippedTextField fieldName="currencyID" fieldPath={`${fieldPath}[${itIndex}].priceAmount.currencyID`} memberOfClass="AmountType" formikProps={formikProps} />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TooltippedTextField fieldName="quantity" fieldPath={`${fieldPath}[${itIndex}].quantity.value`} memberOfClass="ItemComparison" formikProps={formikProps} fieldType="number" />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TooltippedTextField fieldName="unitCode" fieldPath={`${fieldPath}[${itIndex}].quantity.unitCode`} memberOfClass="QuantityType" formikProps={formikProps} />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TooltippedTextField fieldName="unitCodeListID" fieldPath={`${fieldPath}[${itIndex}].quantity.unitCodeListID`} memberOfClass="QuantityType" formikProps={formikProps} disabled />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
              </Grid>
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CatalogueForms.add") + " " + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </ToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const CatalogueLineRelatedItemForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>}> = ({fieldName, fieldPath, memberOfClass, formikProps}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const items: RelatedItem[] = fieldProps.value ?? [];

  const add = () => {
    items.push({
      quantity: {unitCodeListID: "UNECERec20"}
    });
    formikProps.setFieldValue(fieldName, items);
  }

  const del = (itemIndex: number) => {
    items.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldName, items);
  }

  if (items?.length > 0) {
    return (
      <ToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { items?.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TooltippedTextField fieldName="iD" fieldPath={`${fieldName}[${itIndex}].iD.value`} memberOfClass="RelatedItem" formikProps={formikProps} />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TooltippedTextField fieldName="quantity" fieldPath={`${fieldName}[${itIndex}].quantity.value`} memberOfClass="RelatedItem" formikProps={formikProps} fieldType="number" />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TooltippedTextField fieldName="unitCode" fieldPath={`${fieldName}[${itIndex}].quantity.unitCode`} memberOfClass="QuantityType" formikProps={formikProps} />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TooltippedTextField fieldName="unitCodeListID" fieldPath={`${fieldName}[${itIndex}].quantity.unitCodeListID`} memberOfClass="QuantityType" formikProps={formikProps} disabled />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
              </Grid>
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CatalogueForms.add") + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </ToolTippedCard>
    );
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

export const RequiredItemLocationQuantityForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>}> = ({fieldName, fieldPath, memberOfClass, formikProps}) => {
  const { t } = useTranslation(["translation"]);
  const ct = useCustomTranslations();
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const items: ItemLocationQuantity[] = fieldProps.value ?? [];

  const add = () => {
    items.push({
    });
    formikProps.setFieldValue(fieldPath, items);
  }

  const del = (itemIndex: number) => {
    items.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, items);
  }

  if (items?.length > 0) {
    return (
      <ToolTippedCard fieldName={fieldName} memberOfClass={memberOfClass}>
        { items?.map((it, itIndex) => {
          return (
            <DeletableMuiCard key={`${fieldName}-${itIndex}`} title={`#${itIndex + 1} ${ct("title", memberOfClass, fieldName)}`} deletable deleteCard={() => del(itIndex)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <AddressListForm fieldName="applicableTerritoryAddress" fieldPath={`${fieldPath}[${itIndex}].applicableTerritoryAddress`} memberOfClass="ItemLocationQuantity" formikProps={formikProps} />
                </Grid>
                <Grid item xs={12}>
                  <PriceForm fieldName="price" fieldPath={`${fieldPath}[${itIndex}].price`} memberOfClass="ItemLocationQuantity" formikProps={formikProps} />
                </Grid>
                <Grid item xs={12}>
                  <DeliveryUnitListForm fieldName="deliveryUnit" fieldPath={`${fieldPath}[${itIndex}].deliveryUnit`} memberOfClass="ItemLocationQuantity" formikProps={formikProps} />
                </Grid>
              </Grid>
            </DeletableMuiCard>
          )
        })}
        <Box sx={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={() => add()}>
            <Typography>{t("app.components.CatalogueForms.add") + ct("title", memberOfClass, fieldName)}</Typography>
          </Button>
        </Box>
      </ToolTippedCard>
    )
  }

  return (
    <ToolTippedAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
  
}