export const currentIsoDate = (): string => {
  return new Date().toISOString().substring(0, 10);
};

export const currentTimestamp = (): string => {
  return Date.now().toString(10);
};

export const toFiDateString = (isoDate: string) => {
  return new Date(isoDate).toLocaleDateString('fi-FI');
};

export const toDateString = (date?: Date | string) => {
  if (date instanceof Date) {
    date.toLocaleDateString('fi-FI');
  }
  else if (date) {
    return new Date(date).toLocaleDateString('fi-FI');
  }
}

export const toDateTimeString = (date?: Date | string) => {
  if (!date) {
    return "";
  }
  const d = date instanceof Date ? date : new Date(date);
  return d.toLocaleDateString('fi-FI') + " " + d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
}
