import {jsx} from '@emotion/react'
import React from "react";
import {FlexCol, FlexRow } from "../../common/CommonStyles";
import TextField from '@mui/material/TextField';
import { Organization } from '../../../model/organization/organization';
import { Button, suomifiDesignTokens as tokens, Expander, ExpanderContent, ExpanderTitleButton } from 'suomifi-ui-components'
import Card from '../../common/Card'
import ContactDetailEditor from './ContactDetailEditor';
import { ContactDetails } from '../../../model/contactDetails';
import _ from 'lodash';

class OrganizationEditor extends React.Component<any, Organization> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  render() {
    
      return (
        <Expander className="expander-test" defaultOpen={true}>
          <ExpanderTitleButton asHeading="h3">
            Yritystiedot
          </ExpanderTitleButton>
          <ExpanderContent>
          <FlexCol>
              <FlexCol style={{display: 'flex'}}>
                <TextField name='legalName' label="Virallinen nimi" value={this.state.legalName} onChange={this.handleChange.bind(this)} variant="outlined" />
                </FlexCol>
                <FlexCol style={{display: 'flex'}}>
                <TextField name='alternativeName' label="Vaihtoehtoinen nimi" value={this.state.alternativeName} onChange={this.handleChange.bind(this)} variant="outlined" />
              </FlexCol>
              <FlexRow>
              <ContactDetailEditor data={new ContactDetails()}></ContactDetailEditor>
              </FlexRow>
            </FlexCol>
          </ExpanderContent>
        </Expander>
      );
  }
}

export default OrganizationEditor;
